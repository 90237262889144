import Wheel from 'components/Wheel';
import { PagesWrapper } from './Map.styles';

const GuidWithCTA = () => {
  return (
    <PagesWrapper>
      <Wheel />
    </PagesWrapper>
  );
};

export default GuidWithCTA;
