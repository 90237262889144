import styled from 'styled-components';
import tw from 'twin.macro';

export const Content = tw.div`
 relative flex flex-col justify-center items-center gap-3 overflow-hidden pb-3
`;

export const WheelWrapper = tw.div`
  relative  w-[22.5rem] h-[22.5rem] pointer-events-none 
`;
export const WheelImages = tw.div`
   absolute inset-0
`;
export const WheelImg = tw.img`
  absolute inset-0 object-contain rounded-full 
`;

export const WheelLight = tw.img`
 absolute inset-0 object-contain mix-blend-overlay 
`;
export const WheelShadow = tw.img`
 absolute inset-0 object-contain mix-blend-overlay
`;

export const Needle = tw.img`
  absolute  left-1/2 top-[48%] -translate-x-1/2 -translate-y-1/2
`;

export const PrizeIcon = styled.img<{ angle: number }>`
  ${tw`absolute w-12 h-12 left-1/2 top-1/2 translate-x-1/2 object-contain`};
  transform: ${({ angle }) =>
    `rotate(${angle + 22.5}deg) 
     translate(6.4rem) 
     rotate(-${angle + 22.5}deg) 
     translate(-50%, -50%)`};
`;
