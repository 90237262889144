import Button from 'components/Button';
import styled from 'styled-components';
import tw from 'twin.macro';
import { PopupContainerProps } from './WinningPopup.types';

export const PopupContainer = styled.div<PopupContainerProps>`
  ${tw`relative  w-full h-full flex  min-w-[10.813rem] max-w-[23.375rem]`}
  // if wheel true
  ${({ wheel }) => wheel && tw`[&>div]:(justify-center items-center)`}
`;

export const PopupContent = tw.div`
    w-full h-full flex flex-col justify-center items-center gap-2 p-4 rounded-xl [&>h1]:text-2xl
`;
export const PopupContentItem = tw.div`w-full flex flex-col justify-start items-center gap-2`;

export const PopupImage = styled.img<PopupContainerProps>`
  ${tw`w-[6rem] h-[6rem] rounded-full object-contain`}
  ${({ wheel }) => wheel && tw`w-36 h-40 rounded-none -mt-1`}
`;

export const PopupDescription = tw.span`w-full flex items-center justify-center gap-1.5`;
export const PopupTitle = tw.p`text-sm font-medium text-center text-success`;

export const CloseButton = styled(Button)`
  ${tw`absolute -top-5 -right-4 z-10`}
`;

export const RetryButton = styled(Button)`
  ${tw`absolute bottom-0 left-auto right-auto translate-y-1/2`}
`;
