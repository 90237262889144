import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledProfileCardProps } from './InviteCard.types';

export const Wrapper = styled.div<StyledProfileCardProps>`
  ${tw`w-full max-w-sm flex flex-col justify-center items-start gap-2
      border-2 border-darkGray  rounded-[18px] p-[18px]`}
  background-color: ${({ bgColor }) => bgColor};
`;

export const CopyWrapper = tw.form`
  w-full flex justify-center items-center gap-1.5
`;

export const Link = tw.input`
   w-full overflow-hidden whitespace-nowrap text-ellipsis max-w-[18.875rem] p-3 pr-5 text-gray text-base tracking-tight leading-[1.25rem]  
   bg-softGray border-2 border-darkGray rounded-lg cursor-default
`;

export const CopyButton = styled.button`
  ${tw`relative w-11 h-11 shrink-0 border-2 border-darkGray bg-yellow
   flex justify-center items-center rounded-lg cursor-copy`}
  &:hover:after {
    ${tw`[content: 'copy link'] absolute z-10 top-full left-1/2 -translate-x-1/2
    w-20 py-1.5 mt-2 bg-black text-white text-center text-sm rounded-lg`}
  }
`;
