import Button from 'components/Button';
import styled from 'styled-components';
import tw from 'twin.macro';
import { MarkerProps, WrapperProps } from './MarkerPopup.types';

export const Wrapper = styled.div<WrapperProps>`
  ${tw`relative  flex flex-col justify-center items-center gap-[3.2rem] mx-auto 
       bg-softGray px-7 py-14  border-4 border-black rounded-xl capitalize text-2xl font-bold`}
  &:after {
    ${tw`[content: ""] absolute -z-10 inset-0 border-4 border-black rounded-xl 
    translate-x-3 translate-y-3`};
    background-color: ${({ shadow }) => shadow};
    border-color: ${({ borderColor }) => borderColor};
  }
`;

export const TopInfo = styled.div`
  ${tw`relative w-full flex justify-between gap-2 items-center`}
  //The line between the Infos divs
  &:after {
    ${tw`[content: ""] absolute -bottom-8 left-[50%] -translate-x-1/2 w-1/2 h-0.5 bg-black`}
  }
`;

export const Name = styled.div`
  ${tw`flex flex-col items-start`}
  span {
    ${tw`text-sm text-darkGray`}
  }
`;

export const CoinImage = tw.img`w-6`;

export const Reward = tw.div`
   flex justify-center items-center gap-1 text-xl
   py-2 px-4 bg-gray border-[3px] border-black  rounded-3xl
`;

export const BottomInfo = tw.div`
   w-full flex justify-between gap-x-4 items-center  flex-wrap 
   text-xl font-medium [&>span]:text-sm 
`;

export const MarkerWrapper = tw.div`absolute -top-10 left-1/2 -translate-y-1/2 -translate-x-1/2 -rotate-45`;
export const Marker = styled.span<MarkerProps>`
  ${tw`flex justify-center items-center bg-softGray [border-radius: 50% 50% 50% 0]  w-[6.31rem] h-[6.31rem] border-4 border-black `}
  &:after {
    ${tw`[content: ""] absolute -z-20 inset-0 border-4 border-black rounded-xl 
         [border-radius: 50% 50% 50% 0] translate-y-3 -translate-x-3`};
    background-color: ${({ shadow }) => shadow};
  }
  img {
    ${tw`rotate-45`}
  }
`;

export const CloseButton = styled(Button)`
  ${tw`absolute -top-7 -right-6 z-10 outline-none`}
`;

export const StartButton = styled(Button)`
  ${tw`absolute bottom-0 left-8 right-8 translate-y-1/2`}
`;

export const Start = tw.div`
   flex justify-center items-center gap-1 
`;

export const OnboardingTarget = tw.span`
   absolute left-[35%] -top-[20%]  bg-black
`;
