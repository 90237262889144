import Button from 'components/Button';
import ContentWrapper from 'components/ContentWrapper';
import Input from 'components/Input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useKeyboard from 'store/useKeyboard';
import { Form } from './ChooseForm.styles';
import { ChooseFormProps } from './ChooseForm.types';

const ChooseForm = ({ handleSubmit, ...rest }: ChooseFormProps) => {
  const [name, setName] = useState('');
  const { hasKeyboard } = useKeyboard();

  const { t } = useTranslation();
  return (
    <ContentWrapper
      style={{ paddingBottom: hasKeyboard ? 0 : '6rem' }}
      hasSpeechBox={false}
      {...rest}
    >
      <Form onSubmit={handleSubmit}>
        <label htmlFor="username">
          <Input
            width="100%"
            placeholder={t('yourname') || ''}
            name="username"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <Button
          rotation="-2.74deg"
          shadowColor="black"
          height="3.75rem"
          width="30%"
          type="submit"
        >
          {t('choose')}
        </Button>
      </Form>
    </ContentWrapper>
  );
};

export default ChooseForm;
