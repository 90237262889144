import tw from 'twin.macro';

export const ResultsContainer = tw.form`w-full px-2 md:px-[6rem] py-[1rem] flex flex-col items-center justify-between gap-4 z-10`;

export const TotalResultContainer = tw.div`
  w-full h-[5.25rem] rounded-lg border border-solid flex items-center justify-between bg-softGray px-2
`;

export const RewardsContainer = tw.div`
  w-full flex flex-col items-center justify-center gap-3 mb-5
`;

export const RewardsItemsContainer = tw.div`
  w-full flex justify-around items-center gap-3
`;

export const RewardsImage = tw.img`w-[4.875rem] h-[4.875rem] object-contain`;

export const CoinsRewardsContainter = tw.div`flex flex-col items-center justify-center gap-1`;

export const CoinsRewardsImage = tw.img`w-[2.806rem] h-[3.215rem]`;
