export const inviteDescription =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ';
export const inviteLink = 'https://casa-game-app.vercel.app';

export const playDescription =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ';

export const user = {
  name: 'jhone doe',
  email: 'jhoneDoe@gmail.com',
  avatarSrc: 'https://source.unsplash.com/random/avatar',
};
