import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledHowToPlayCardProps } from './HowToPlayCard.types';

export const Wrapper = styled.div<StyledHowToPlayCardProps>`
  ${tw`relative w-full max-w-[23.875rem] flex flex-col justify-center items-start  gap-2
      border-2 border-darkGray  rounded-[18px] p-[18px] [&>button]:self-center [&>button]:z-10`}
  background-color: ${({ bgColor }) => bgColor};
`;

export const Shape = tw.img`
   absolute bottom-0 left-0  object-contain 
`;
