import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledFindPlaceQuoteWrapperProps } from './FindPlaceQuoteCard.types';

export const StyledFindPlaceQuoteWrapper = styled.div<StyledFindPlaceQuoteWrapperProps>`
  ${tw`w-full h-full p-3 rounded-[31px]`};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export const StyledFindPlaceImage = tw.div`
     w-full h-full rounded-2xl p-7  bg-darkShadeGray 
    flex items-center justify-center text-white font-bold
    text-[16px] italic
`;

export const StyledQuotesMark = tw.div`absolute top-[2rem] right-[2rem] flex`;

export const StyledQuotesText = tw.div`mt-16 max-h-[198px] overflow-auto pt-2`;
