import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Camera from './Camera';
import Layout from './Layout';
import MapRoutes from './Map';
import ProfileRoutes from './Profile/index';
import RewardsRoutes from './Rewards';

import StartRoutes from './Start';

import QuizzesRoutes from './Quizzes';
import SpalshScreen from './SplashScreen';

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<SpalshScreen />} />

          <Route path="start/*" element={<StartRoutes />} />
          <Route path="map/*" element={<MapRoutes />} />
          <Route path="profile/*" element={<ProfileRoutes />} />
          <Route path="rewards/*" element={<RewardsRoutes />} />
          <Route path="camera/*" element={<Camera />} />
          <Route path="quizzes/*" element={<QuizzesRoutes />} />

          <Route path="*" element={<MapRoutes />} />
        </Route>
        <Route path="*" element={<MapRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
