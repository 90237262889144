import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import { Empty, Loading } from 'components/LottieFiles';
import RewardItem from 'components/RewardItem';
import Title from 'components/Typography/Title';
import { AllWrapper, BackButton, Content, Grid } from './Rewards.styles';
import ChevronLeft from 'Icons/ChevronLeft';
import useRewards from 'store/useRewards';
import useUser from 'store/useUser';
import { getImagefromMedia, getObject3D } from 'helpers/GlobalHelper';
import NoImage from 'assets/images/noImage.png';
import ClickSound from 'assets/sounds/Tap.wav';
import i18n from 'i18n';

const AllObjects: React.FC = () => {
  const [playClick] = useSound(ClickSound);

  // States
  const { isLogged } = useUser();
  const { objects } = useRewards();
  const [loading, setLoading] = useState<boolean>(true);

  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Effect to check if user is logged in
  useEffect(() => {
    if (!isLogged) {
      navigate('/start');
    }

    // Simulate a loader
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [isLogged, navigate]);

  // Helper functions
  const getName = (item: any) =>
    item?.dictionary?.name?.[i18n.language] || item?.name;
  const getDescription = (item: any) =>
    item?.dictionary?.description?.[i18n.language] || item?.description;

  return (
    <AllWrapper>
      <BackButton
        onClick={() => {
          playClick();
          navigate(-1);
        }}
      >
        <ChevronLeft width="17" height="23" />
      </BackButton>
      <Content>
        <Title renderAs="h1" level={1}>
          {t('objectWon')}
        </Title>
        {objects && objects.length > 0 ? (
          <Grid>
            {objects.map((object: any, index: number) => {
              const img = getImagefromMedia(object?.media);
              const object3d = getObject3D(object?.media)?.location;
              return (
                <RewardItem
                  key={object.name + index}
                  type="object"
                  name={getName(object)}
                  image={img || NoImage}
                  object3d={object3d || ''}
                  description={getDescription(object)}
                  is3D
                />
              );
            })}
          </Grid>
        ) : loading ? (
          <Loading />
        ) : (
          <Empty />
        )}
      </Content>
    </AllWrapper>
  );
};

export default AllObjects;
