import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import EditModal from './EditModal';
import ProfileAvatar from './ProfileAvatar';
import { Content, Info, Wrapper } from './ProfileCard.styles';
import { ProfileCardProps } from './ProfileCard.types';

const ProfileCard = ({
  name,
  email,
  avatarSrc,
  bgColor = '#D8FBEA',
  circleBg,
  shadowColor = '#FDC800',
  handleSubmit,
  ...rest
}: ProfileCardProps) => {
  return (
    <Wrapper bgColor={bgColor} {...rest}>
      <Content>
        <ProfileAvatar
          avatarSrc={avatarSrc}
          bgColor={circleBg}
          shadowColor={shadowColor}
        />
        <Info>
          <Title renderAs="h3" level={3}>
            {name}
          </Title>
          <Paragraph>{email}</Paragraph>
        </Info>
      </Content>
      <EditModal handleSubmit={handleSubmit} />
    </Wrapper>
  );
};

export default ProfileCard;
