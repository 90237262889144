import { ConvContainer } from 'components/SpeechSwiper/ConvActions/ConvActions.styles';
import { Avatar, Content, Wrapper } from './IOSGuide.styles';
import ContentWrapper from 'components/ContentWrapper';
import SpeechBox from 'components/SpeechBox';
import useUser from 'store/useUser';
import NoImage from 'assets/icons/timeicon.png';
import { ButtonChild } from 'components/TalkingPA/TalkingPA.styles';
import Title from 'components/Typography/Title';
import { useTranslation } from 'react-i18next';
import Paragraph from 'components/Typography/Paragraph';
import Button from 'components/Button';
import ArrowRight from 'Icons/ArrowRight';
import { IOSGuideProps } from './IOSGuide.types';

const IOSGuide = ({ onClick, ...rest }: IOSGuideProps) => {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <Wrapper {...rest}>
      <SpeechBox cornPosition="top" hasLightBorder={false} width="100%">
        <Content>
          <img src={NoImage} alt="guide" className="w-[8rem] h-[6rem]" />
          <Title renderAs="h1" level={1}>
            {t('hey') + ' ' + user?.username}
          </Title>
          <Paragraph>{t('IOSdesc')}</Paragraph>
          <Button
            width="100%"
            hasAfter={false}
            rotation="-2.36deg"
            shadowColor="black"
            onClick={onClick}
          >
            <ButtonChild>
              <span>{t('understand')}</span>
              <ArrowRight />
            </ButtonChild>
          </Button>
        </Content>
      </SpeechBox>
      <Avatar src={user?.avatarImage} alt="pa avatar" isTall={true} />
    </Wrapper>
  );
};

export default IOSGuide;
