import { locales } from '../i18n';

// check type of mission and return the mission
export const checkMissionRole = (mission: any) => {
  if (mission.quiz?.length > 0) {
    return 'quizzes';
  } else if (mission.puzzle?.length > 0) {
    return 'puzzle';
  } else if (mission.badge?.length > 0) {
    return 'badge';
  } else if (mission.media?.length > 0) {
    return 'media';
  }
  return 'none';
};

// get dialogue conversation
export const getDialogueConversation = async (str: string, dictionary: any) => {
  if (str.length <= 1) return;

  if (dictionary) {
    const descriptions = Object.fromEntries(
      Object.entries(dictionary).map(([key, value]: any) => [
        key,
        value.split('|'),
      ]),
    );

    return handleDictionary(descriptions);
  }

  const dialogue = str?.split('|');
  const persons: Array<string> = [];

  const conversation =
    dialogue && dialogue.length > 0
      ? dialogue?.map((item: any) => {
          const [name, text] = item.split(':');
          // get persons from conversation and push it on persons if not exist
          if (name && !persons.includes(name.trim())) {
            persons.push(name.trim());
          }
          return { name: name?.trim(), text };
        })
      : [];

  // get person's role
  const personRole = persons.map((item: any, i: number) => {
    if (i === 0) return { name: item, role: 'pa' };
    else return { name: item, role: 'object' };
  });

  // add role to conversation
  conversation.forEach(async (item: any) => {
    personRole.forEach((person: any) => {
      if (item.name === person.name) {
        item.role = person.role;
      }
    });
  });

  return { conversation, persons };
};

export const handleDictionary = (dictionary: any) => {
  if (dictionary) {
    const persons: Array<string> = [];
    const conversation = Object.fromEntries(
      Object.entries(dictionary).map(([key, value]) => [
        key,
        (value as any[]).map((item: any) => {
          const [name, text] = item.split(':');
          // get persons from conversation and push it on persions if not exist
          if (name && !persons.includes(name.trim())) {
            persons.push(name.trim());
          }
          return { name: name?.trim(), text };
        }),
      ]),
    );

    // get person's role
    const personRole = persons.map((item: any, i: number) => {
      if (i === 0) return { name: item, role: 'pa' };
      else return { name: item, role: 'object' };
    });

    // add role to conversation
    Object.entries(conversation).forEach(([key, value]) => {
      (value as any[]).forEach((item: any) => {
        personRole.forEach((person: any) => {
          if (item.name === person.name) {
            item.role = person.role;
          }
        });
      });
    });

    return { conversation, persons };
  }
};

// get single dialogue
export const getSingleDialogue = (str: string, dictionary?: any) => {
  if (dictionary?.description) {
    const descriptions = Object.fromEntries(
      Object.entries(dictionary.description).map(([key, value]: any) => [
        key,
        value.split('|'),
      ]),
    );
    return descriptions;
  }
  return locales.reduce((acc: any, cur: string) => {
    return { ...acc, [cur]: str?.split('|') };
  }, {});
};

// get mission tasks by order  order = ['quiz', 'puzzle', 'object'] => mission.order: "0,1,3"
export const getMissionTask = (order: string) => {
  const task: any[] = [];
  const orders = order?.split(',');
  orders.forEach((item: any) => {
    switch (item) {
      case '1':
        task.push('quizzes');
        break;
      case '2':
        task.push('puzzle');
        break;
      case '3':
        task.push('object');
        break;
      default:
        break;
    }
  });
  return task;
};

// handle latitude and longitude
export const handleLatLong = (latLong: Array<number>) => {
  const longitude = latLong[0];
  const latitude = latLong[1];
  return { latitude, longitude };
};

// calculate total and finished missions
export const calcuateMissions = (missions: any) => {
  const total = missions?.length;
  const finished = missions?.filter((item: any) => item.finished)?.length;
  return finished + '/' + total;
};

// handle coins 3000 = 3k
export const handleCoins = (coins: number) => {
  if (coins >= 1000 && coins < 1000000) {
    return `${(coins / 1000).toFixed(0)}k`;
  } else if (coins >= 1000000) {
    return `${(coins / 1000000).toFixed(0)}m`;
  } else {
    return coins + '';
  }
};

// get mission by id
export const getMissionById = (id: string, missions: any) => {
  const mission = missions?.filter((item: any) => item._id === id);
  return mission;
};

// get mission order
export const getMissionOrder = (order: string) => {
  const orders = order?.split(',');
  return orders;
};

// check mission order
export const checkMissionOrder = (mission: any) => {
  if (mission.description) return 'guide';
  else if (mission.startDialogue?.length > 0) return 'guides';
  else if (mission.endDialogue?.length > 0) return 'guides';
};

// check quest order
