import { mediaTypes } from './GlobalTypes';

export const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return 'Android';
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator?.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'iOS';
  }
  return 'Other';
  //   /https:/ / modelviewer.dev / shared -
  //     assets / models / reflective -
  //     sphere.gltf;
};

// get image from media
export const getImagefromMedia = (media: Array<mediaTypes>) => {
  const image = media.find(
    (item) => item.mimetype && item.mimetype?.includes('image'),
  );
  if (!image) return '';
  return image.location;
};

// get GLB model
export const getObject3D = (media: Array<mediaTypes>) => {
  const object3D = media.find((item) => item.mimetype.includes('octet-stream'));
  return object3D;
};

// get IOS Version
export const getIOSVersion = async (userAgent: string) => {
  const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
  if (match) {
    const major = match[1];
    const minor = match[2];
    const patch = match[3] || '0';
    return [major, minor, patch].join('.');
  }
  return null;
};

export const compareVersions = (a: string): number => {
  // version need to be equal or higher than 16.4.0
  const version = a.split('.');
  const major = parseInt(version[0], 10);
  const minor = parseInt(version[1], 10);
  const patch = parseInt(version[2], 10);
  if (major > 16) {
    return 1;
  }
  if (major === 16 && minor > 4) {
    return 1;
  }
  if (major === 16 && minor === 4 && patch >= 0) {
    return 1;
  }
  return 0;
};

export const formatNumber = (num: number): string => {
  const absNum = Math.abs(num);
  const suffixes = ['', 'k', 'M', 'B', 'T', 'Q'];
  const magnitude = Math.min(
    Math.floor(Math.log10(absNum) / 3),
    suffixes.length - 1,
  );
  const roundedNum = Math.round(num / Math.pow(10, magnitude * 3));
  if (!num) return `0`;
  return `${roundedNum}${suffixes[magnitude]}`;
};
