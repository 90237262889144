import React, { useEffect } from 'react';
import ChooseArea from 'components/ChooseArea';
import { ChooseAreaWrapper } from './Start.styles';
import useData from 'store/useData';
import useArea from 'store/useArea';

// Utility function to parse distance
const parseDistance = (distance: string): number => {
  if (distance.endsWith('km')) {
    return parseFloat(distance) * 1000; // convert km to m
  }
  if (distance.endsWith('m')) {
    return parseFloat(distance);
  }
  return 0; // default case if distance is not properly formatted
};

const ChooseAreaPage: React.FC = () => {
  // data
  const { quests } = useData();
  const { areasList } = useArea();

  if (!quests) {
    return null; // or return a loading indicator or some fallback UI
  }

  // Sort quests by distance
  const sortedItems = areasList?.slice().sort((a, b) => {
    const distanceA = parseDistance(a?.distance || '0m');
    const distanceB = parseDistance(b?.distance || '0m');
    return distanceA - distanceB;
  });

  return (
    <ChooseAreaWrapper>
      <ChooseArea items={sortedItems} />
    </ChooseAreaWrapper>
  );
};

export default ChooseAreaPage;
