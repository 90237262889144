import { create } from 'zustand';

interface State {
  hasKeyboard: boolean;
  toggleTrue: () => void;
  toggleFalse: () => void;
}

const useKeyboard = create<State>()((set) => ({
  hasKeyboard: false,
  toggleTrue: () => set({ hasKeyboard: true }),
  toggleFalse: () => set({ hasKeyboard: false }),
}));

export default useKeyboard;
