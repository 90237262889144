const ChevronLeft = ({
  width = '20',
  height = '31',
  color = 'white',
  stroke = 'black',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.42339 16.8122L17.3058 24.3488C18.5307 25.5199 18.5312 27.4762 17.307 28.6479C16.157 29.7486 14.3443 29.749 13.1939 28.6487L2.76606 18.6748C0.957244 16.9447 0.957243 14.0553 2.76606 12.3252L13.1939 2.35133C14.3443 1.25104 16.157 1.25136 17.307 2.35206C18.5312 3.52382 18.5307 5.48005 17.3058 6.65117L9.42339 14.1878C8.67557 14.9028 8.67557 16.0972 9.42339 16.8122Z"
        fill={color}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
};

export default ChevronLeft;
