import tw from 'twin.macro';

export const Wrapper = tw.div`
  flex flex-col justify-center items-center gap-8 border-2 border-black
  w-full max-w-sm px-5 pt-6 pb-11 bg-[#D8E2FB] rounded-3xl
`;
export const SeeAll = tw.div`
   w-full flex justify-between items-center  text-lg leading-[1.438rem] capitalize font-bold tracking-tighter
`;

export const Grid = tw.div`w-full grid grid-cols-3 gap-x-3 gap-y-11`;
