import tw from 'twin.macro';

export const Wrapper = tw.div`
  relative w-full h-screen flex justify-center items-center z-[9999]
`;

export const PAs = tw.img`
  w-[90%] object-contain mx-auto z-30 h-[80vh]
`;

export const Background = tw.img`
  absolute inset-0 w-full h-screen object-cover 
`;
