const Location = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12.933L9.2998 9.63311C11.1223 7.81071 11.1223 4.85593 9.2998 3.03348C7.4774 1.21103 4.52261 1.21103 2.70017 3.03348C0.87772 4.85593 0.87772 7.81071 2.70017 9.63311L6 12.933ZM6 14.8186L1.75736 10.576C-0.585787 8.23278 -0.585787 4.43382 1.75736 2.09067C4.10051 -0.252474 7.89947 -0.252474 10.2427 2.09067C12.5858 4.43382 12.5858 8.23278 10.2427 10.576L6 14.8186ZM6 7.66665C6.7364 7.66665 7.33333 7.06971 7.33333 6.33331C7.33333 5.59693 6.7364 4.99998 6 4.99998C5.2636 4.99998 4.66667 5.59693 4.66667 6.33331C4.66667 7.06971 5.2636 7.66665 6 7.66665ZM6 8.99998C4.52724 8.99998 3.33333 7.80605 3.33333 6.33331C3.33333 4.86055 4.52724 3.66665 6 3.66665C7.47273 3.66665 8.66667 4.86055 8.66667 6.33331C8.66667 7.80605 7.47273 8.99998 6 8.99998Z"
        fill="black"
      />
    </svg>
  );
};

export default Location;
