const ChevronDown = ({ width = '23', height = '15', color = '#FE4A4F' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9994 5.33485C21.871 4.46334 21.871 3.05034 20.9994 2.17882C20.1279 1.30731 18.7149 1.30731 17.8434 2.17883L11.5911 8.43113L5.51008 2.35011C4.67086 1.51089 3.31022 1.51089 2.471 2.35011C1.63178 3.18933 1.63178 4.54997 2.471 5.38919L10.2511 13.1693C11.0903 14.0085 12.4509 14.0085 13.2901 13.1693C13.4405 13.0188 13.564 12.8517 13.6605 12.6738L20.9994 5.33485Z"
        fill={color}
      />
      <path
        d="M20.9994 5.33485L21.6005 5.93591L20.9994 5.33485ZM17.8434 2.17883L18.4445 2.77989L17.8434 2.17883ZM11.5911 8.43113L10.99 9.0322L11.5911 9.63326L12.1922 9.0322L11.5911 8.43113ZM2.471 5.38919L1.86994 5.99025L2.471 5.38919ZM10.2511 13.1693L10.8521 12.5682L10.2511 13.1693ZM13.6605 12.6738L13.0594 12.0727L12.9721 12.16L12.9133 12.2685L13.6605 12.6738ZM20.3984 2.77989C20.9379 3.31944 20.9379 4.19423 20.3984 4.73379L21.6005 5.93591C22.804 4.73244 22.804 2.78123 21.6005 1.57776L20.3984 2.77989ZM18.4445 2.77989C18.984 2.24033 19.8588 2.24033 20.3984 2.77989L21.6005 1.57776C20.397 0.37429 18.4458 0.374293 17.2424 1.57776L18.4445 2.77989ZM12.1922 9.0322L18.4445 2.77989L17.2424 1.57776L10.99 7.83007L12.1922 9.0322ZM4.90902 2.95117L10.99 9.0322L12.1922 7.83007L6.11114 1.74905L4.90902 2.95117ZM3.07206 2.95117C3.57932 2.44391 4.40175 2.44391 4.90902 2.95117L6.11114 1.74905C4.93997 0.577868 3.04111 0.577865 1.86993 1.74904L3.07206 2.95117ZM3.07206 4.78813C2.5648 4.28086 2.5648 3.45843 3.07206 2.95117L1.86993 1.74904C0.698755 2.92022 0.69876 4.81908 1.86994 5.99025L3.07206 4.78813ZM10.8521 12.5682L3.07206 4.78813L1.86994 5.99025L9.65 13.7703L10.8521 12.5682ZM12.6891 12.5682C12.1818 13.0754 11.3594 13.0754 10.8521 12.5682L9.65 13.7703C10.8212 14.9415 12.72 14.9415 13.8912 13.7703L12.6891 12.5682ZM12.9133 12.2685C12.8554 12.3752 12.781 12.4763 12.6891 12.5682L13.8912 13.7703C14.1001 13.5614 14.2726 13.3282 14.4077 13.0791L12.9133 12.2685ZM20.3984 4.73379L13.0594 12.0727L14.2616 13.2749L21.6005 5.93591L20.3984 4.73379Z"
        fill="#161F21"
      />
    </svg>
  );
};

export default ChevronDown;
