import RewardItem from 'components/RewardItem';
import { Items, Wrapper } from './StatisticsCard.styles';
import { StatisticsCardProps } from './StatisticsCard.types';
import { useTranslation } from 'react-i18next';

const StatisticsCard = ({
  items,
  bgColor = '#F2E8E8',
  ...rest
}: StatisticsCardProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Wrapper bgColor={bgColor} {...rest}>
      <Items>
        {items.length > 0 &&
          items.map((item, index) => (
            <RewardItem
              key={item.name + index}
              type="statistic"
              name={t(item.name)}
              image={item.image}
              object3d={''}
              statistics={item.statistics}
              description={t(item.description) || ''}
            />
          ))}
      </Items>
    </Wrapper>
  );
};

export default StatisticsCard;
