const Camera = () => {
  return (
    <svg
      width="31"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.3152"
        cy="15.6721"
        r="4.65179"
        transform="rotate(-1.89 15.3152 15.6721)"
        stroke="#F9FCFD"
        strokeWidth="3"
      />
      <path
        d="M28.5712 22.8101L28.103 8.62143C28.0771 7.83781 27.4209 7.22352 26.6373 7.24938L23.611 7.34924C23.0144 7.36893 22.4691 7.01326 22.2467 6.45927L20.9241 3.16472C20.7017 2.61072 20.1565 2.25505 19.5599 2.27474L9.72357 2.59932C9.12693 2.61901 8.60634 3.00985 8.42095 3.5773L7.31842 6.95188C7.13302 7.51933 6.61244 7.91017 6.01579 7.92986L2.98947 8.02973C2.20585 8.05559 1.59156 8.7118 1.61742 9.49542L2.08563 23.6841C2.11149 24.4677 2.7677 25.082 3.55132 25.0562L27.1992 24.2758C27.9828 24.25 28.5971 23.5938 28.5712 22.8101Z"
        stroke="#F9FCFD"
        strokeWidth="3"
      />
    </svg>
  );
};

export default Camera;
