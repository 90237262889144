import { Swiper, SwiperSlide } from 'swiper/react';
import QuizBarItem from '../QuizBarItem';
import { QuizBarWrapper } from './QuizBar.styles';
import { QuizBarProps, QuizzesBarData } from './QuizBar.type';

const QuizBar = ({ quizzes }: QuizBarProps): JSX.Element => {
  return (
    <QuizBarWrapper>
      <Swiper slidesPerView="auto" spaceBetween={10}>
        {quizzes.length > 0 &&
          quizzes.map((quiz: QuizzesBarData, index: number) => (
            <SwiperSlide key={index}>
              <QuizBarItem {...quiz} />
            </SwiperSlide>
          ))}
      </Swiper>
    </QuizBarWrapper>
  );
};

export default QuizBar;
