import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import useUser from 'store/useUser';
import Home from './home';
import QuizzItem from './QuizzContent';
import Result from './Result';

const QuizzesRoutes = () => {
  // state data
  const { isLogged } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      navigate('/start');
    }
    // eslint-disable-next-line
  }, [isLogged]);
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route path="/final/result" element={<Result />} />
        <Route children path=":id" element={<QuizzItem />} />
      </Route>
    </Routes>
  );
};

export default QuizzesRoutes;
