import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledSettingsCardProps } from './SettingsCard.types';

export const Wrapper = styled.div<StyledSettingsCardProps>`
  ${tw`w-full max-w-sm flex flex-col justify-center items-start gap-8
       p-6 border-2 border-darkGray  rounded-[18px] `}
  background-color: ${({ bgColor }) => bgColor};
`;

export const SettingItems = tw.div`
  w-full flex flex-col justify-center items-center gap-4
`;
