import { fetchit } from 'lib';
import { API_ENIGMAS } from 'config/api';
import useData from 'store/useData';
import { getDialogueConversation, handleLatLong } from 'helpers/MissionsHelper';
import { getBadgesItems } from 'helpers/BadgesHelper';

export const getEnigmas = async (placeId: String) => {
  if (!placeId) return;
  const data = await fetchit(API_ENIGMAS + '/?place=' + placeId, null, 'GET');
  await data?.results.map(async (item: any, index: number) => {
    const { latitude, longitude } = handleLatLong(item?.loc);

    Object.assign(item, {
      dialogue:
        item?.dialogue &&
        (await getDialogueConversation(
          item?.dialogue,
          item?.dictionary?.dialogue,
        )),
      badges: item.badges.length > 0 && (await getBadgesItems(item?.badges)),
      latitude,
      longitude,
    });
  });
  useData.setState({ enigmas: data.results });
  return data;
};
