import tw from 'twin.macro';
import { BoxWrapperProps, CornProps } from './SpeechBox.types';
import styled from 'styled-components';

export const Wrapper = tw.div`animate-fadeFromTop`;
export const BoxWarpper = styled.div<BoxWrapperProps>`
  ${tw`relative flex justify-center items-center 
  bg-white border-4 border-black rounded-xl text-center`};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-color: ${({ borderColor }) => borderColor};
  ${({ hasLightBorder }) =>
    hasLightBorder ? tw`border-2` : tw`min-h-[6 .5rem]`}
  //the rectangle beneath the div
  &:after {
    ${tw`[content: ""] absolute -z-10 inset-0 border-4 border-black rounded-xl 
    translate-x-3 translate-y-3 transition-all duration-700`};
    background-color: ${({ afterBg }) => afterBg};
    border-color: ${({ borderColor }) => borderColor};
    ${({ hasLightBorder }) => hasLightBorder && tw`border-2`}
  }
`;

export const StyledCorn = styled.div<CornProps>(
  ({ cornPosition, translateCorn, hasLightBorder }) => [
    tw`absolute pointer-events-none transition-all duration-700`,
    cornPosition === 'left' &&
      tw`left-[-55px] top-[50%] translate-y-[-50%] rotate-[270deg]`,
    cornPosition === 'top' && tw`top-[-29px] left-[50%] translate-x-[-50%]`,
    //Styles for small corn
    cornPosition === 'left' && hasLightBorder === true && tw`left-[-39px]`,
    cornPosition === 'top' && hasLightBorder === true && tw`top-[-25px]`,
    //move the corn along the x and y axis
    cornPosition === 'top'
      ? `translate: ${translateCorn} 0px`
      : `translate: 0px ${translateCorn}`,
  ],
);
