import BadgeItem from 'components/BadgesCard/BadgeItem';
import { Empty, Loading } from 'components/LottieFiles';
import Title from 'components/Typography/Title';
import { IMAGE_BASE_URL } from 'config/constant';
import ChevronLeft from 'Icons/ChevronLeft';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useRewards from 'store/useRewards';
import useUser from 'store/useUser';
import { AllWrapper, BackButton, BadgesGrid, Content } from './Rewards.styles';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import i18n from 'i18n';

const AllBadges = () => {
  const [playClick] = useSound(ClickSound);
  // states
  const { isLogged } = useUser();
  const { badges } = useRewards();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const goBack = () => {
    playClick();
    navigate(-1);
  };
  const { t } = useTranslation();

  // check if user logged or not

  useEffect(() => {
    if (!isLogged) {
      navigate('/start');
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
    // eslint-disable-next-line
  }, []);

  const getName = (item: any) => {
    if (item?.dictionary) {
      return item?.dictionary?.name[i18n.language];
    }
    return item?.name;
  };

  const getDescription = (item: any) => {
    if (item?.dictionary && item?.dictionary?.description) {
      return item?.dictionary?.description[i18n.language];
    }
    return item?.description;
  };

  return (
    <AllWrapper>
      <BackButton onClick={goBack}>
        <ChevronLeft width="17" height="23" />
      </BackButton>
      <Content>
        <Title renderAs="h1" level={1}>
          {t('meritBadges')}
        </Title>
        {badges && badges?.length > 0 ? (
          <BadgesGrid>
            {badges.map((badge: any, index) => {
              const img = badge?.media[0]?.location;
              return (
                badge && (
                  <BadgeItem
                    key={badge?.name + index}
                    badgeImage={IMAGE_BASE_URL + img}
                    className="mb-20"
                    badgeName={getName(badge)}
                    badgeDescription={getDescription(badge)}
                  />
                )
              );
            })}
          </BadgesGrid>
        ) : loading ? (
          <Loading />
        ) : (
          <Empty />
        )}
      </Content>
    </AllWrapper>
  );
};

export default AllBadges;
