import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledFindPlaceImageWrapperProps } from './FindPlaceImageCard.types';

export const StyledFindPlaceImageWrapper = styled.div<StyledFindPlaceImageWrapperProps>`
  ${tw`w-full  p-3 rounded-[31px]`};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export const StyledFindPlaceImage = tw.img`w-full rounded-2xl object-cover`;
