import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useMission from 'store/useMission';

const Quizzes = () => {
  const navigate = useNavigate();
  const { mission } = useMission();

  useEffect(() => {
    if (!mission || !mission.quizzes || mission.quizzes.length === 0) {
      navigate('/map');
    } else {
      navigate(`${mission.quizzes[0].id}`);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};

export default Quizzes;
