import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
// with noName
import Coins10 from 'assets/wheel/coins_10.png';
import Coins30 from 'assets/wheel/coins_30.png';
import Coins50 from 'assets/wheel/coins_50.png';
import Coins100 from 'assets/wheel/coins_100.png';
import Expert from 'assets/wheel/expert.png';
import Lucky from 'assets/wheel/lucky.png';
import Yeah from 'assets/wheel/yeah.png';
import NextTime from 'assets/wheel/next_time.png';

// en version
import Coins10_en from 'assets/wheel/en/coins_10.png';
import Coins30_en from 'assets/wheel/en/coins_30.png';
import Coins50_en from 'assets/wheel/en/coins_50.png';
import Coins100_en from 'assets/wheel/en/coins_100.png';
import Expert_en from 'assets/wheel/en/expert.png';
import Lucky_en from 'assets/wheel/en/lucky.png';
import Yeah_en from 'assets/wheel/en/yeah.png';
import NextTime_en from 'assets/wheel/en/next_time.png';

// fr version
import Coins10_fr from 'assets/wheel/fr/coins_10.png';
import Coins30_fr from 'assets/wheel/fr/coins_30.png';
import Coins50_fr from 'assets/wheel/fr/coins_50.png';
import Coins100_fr from 'assets/wheel/fr/coins_100.png';
import Expert_fr from 'assets/wheel/fr/expert.png';
import Lucky_fr from 'assets/wheel/fr/lucky.png';
import Yeah_fr from 'assets/wheel/fr/yeah.png';
import NextTime_fr from 'assets/wheel/fr/next_time.png';

// es version
import Coins10_es from 'assets/wheel/es/coins_10.png';
import Coins30_es from 'assets/wheel/es/coins_30.png';
import Coins50_es from 'assets/wheel/es/coins_50.png';
import Coins100_es from 'assets/wheel/es/coins_100.png';
import Expert_es from 'assets/wheel/es/expert.png';
import Lucky_es from 'assets/wheel/es/lucky.png';
import Yeah_es from 'assets/wheel/es/yeah.png';
import NextTime_es from 'assets/wheel/es/next_time.png';

interface DataProps {
  icons: Array<any>;
  access: boolean;
  count: number;
  setIcons: (icons: Array<any>) => void;
  setAccess: (access: boolean) => void;
  setCount: (count: number) => void;
}

const useWheel = create<DataProps>()(
  devtools(
    persist(
      (set) => ({
        icons: [
          {
            icon: Coins10,
            iconbylng: {
              en: Coins10_en,
              fr: Coins10_fr,
              es: Coins10_es,
            },
            alt: 'coin_10',
            prize: '10',
            angle: 0,
            type: 'coins',
          },
          {
            icon: Yeah,
            iconbylng: {
              en: Yeah_en,
              fr: Yeah_fr,
              es: Yeah_es,
            },
            alt: 'spin',
            prize: 'spin',
            angle: 45,
            type: 'spin',
          },
          {
            icon: Coins100,
            iconbylng: {
              en: Coins100_en,
              fr: Coins100_fr,
              es: Coins100_es,
            },
            alt: 'coins_100',
            prize: '100',
            angle: 90,
            type: 'coins',
          },
          {
            dictionary: {
              name: {
                en: 'Lucky badge',
                es: 'Insignia de la suerte',
                fr: 'Badge de la chance',
              },
            },
            _id: '64a7ee04281feb5b30b3553b',
            place: '63c806f4b5411539045fdc00',
            createdBy: '63c87ee0a975ac792e6754f0',
            name: 'Lucky badge',
            media: [
              {
                _id: '64a7edf9281feb5b30b35536',
                place: '63c806f4b5411539045fdc00',
                createdBy: '63c87ee0a975ac792e6754f0',
                filename: 'd0290b63-dc69-4a1d-9c8a-82b78f0e6c07.png',
                originalname: 'lucky badge V.png',
                mimetype: 'image/png',
                location:
                  'https://concierge.fra1.digitaloceanspaces.com/d0290b63-dc69-4a1d-9c8a-82b78f0e6c07.png',
                size: '105526',
                createdAt: '2023-07-07T10:50:33.718Z',
                updatedAt: '2023-07-07T10:50:33.718Z',
                __v: 0,
              },
            ],
            createdAt: '2023-07-07T10:50:44.312Z',
            updatedAt: '2023-07-07T11:02:17.559Z',
            __v: 0,
            icon: Lucky,
            iconbylng: {
              en: Lucky_en,
              fr: Lucky_fr,
              es: Lucky_es,
            },
            alt: 'lucky_badge',
            prize: 'lucky_badge',
            angle: 135,
            type: 'badge',
          },
          {
            icon: Coins30,
            iconbylng: {
              en: Coins30_en,
              fr: Coins30_fr,
              es: Coins30_es,
            },
            alt: 'coins_30',
            prize: '30',
            angle: 180,
            type: 'coins',
          },
          {
            icon: NextTime,
            iconbylng: {
              en: NextTime_en,
              fr: NextTime_fr,
              es: NextTime_es,
            },
            alt: 'next_time',
            prize: 'next_time',
            angle: 225,
            type: 'noChance',
          },
          {
            icon: Coins50,
            iconbylng: {
              en: Coins50_en,
              fr: Coins50_fr,
              es: Coins50_es,
            },
            alt: 'coins_50',
            prize: '50',
            angle: 270,
            type: 'coins',
          },

          {
            dictionary: {
              name: {
                en: 'Expert of Tangier',
                es: 'Exporte de Tánger',
                fr: 'Expert de Tanger',
              },
            },
            _id: '64a7ee16281feb5b30b35553',
            place: '63c806f4b5411539045fdc00',
            createdBy: '63c87ee0a975ac792e6754f0',
            name: 'Expert of Tangier',
            media: [
              {
                _id: '64a7ee10281feb5b30b3554e',
                place: '63c806f4b5411539045fdc00',
                createdBy: '63c87ee0a975ac792e6754f0',
                filename: '9228dbb8-9484-4bd7-9d0c-aa7b9d2df344.png',
                originalname: 'expert de Tanger V.png',
                mimetype: 'image/png',
                location:
                  'https://concierge.fra1.digitaloceanspaces.com/9228dbb8-9484-4bd7-9d0c-aa7b9d2df344.png',
                size: '99833',
                createdAt: '2023-07-07T10:50:56.284Z',
                updatedAt: '2023-07-07T10:50:56.284Z',
                __v: 0,
              },
            ],
            createdAt: '2023-07-07T10:51:02.526Z',
            updatedAt: '2023-07-07T11:02:41.240Z',
            __v: 0,
            icon: Expert,
            iconbylng: {
              en: Expert_en,
              fr: Expert_fr,
              es: Expert_es,
            },
            alt: 'expert_badge',
            prize: 'expert_badge',
            angle: 315,
            type: 'badge',
          },
        ],
        access: true,
        count: 0,
        setIcons: (icons: Array<any>) => set({ icons }),
        setAccess: (access: boolean) => set({ access }),
        setCount: (count: number) => set({ count }),
      }),

      {
        name: 'Wheel',
      },
    ),
  ),
);

export default useWheel;
