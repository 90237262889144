const SmallCorn = ({ width = 55, height = 27 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 55 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_717_2915)">
        <path
          d="M27.5 2L22.6553 11.1911C18.5028 19.069 10.3296 24 1.4242 24L-9.61651e-07 24L-1.04907e-06 26L55 26L55 24L53.7161 24C44.9116 24 36.8139 19.1791 32.6175 11.439L27.5 2Z"
          fill="white"
        />
        <path
          d="M35.2623 13.8165L27.5 -1.20206e-06L19.7377 13.8165C16.5509 19.4889 10.551 23 4.0447 23L-1.00536e-06 23L-1.09278e-06 25L3.5109 25C11.1567 25 18.1983 20.8445 21.8943 14.1515L27.5 4L33.3909 14.3715C37.1224 20.9412 44.0955 25 51.651 25L55 25L55 23L50.9552 23C44.449 23 38.4491 19.4889 35.2623 13.8165Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_717_2915">
          <rect
            width="27"
            height="55"
            fill="white"
            transform="translate(55) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SmallCorn;
