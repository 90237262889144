import styled from 'styled-components';
import tw from 'twin.macro';
import { WrapperProps } from './Profile.types';

export const Wrapper = styled.div<WrapperProps>`
  ${tw` w-full flex flex-col justify-center items-center 
  px-4 py-6 pb-[7rem] gap-5 bg-softGray`}

  &>div:first-child {
    margin-bottom: ${({ isLogged }) => (isLogged ? 0 : '40px')};
  }
`;
