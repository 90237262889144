import Title from 'components/Typography/Title';
import {
  LanguageItem,
  LanguageItemContainer,
  LanguageItemRadio,
  LanguageItemText,
  LanguagesContainer,
  LanguagesIcon,
  LangugaesWrapper,
} from './LanguageSelect.styles';
import LanguagesImage from 'assets/icons/languages.png';
import { locales } from 'i18n';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import { LanguageProps } from './LanguageSelect.types';

const LanguageSelect = ({ onChange }: LanguageProps) => {
  const [playClick] = useSound(ClickSound);
  const { t } = useTranslation();
  const changeLanguage = (lang: string) => {
    playClick();
    i18next.changeLanguage(lang);
    onChange && onChange();
  };

  return (
    <LangugaesWrapper>
      <LanguagesIcon src={LanguagesImage} alt="languges" />
      <LanguagesContainer>
        <Title level={1} renderAs="h1">
          {t('changelanguge')}
        </Title>
        <LanguageItemContainer>
          {locales?.map((language) => (
            <LanguageItem
              key={language}
              isActive={language === i18next.language}
              onClick={() => changeLanguage(language)}
            >
              <LanguageItemRadio />
              <LanguageItemText>{t(language)}</LanguageItemText>
            </LanguageItem>
          ))}
        </LanguageItemContainer>
      </LanguagesContainer>
    </LangugaesWrapper>
  );
};

export default LanguageSelect;
