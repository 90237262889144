export const QcmHandleQuizzesItems = (items: []) => {
  const newItems = items.map((item: any) => {
    const data = {
      title: item,
      checked: false,
      correct: true,
    };
    return data;
  });
  return newItems;
};

// Qcm Handle translated items
export const QcmHandleTranslatedItems = (items: [], lng: string) => {
  const newItems = items.map((item: any) => {
    const data = {
      title: item && item[lng],
      checked: false,
      correct: true,
    };
    return data;
  });

  return newItems;
};

export const handleQuizBarItems = (items: any) => {
  const newItems = items.map((item: any, index: number) => {
    const data = {
      quizNumber: index + 1,
      coins: item.coins,
      isActive: item.active,
      isCompleted: item.completed,
      isLocked: false,
    };
    return data;
  });
  return newItems;
};
