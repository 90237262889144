import { Empty } from 'components/LottieFiles';
import BadgeItem from './BadgeItem';
import { Grid, SeeAll, Wrapper } from './BadgesCard.styles';
import { BadgesCardProps } from './BadgesCard.types';
import NoImage from 'assets/images/noImage.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const BadgesCard = ({
  badges,
  handleSeeAll,
  ...rest
}: BadgesCardProps): JSX.Element => {
  // const getBadgeImage
  const { t } = useTranslation();
  const getName = (item: any) => {
    if (item?.dictionary) {
      return item?.dictionary?.name[i18n.language];
    }
    return item?.name;
  };

  const getDescription = (item: any) => {
    if (item?.dictionary && item?.dictionary?.description) {
      return item?.dictionary?.description[i18n.language];
    }
    return item?.description;
  };
  return (
    <Wrapper {...rest}>
      <SeeAll>
        <p>{t('meritbadges')}</p>
        <button onClick={handleSeeAll}>{t('seeAll')}</button>
      </SeeAll>
      {badges.length > 0 ? (
        <Grid>
          {badges.map((badge: any, index) => {
            const img = badge?.media[0]?.location;
            return (
              badge && (
                <BadgeItem
                  key={badge + index}
                  badgeImage={img || NoImage}
                  badgeName={getName(badge)}
                  badgeDescription={getDescription(badge)}
                />
              )
            );
          })}
        </Grid>
      ) : (
        <Empty />
      )}
    </Wrapper>
  );
};

export default BadgesCard;
