import { Player } from '@lottiefiles/react-lottie-player';
import { LottieProps } from './Lottie.type';

export default function Celebrate({
  className,
  autoplay = true,
  loop = true,
  speed = 1.5,
}: LottieProps) {
  return (
    <Player
      className={className}
      autoplay={autoplay}
      speed={speed}
      loop={loop}
      src="https://assets4.lottiefiles.com/packages/lf20_bvvejzaz.json"
    />
  );
}
