import useData from 'store/useData';
import { checkMissionOrder } from './MissionsHelper';
import { getDistance } from 'geolib';
import { missions } from 'routes/Map/dummy';
// get missions items
export const getMissionsItems = async (missions: any, starterMission: any) => {
  const data = useData.getState()?.missions;
  // get missions item from data with the same mission order
  const missionsItems = data
    ?.filter((item: any) => missions.includes(item._id))
    .sort((a: any, b: any) => {
      return missions.indexOf(a._id) - missions.indexOf(b._id);
    });

  // add starter fieald to mission index 0
  if (missionsItems && missionsItems.length > 1 && !starterMission) {
    missionsItems[0].starter = true;
  }
  return missionsItems;
};

// get mission
export const getMission = async (missionId: string) => {
  const data = useData.getState().missions;
  const mission = data?.filter((item: any) => item._id === missionId);
  if (mission) return mission[0];
  else return null;
};

// get enigmas items
export const getEnigmasItems = async (enigmas: any) => {
  const data = useData.getState().enigmas;
  const enigmasItems = data?.filter((item: any) => enigmas.includes(item._id));
  return enigmasItems;
};

// get quest tasks by order  order = ['enigmas', 'missions' => quest.order: "0,1"
export const getQuestTasks = async (order: string) => {
  const tasks: any[] = [];
  const orders = order?.split(',');

  orders.forEach((element) => {
    switch (element) {
      case '1':
        tasks.push('enigmas');
        break;
      case '2':
        tasks.push('missions');
        break;
      default:
        break;
    }
  });
  return tasks;
};

// handle next mission
export const handleNextMission = (missions: any, missionId: string) => {
  const index = missions?.findIndex((item: any) => item._id === missionId);
  if (index !== -1) {
    const nextMission = missions[index + 1];
    return nextMission;
  }
};

// finish mission
export const finishMission = async (missionId: string) => {
  const data = useData.getState().missions;
  const mission = data?.filter((item: any) => item._id === missionId);
  if (mission) {
    mission[0].finished = true;
    useData.setState({ missions: data });
  }
};

// update quest after finishing mission
export const updateQuest = async (selectedQuest: any) => {
  const data = useData.getState().quests;
  const newQuest: any = data?.map((quest: any) => {
    if (quest?._id === selectedQuest?._id) {
      return selectedQuest;
    } else return quest;
  });
  return newQuest;
};

// update Selected Quest with mission and return selected quest
export const updateSelectedQuest = async (selectedQuest: any, mission: any) => {
  const newSelectedQuest = selectedQuest?.missions?.map((item: any) => {
    if (item._id === mission._id) {
      return mission;
    }
    return item;
  });
  if (newSelectedQuest) selectedQuest.missions = newSelectedQuest;
  return selectedQuest;
};

// check mission selection  and handle  mission
export const checkMissionSelection = async (
  mission: any,
  selectedQuest: any,
  setMission: any,
  setObjects: any,
  setObject: any,
  objects: any,
  navigate: any,
) => {
  const order = mission?.order;
  if (!order || order.length === 0) {
    finishMission(mission?._id);
    // shift missions in quest

    const nextMission = handleNextMission(
      selectedQuest?.missions,
      mission?._id,
    );
    if (nextMission) {
      setMission(nextMission);
      selectedQuest?.missions.shift();
      // update quests
      updateQuest(selectedQuest);
      navigate(`/map`);
    }
  } else if (order[0] === 'object') {
    if (mission?.objects) {
      const newObjects: any = [...objects, mission?.objects[0]];
      setObjects(newObjects);
      setObject(mission?.objects[0]);
      mission.startDialogue = undefined;
      mission.order = order.slice(1);
      // update order
      setMission(mission);
      // update selectdeQuest and quest
      updateQuest(selectedQuest);
    }
  }
};

// check selectd Quest
export const checkSelectedQuest = async (selectedQuest: any) => {
  const missions = selectedQuest?.missions;
  const enigmas = selectedQuest?.enigmas;
  const missionsItems = await getMissionsItems(
    missions,
    selectedQuest.starterMission,
  );
  const enigmasItems = await getEnigmasItems(enigmas);
  const questTasks = await getQuestTasks(selectedQuest?.order);
  const selectedQuestItems = {
    missions: missionsItems,
    enigmas: enigmasItems,
    tasks: questTasks,
  };
  return selectedQuestItems;
};

// handla start misison
export const handleStartMission = async (mission: any) => {
  const mission_order = checkMissionOrder(mission);
  if (mission_order === 'guide') return { path: 'guide' };
  else if (mission_order === 'guides')
    return {
      path: 'guides',
      dialogue: mission.startDialogue
        ? mission.startDialogue
        : mission.endDialogue,
    };
};

// finish quest
export const finishQuest = async (questId: string) => {
  const data = useData.getState().quests;
  const quest = data?.filter((item: any) => item._id === questId);
  if (quest) {
    quest[0].finished = true;
    useData.setState({ quests: data });
  }
};

// get all quest have enigmas
export const getQuestsHaveEnigmas = async () => {
  const data = useData.getState().quests;
  const quests = data?.filter(
    (quest: any) =>
      quest?.enigmas?.length > 0 &&
      quest.finished === false &&
      !quest.starterMission,
  );
  return quests;
};

// get random quest
export const getRandomQuest = async () => {
  const quests = await getQuestsHaveEnigmas();

  if (quests) {
    const randomQuest = quests[Math.floor(Math.random() * quests.length)];
    return randomQuest;
  }
  return null;
};

// get quest sorted by most close distance to the user position
export const getQuestsSortedByDistance = async (userPosition: any) => {
  const quests = await getQuestsHaveEnigmas();

  const questsSortedByDistance = quests?.sort(
    (a: any, b: any) =>
      getDistance(
        { latitude: a?.enigmas[0]?.loc[1], longitude: a?.enigmas[0]?.loc[0] },
        userPosition,
      ) -
      getDistance(
        { latitude: b?.enigmas[0]?.loc[1], longitude: b?.enigmas[0]?.loc[0] },
        userPosition,
      ),
  );
  if (questsSortedByDistance) return questsSortedByDistance[0];
  return null;
};

export const getTotalQuestCoins = (selectedQuest: any) => {
  const quizzesCoins = selectedQuest?.missions
    ?.map((item: any) => {
      // if mission have quizzes return total of quizzes coins
      if (item?.quizzes?.length > 0) {
        const quizzesCoins = item?.quizzes
          ?.map((quiz: any) => (quiz.coins ? quiz.coins : 0))
          .reduce((a: any, b: any) => a + b, 0);
        return quizzesCoins;
      }
      return 0;
    })
    .reduce((a: any, b: any) => a + b, 0);

  const coins = selectedQuest?.missions
    ?.map((item: any) => (item.coins ? item.coins : 0))
    .reduce((a: any, b: any) => a + b, 0);

  return coins + quizzesCoins;
};
