import NavBar from 'components/NavBar';
import { Outlet, useLocation } from 'react-router-dom';
import { Wrapper } from './Layout.styles';
import map from 'assets/icons/menu/Map.png';
import profile from 'assets/icons/menu/profile.png';
import rewards from 'assets/icons/menu/rewards.png';
import Gifts from 'assets/icons/menu/Gifts.png';
import useUser from 'store/useUser';
import useMission from 'store/useMission';

const links = [
  {
    link: '/map',
    title: 'map',
    image: map,
    active: true,
  },
  {
    link: '/profile',
    title: 'profile',
    image: profile,
    active: false,
  },
  {
    link: '/rewards',
    title: 'rewards',
    image: rewards,
    active: false,
  },
];

const Layout = () => {
  const { isLogged } = useUser();

  // pathname
  const { pathname } = useLocation();

  return (
    <Wrapper>
      <Outlet />
      {isLogged && pathname !== '/start/welcome' && <NavBar links={links} />}
    </Wrapper>
  );
};

export default Layout;
