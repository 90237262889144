//types
import { MarkerPopupProps } from './MarkerPopup.types';
//Styles and Icons
import {
  BottomInfo,
  CloseButton,
  CoinImage,
  Marker,
  MarkerWrapper,
  Name,
  Reward,
  Start,
  StartButton,
  TopInfo,
  Wrapper,
} from './MarkerPopup.styles';
import { theme } from 'twin.macro';
import XMark from 'Icons/XMark';
import ChevronRight from 'Icons/ArrowRight';
import coin from 'assets/icons/coins/coin.png';
import Paragraph from 'components/Typography/Paragraph';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'components/LottieFiles/LoadingButton';
import { useState } from 'react';

const MarkerPopup = ({
  icon,
  type,
  name,
  coins,
  missions,
  level,
  distance,
  borderColor,
  shadow = '#02D596',
  onClose,
  onStart,
}: MarkerPopupProps) => {
  const [playClick] = useSound(ClickSound);
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Wrapper
      data-testid="marker-popup"
      borderColor={borderColor}
      shadow={shadow}
    >
      <MarkerWrapper>
        <Marker shadow={shadow}>
          <img src={icon} alt={`${type}-icon`} />
        </Marker>
      </MarkerWrapper>
      <CloseButton
        bgColor={theme`colors.softGray`}
        hasAfter={true}
        afterBg={theme`colors.red`}
        isRounded={true}
        onClick={() => {
          playClick();
          onClose();
        }}
        aria-label="close-marker-popup outline-none"
      >
        <XMark />
      </CloseButton>
      <StartButton
        className="start-button"
        hasAfter={false}
        isRounded={false}
        rotation="-2.36deg"
        color={theme`colors.softGray`}
        shadowColor="black"
        onClick={() => {
          playClick();
          onStart();
        }}
      >
        <Start>
          {loading ? <LoadingButton /> : t('startmission')}
          <ChevronRight />
        </Start>
      </StartButton>
      <TopInfo>
        <Name>
          <Paragraph>{t('quest')}</Paragraph>
          <p>{name}</p>
        </Name>
        {coins && (
          <Reward>
            <p>{coins}</p>
            <CoinImage src={coin} alt="coin icon" />
          </Reward>
        )}
      </TopInfo>
      <BottomInfo>
        {missions && (
          <p>
            {missions} <span>missions</span>
          </p>
        )}
        <p>{t(level)}</p>
        <p>{distance}</p>
      </BottomInfo>
    </Wrapper>
  );
};

export default MarkerPopup;
