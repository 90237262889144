import Button from 'components/Button';
import { CardInfo } from './PlaceCard.styles';
import Location from 'Icons/Location';
import Paragraph from 'components/Typography/Paragraph';
import { PlaceCardProps } from './PlaceCard.types';
import { useNavigate } from 'react-router-dom';
import useArea from 'store/useArea';

const PlaceCard = ({ name, location, handleSelectArea }: PlaceCardProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    handleSelectArea();
    navigate('/start/choose-pa');
  };
  return (
    <Button
      shadowColor="#161F21"
      width="100%"
      height="66px"
      bgColor="white"
      color="#161F21"
      onClick={handleOnClick}
    >
      <CardInfo>
        <label>
          <Location />
          {name}
        </label>
        <Paragraph>{location}</Paragraph>
      </CardInfo>
    </Button>
  );
};

export default PlaceCard;
