import Title from 'components/Typography/Title';
import WrapperBox from 'components/WrapperBox';
import { theme } from 'twin.macro';
import {
  PopupContainer,
  PopupContent,
  PopupInfos,
  Distance,
} from './FindPlacePopup.styles';
import { useTranslation } from 'react-i18next';
import { PopupProps } from './FindPlacePopup.types';
import Button from 'components/Button';
import Paragraph from 'components/Typography/Paragraph';

const FindPlacePopup = ({
  title,
  points,
  distance,
  collectRewards,
  ...rest
}: PopupProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <WrapperBox
      width="100%"
      height="13.938rem"
      borderColor={theme`colors.darkBlue`}
      {...rest}
    >
      <PopupContainer data-testid="find-place-popup">
        <PopupContent>
          <Distance>{`(${distance})`}</Distance>
          <PopupInfos>
            <Title level={1} renderAs="h1">
              {title}
            </Title>
            <Paragraph>And gain {points} Points</Paragraph>
          </PopupInfos>
          <Button
            width="100%"
            hasAfter={false}
            isRounded={false}
            rotation="-2.36deg"
            bgColor={theme`colors.blue`}
            borderColor={theme`colors.darkBlue`}
            shadowColor={theme`colors.darkGray`}
            onClick={collectRewards}
          >
            {t('collectcoins')}
          </Button>
        </PopupContent>
      </PopupContainer>
    </WrapperBox>
  );
};

export default FindPlacePopup;
