import RoutesComponent from 'routes';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'index.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { useEffect, useState } from 'react';
import { PLACE_ID } from 'config/constant';
import { getEnigmas } from 'routes/services/Enigmas';
import {
  getAllQuizzes,
  getAreas,
  getAvatars,
  getBadges,
  getMissions,
  getObjects,
  getQuests,
} from 'routes/services';
import { usePosition } from 'hooks/usePosition';
import { getDistance, convertDistance } from 'geolib';
import useData from 'store/useData';
import useUser from 'store/useUser';
import { showNotification } from 'serviceWorkerRegistration';
import { useIdleTimer } from 'react-idle-timer';
import useMission from 'store/useMission';
import { getPuzzles } from 'routes/services/Puzzels';
import { useTranslation } from 'react-i18next';
import { getMobileOS } from 'helpers/GlobalHelper';
import { ClientJS } from 'clientjs';
import useWheel from 'store/useWheel';
import useArea from 'store/useArea';

function App() {
  const { t } = useTranslation();
  // Create a new ClientJS object
  const client = new ClientJS();

  // Get the client's fingerprint id
  const fingerprint = client.getFingerprint();

  // positions
  const { latitude, longitude } = usePosition(true);
  // data state
  const { onGoing, mission, missionType, setRefresh } = useMission();
  const { missions, quests, setMissions, setQuest } = useData();
  const { isLogged, activeNotification, setVisitorId } = useUser();
  const { areasList, setAreasList } = useArea();
  const { access, setAccess } = useWheel();
  // Idle States
  // eslint-disable-next-line
  const [state, setState] = useState<string>('Active');
  // eslint-disable-next-line
  const [remaining, setRemaining] = useState<number>(0);

  // redirect to wheel page every 3 hours
  // useEffect(() => {

  //     if (access) {
  //       window.location.href = '/map/wheel';
  //     }
  //     // 10800000
  // }, [access]);

  // get Data
  useEffect(() => {
    setVisitorId(fingerprint);
    if (!isLogged || !missions) {
      getAreas(PLACE_ID);
      getBadges(PLACE_ID);
      getObjects(PLACE_ID);
      getAvatars(PLACE_ID);
      getPuzzles(PLACE_ID);
      getAllQuizzes(PLACE_ID);
      getEnigmas(PLACE_ID);
      getMissions(PLACE_ID).then((res) => {
        setMissions(res.results);
        getQuests(PLACE_ID).then((res) => {
          setQuest(res.results);
          localStorage.setItem('allData', JSON.stringify(res.results));
        });
      });
    }

    // eslint-disable-next-line
  }, []);

  // get distance for Areas list
  useEffect(() => {
    let distance: any;
    const interval = setInterval(() => {
      if (areasList && areasList[0] && latitude && longitude) {
        const newAreas: any = areasList?.map((area: any) => {
          if (area) {
            // eslint-disable-next-line
            distance = getDistance(
              {
                latitude: area?.latitude || area?.loc[1],
                longitude: area?.longitude || area?.loc[0],
              },
              { latitude: latitude, longitude: longitude },
            );
          }

          return {
            ...area,
            distance:
              convertDistance(distance, 'km') < 1
                ? distance + 'm'
                : convertDistance(distance, 'km').toFixed() + 'km',
          };
        });
        setAreasList(newAreas);
      }
    }, 10000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [latitude, longitude, areasList]);

  // get distance for each mission
  useEffect(() => {
    let distance: any;
    const interval = setInterval(() => {
      if (missions && missions[0] && latitude && longitude) {
        if (quests) {
          const newQuests: any = quests?.map((quest: any) => {
            if (quest.starterMission) {
              // eslint-disable-next-line
              distance = getDistance(
                {
                  latitude:
                    quest.starterMission?.latitude ||
                    quest.starterMission?.loc[1],
                  longitude:
                    quest.starterMission?.longitude ||
                    quest.starterMission?.loc[0],
                },
                { latitude: latitude, longitude: longitude },
              );

              return {
                ...quest,
                starterMission: {
                  ...quest.starterMission,
                  distance:
                    convertDistance(distance, 'km') < 1
                      ? distance + 'm'
                      : convertDistance(distance, 'km').toFixed() + 'km',
                },
              };
            }
            const newQuestMissions: any = quest?.missions?.map(
              (mission: any) => {
                if (mission) {
                  // eslint-disable-next-line
                  distance = getDistance(
                    {
                      latitude: mission?.latitude || mission?.loc[1],
                      longitude: mission?.longitude || mission?.loc[0],
                    },
                    { latitude: latitude, longitude: longitude },
                  );
                }

                return {
                  ...mission,
                  distance:
                    convertDistance(distance, 'km') < 1
                      ? distance + 'm'
                      : convertDistance(distance, 'km').toFixed() + 'km',
                };
              },
            );
            return {
              ...quest,
              missions: newQuestMissions,
            };
          });
          setQuest(newQuests);
        }
        if (localStorage.getItem('allData')) {
          const data = JSON.parse(localStorage.getItem('allData') || '');
          const newQuests: any = data?.map((quest: any) => {
            if (quest.starterMission) {
              // eslint-disable-next-line
              distance = getDistance(
                {
                  latitude:
                    quest.starterMission?.latitude ||
                    quest.starterMission?.loc[1],
                  longitude:
                    quest.starterMission?.longitude ||
                    quest.starterMission?.loc[0],
                },
                { latitude: latitude, longitude: longitude },
              );

              return {
                ...quest,
                starterMission: {
                  ...quest.starterMission,
                  distance:
                    convertDistance(distance, 'km') < 1
                      ? distance + 'm'
                      : convertDistance(distance, 'km').toFixed() + 'km',
                },
              };
            }

            const newQuestMissions: any = quest?.missions?.map(
              (mission: any) => {
                if (mission) {
                  // eslint-disable-next-line
                  distance = getDistance(
                    {
                      latitude: mission?.latitude || mission?.loc[1],
                      longitude: mission?.longitude || mission?.loc[0],
                    },
                    { latitude: latitude, longitude: longitude },
                  );
                }

                return {
                  ...mission,
                  distance:
                    convertDistance(distance, 'km') < 1
                      ? distance + 'm'
                      : convertDistance(distance, 'km').toFixed() + 'km',
                };
              },
            );
            return {
              ...quest,
              missions: newQuestMissions,
            };
          });
          localStorage.setItem('allData', JSON.stringify(newQuests));
        }
      }
    }, 10000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [latitude, longitude, mission, quests]);

  useEffect(() => {
    // update every 3 sec
    if (mission && latitude && longitude && onGoing) {
      const distance = getDistance(
        { latitude: latitude, longitude: longitude },
        {
          latitude: mission?.latitude || mission?.loc[1],
          longitude: mission?.longitude || mission?.loc[0],
        },
      );

      if (distance <= 500) {
        if (missionType !== 'enigmas') {
          setRefresh(true);
          const data = {
            url: '/map',
          };
          if (navigator.onLine && !document.hidden) {
            // User is currently  not on the website
          } else {
            showNotification(t('youdidit'), t('gocheckit'), '', data);
          }
        }
      }
    }
  }, [latitude, longitude, mission]);

  // handle Idle functions

  const onIdle = () => {
    setState('Idle');
    const data = {
      url: '/map/find-image',
    };

    const isiOS = getMobileOS() === 'iOS';
    const shouldNavigate =
      window.location.pathname !== '/map/find-image' &&
      isiOS &&
      !onGoing &&
      isLogged;
    const shouldShowNotification =
      !isiOS && !onGoing && isLogged && activeNotification;

    if (shouldNavigate) {
      window.location.href = '/map/find-image';
    }

    if (shouldShowNotification) {
      showNotification(
        t('wemissu'),
        t('somethingnew'),
        'https://cdn-icons-png.flaticon.com/512/2210/2210253.png',
        data,
      );
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive: () => {
      setState('Active');
    },
    // 30min
    onAction: () => {},
    timeout: 3600000,
    debounce: 500,
  });

  useEffect(() => {
    // check every 1hour
    const interval = setInterval(() => {
      const remainingTime = getRemainingTime();
      setRemaining(remainingTime);
    }, 3600000);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  });

  return (
    <>
      <RoutesComponent />
    </>
  );
}

export default App;
