// Importing necessary modules and components
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';

import { Loading } from 'components/LottieFiles';
import BadgesCard from 'components/BadgesCard';
import ObjectsCard from 'components/ObjectsCard';
import StatisticsCard from 'components/StatisticsCard';
import { Wrapper } from './Rewards.styles';

import { handleStatisticsItems } from 'helpers/RewardsHelper';
import { statisticsItemsType } from './Rewards.type';

import ClickSound from 'assets/sounds/Tap.wav';

// Custom hook imports
import useRewards from 'store/useRewards';
import useUser from 'store/useUser';

const Rewards: React.FC = () => {
  // Custom hook calls
  const [playClick] = useSound(ClickSound);
  const { isLogged } = useUser();
  const { badges, objects, coins } = useRewards();

  // Component state
  const [statisticsItems, setStatisticsItems] = useState<statisticsItemsType[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Navigation hook
  const navigate = useNavigate();

  // Handler for "See All" button click
  const handleSeeAll = (path: string) => {
    playClick();
    navigate(path);
  };

  useEffect(() => {
    // Redirect to login if not logged in
    if (!isLogged) {
      navigate('/start');
    }

    // Simulate a loader
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check if objects are loaded or stored in local storage
    if (objects && badges) {
      const data = handleStatisticsItems(badges.length, objects.length, coins);
      setStatisticsItems(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects, badges]);

  return (
    <Wrapper>
      {statisticsItems.length > 0 ? (
        <StatisticsCard items={statisticsItems} className="mb-6" />
      ) : (
        <Loading />
      )}
      {!isLoading && objects ? (
        <ObjectsCard
          items={objects.slice(0, 3)}
          handleSeeAll={() => handleSeeAll('all-objects')}
          className="mb-[5rem]"
        />
      ) : (
        <Loading />
      )}
      {!isLoading && badges ? (
        <BadgesCard
          badges={badges.slice(0, 9)}
          handleSeeAll={() => handleSeeAll('all-badges')}
        />
      ) : (
        <Loading />
      )}
    </Wrapper>
  );
};

export default Rewards;
