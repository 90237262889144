import tw from 'twin.macro';

export const PopupContainer = tw.div`
  relative w-full h-full  max-w-[23.375rem]
`;

export const PopupContent = tw.div`
w-full h-full flex flex-col justify-around items-center gap-4 p-6 rounded-xl
`;
export const PopupInfos = tw.div`
  w-full flex flex-col items-start justify-around gap-2
`;
export const Distance = tw.span`
 absolute top-5 right-5 text-3xl font-bold text-darkGray opacity-[0.49]
`;
