import Title from 'components/Typography/Title';
import { HeaderPage, QuizzContent, Wrapper } from './Quizzes.styles';
// import mission from './data.json';

import QcmQuiz from 'components/Quizzes/QcmQuiz';
import TypingQuiz from 'components/Quizzes/TypingQuiz';
import {
  handleQuizBarItems,
  QcmHandleQuizzesItems,
  QcmHandleTranslatedItems,
} from './Helper';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QuizBar from 'components/Quizzes/QuizBar';
import usePersonalAssistant from 'store/usePersonalAssistant';
import useMission from 'store/useMission';
import useUser from 'store/useUser';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import applause from 'assets/sounds/applause.wav';
import { useTranslation } from 'react-i18next';

const QuizzItem = () => {
  const { t, i18n } = useTranslation();
  // sounds
  const [playClick] = useSound(ClickSound);
  const [playApplause] = useSound(applause);
  // states
  const { isLogged } = useUser();
  const { pa } = usePersonalAssistant();
  const navigate = useNavigate();
  const params = useParams();
  const [item, setItem] = useState<any>(null);
  const { mission, setMission } = useMission();
  const [items, setItems] = useState<any>([]);

  // check if the user is logged or not
  useEffect(() => {
    if (!isLogged) {
      navigate('/start');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (params.id) {
      const item = mission?.quizzes?.find((item: any) => item.id === params.id);
      if (item) {
        const items = item.translatedAnswers
          ? QcmHandleTranslatedItems(item.translatedAnswers, i18n?.language)
          : QcmHandleQuizzesItems(item.answers);

        setItems(items);
        Object.assign(item, { active: true });
        setItem(item);
      } else {
        navigate('/map');
      }
    } else {
      navigate('/map');
    }
    // eslint-disable-next-line
  }, [params, item]);

  // get next quiz id onValidate
  const getNextQuizId = () => {
    const currentQuizId = params.id;
    const currentQuizIndex = mission.quizzes.findIndex(
      (quiz: any) => quiz.id === currentQuizId,
    );
    const nextQuiz = mission.quizzes[currentQuizIndex + 1];
    setItem(null);
    return nextQuiz ? nextQuiz.id : null;
  };

  const onNext = () => {
    playClick();
    const nextQuizId: any = getNextQuizId();
    if (nextQuizId) {
      navigate(`/quizzes/${nextQuizId}`);
      Object.assign(item, { active: false, completed: true, isCorrect: true });
      // update mission
      const currentQuizIndex = mission.quizzes.findIndex(
        (quiz: any) => quiz.id === item.id,
      );
      const newMission = { ...mission };
      newMission.quizzes[currentQuizIndex] = item;
      setMission(newMission);
    } else {
      playApplause();
      navigate('/quizzes/final/result');
      Object.assign(item, { active: false, completed: true, isCorrect: true });
      // update mission
      const currentQuizIndex = mission.quizzes.findIndex(
        (quiz: any) => quiz.id === item.id,
      );
      const newMission = { ...mission };
      newMission.quizzes[currentQuizIndex] = item;
      setMission(newMission);
    }
  };

  const onRetry = () => {
    onSkip();
  };

  const onSkip = () => {
    const nextQuizId: any = getNextQuizId();
    if (nextQuizId) {
      Object.assign(item, { active: false, completed: true });
      navigate(`/quizzes/${nextQuizId}`);
      setItem(null);
    } else {
      playApplause();
      Object.assign(item, { active: false, completed: true });
      setItem(null);
      navigate('/quizzes/final/result');
    }
  };

  const gettranslatedcorrectAnswers = () => {
    const translatedcorrectAnswers = item?.translatedcorrectAnswers?.map(
      (item: any) => {
        return item?.[i18n.language];
      },
    );
    return translatedcorrectAnswers;
  };

  return (
    <Wrapper>
      <HeaderPage>
        <Title level={1} renderAs="h1">
          {`${mission?.dictionary?.name[i18n.language]} ${t('quizzes')}`}
        </Title>
        <QuizBar quizzes={handleQuizBarItems(mission?.quizzes || [])} />
      </HeaderPage>
      {item && (
        <QuizzContent>
          <QcmQuiz
            avatar={pa.avatar}
            question={
              item?.dictionary
                ? item?.dictionary?.question?.[i18n.language]
                : item.question
            }
            items={items}
            correctAnswers={
              item?.dictionary
                ? gettranslatedcorrectAnswers()
                : item?.correctAnswers || []
            }
            onCorrect={onNext}
            onSkip={onSkip}
            onWrong={onRetry}
            showCorrectAnswers={false}
          />
        </QuizzContent>
      )}
    </Wrapper>
  );
};

export default QuizzItem;
