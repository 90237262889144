const ArrowRight = ({
  width = 8,
  height = 12,
  color = '#F9FCFD',
  stroke = '#013051',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.46458 5.07273C6.86777 5.45016 6.88865 6.08298 6.51122 6.48617L3.04565 10.1883C2.4368 10.8387 1.34553 10.4283 1.31615 9.53785L1.07962 2.37018C1.05024 1.47976 2.11208 0.998306 2.76248 1.60715L6.46458 5.07273Z"
        fill={color}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowRight;
