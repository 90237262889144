import but from 'assets/icons/map/but.png';
import quest from 'assets/icons/map/quest.png';
import { Pins } from './Map.types';
export const missions: Pins = [
  {
    type: 'mission',
    icon: but,
    name: 'medina',
    coins: 230,
    difficulty: 'hard',
    distance: 12,
    latitude: 41.8376,
    longitude: -87.6818,
  },
  {
    type: 'mission',
    icon: but,
    name: 'new medina',
    coins: 530,
    difficulty: 'easy',
    distance: 12,
    latitude: 34.0194,
    longitude: -118.4108,
  },
  {
    type: 'mission',
    icon: but,
    name: 'world cup',
    coins: 1030,
    difficulty: 'hard',
    distance: 6,
    latitude: 47.0094,
    longitude: -117.135,
  },
  {
    type: 'quests',
    missions: 12,
    icon: quest,
    name: 'old medina',
    coins: 2000,
    difficulty: 'hard',
    distance: 50,
    latitude: 29.7805,
    longitude: -95.3863,
  },
  {
    type: 'quests',
    missions: 20,
    icon: quest,
    name: 'medina top',
    coins: 2000,
    difficulty: 'hard',
    distance: 50,
    latitude: 33.5722,
    longitude: -112.088,
  },
  {
    type: 'mission',
    icon: but,
    name: 'old yassir',
    coins: 230,
    difficulty: 'hard',
    distance: 12,
    latitude: 40.0094,
    longitude: -75.1333,
  },

  {
    type: 'quests',
    missions: 32,
    icon: quest,
    name: 'san diego',
    coins: 2000,
    difficulty: 'hard',
    distance: 50,
    latitude: 40.6643,
    longitude: -73.9385,
  },
  {
    type: 'quests',
    icon: quest,
    missions: 14,
    name: 'medina right',
    coins: 2000,
    difficulty: 'hard',
    distance: 50,
    latitude: 32.7757,
    longitude: -96.7967,
  },
  {
    type: 'quests',
    missions: 12,
    icon: quest,
    name: 'medina left',
    coins: 2000,
    difficulty: 'hard',
    distance: 50,
    latitude: 37.2969,
    longitude: -121.8193,
  },
];

export const swiperData: { text: string; role: 'pa' | 'object' }[] = [
  {
    text: 'I guess you will like it, so tell me what do you feel like now I guess you will like it, so tell me what do you feel like now I guess you will like it, so tell me what do you feel like now ',
    role: 'pa',
  },
  {
    text: 'I guess you will like it, so tell me what do you feel like now I guess you will like it, so tell me what do you feel like now I guess you will like it, so tell me what do you feel like now ',
    role: 'object',
  },
  {
    text: 'I guess you will like it, so tell me what do you feel like now I guess you will like it, so tell me what do you feel like now I guess you will like it, so tell me what do you feel like now ',
    role: 'pa',
  },
  {
    text: 'I guess you will like it, so tell me what do you feel like now I guess you will like it, ',
    role: 'object',
  },
];

export const singleGuidData = [
  'I guess you will like it, so tell me what do you feel like now I guess you will like it',
  'I guess you will like it, so tell me what do you feel like now I guess you will like it',
  'I guess you will like it, so tell me what do you feel like now I guess you will like it',
  'I guess you will like it, so tell me what do you feel like now I guess you will like it',
];

export const findPlaceData = {
  title: 'Find Ibn Sinna',
  points: 20,
  distance: '500m',
};
