import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { BackDrop } from './Modal.styles';
import { ModalProps } from './Modal.types';

const Modal = ({
  children,
  isOpen,
  position = 'center',
  closeModal,
}: ModalProps) => {
  return (
    <Transition show={isOpen} as={Fragment} data-testid="modal">
      <Dialog
        as="div"
        className="relative z-50"
        open={isOpen}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter=" ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <BackDrop />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Dialog.Panel
              className={`w-[100%] max-w-[23.25rem] transform text-left align-middle transition-all ${
                position === 'bottom' ? 'self-end mb-32' : ''
              } `}
            >
              {children}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
