import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface rewardsData {
  placeId: string;
  badges: [];
  objects: [];
  coins: number;
  setBadges: (badges: []) => void;
  setObjects: (objects: []) => void;
  setCoins: (coins: number) => void;
}

const useRewards = create<rewardsData>()(
  persist(
    (set) => ({
      placeId: '',
      badges: [],
      objects: [],
      coins: 0,
      setBadges: (badges: []) =>
        set((state) => ({
          ...state,
          badges,
        })),
      setObjects: (objects: []) =>
        set((state) => ({
          ...state,
          objects,
        })),
      setCoins: (coins: number) =>
        set((state) => ({
          ...state,
          coins,
        })),
    }),
    {
      name: 'rewards',
    },
  ),
);

export default useRewards;
