import {
  LinksList,
  NavBarContainer,
  NavBarLink,
  NavBarLogo,
} from './NavBar.styles';
import { NavBarLinkType, NavBarProps } from './NavBar.types';
import { NavLink, useLocation } from 'react-router-dom';
import Label from 'components/Typography/Label';
import useKeyboard from 'store/useKeyboard';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMission from 'store/useMission';

const NavBar = ({ links }: NavBarProps): JSX.Element => {
  const { t } = useTranslation();
  const { onGoing, mission, missionType } = useMission();
  const { hasKeyboard } = useKeyboard();
  const [playClick] = useSound(ClickSound);
  const [newLinks, setLinks] = useState(links);
  const location = useLocation();

  const handleClick = (item: NavBarLinkType) => {
    const updatedLinks = links?.map((link) =>
      link.link === item.link
        ? { ...link, active: true }
        : { ...link, active: false },
    );
    setLinks(updatedLinks);
    playClick();
  };

  // update active link with url if match of the link item

  useEffect(() => {
    const updatedLinks = links?.map((link) =>
      link.link === location.pathname
        ? { ...link, active: true }
        : { ...link, active: false },
    );
    setLinks(updatedLinks);
  }, [location, links]);

  return (
    <NavBarContainer isHidden={hasKeyboard}>
      <LinksList>
        {newLinks?.map((item) => (
          <NavLink
            to={!onGoing && mission && !missionType ? item.link : '#'}
            key={item.link}
            onClick={() => handleClick(item)}
          >
            <NavBarLink
              isActive={item.active}
              key={item.link}
              disabled={onGoing}
            >
              <NavBarLogo src={item.image} alt={item.title} />
              <Label>{t(item.title)}</Label>
            </NavBarLink>
          </NavLink>
        ))}
      </LinksList>
    </NavBarContainer>
  );
};

export default NavBar;
