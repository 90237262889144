import { ChangeEventHandler, useState } from 'react';
//components
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Typography/Label';
import Title from 'components/Typography/Title';
//Styles and Icons
import {
  EditButton,
  EditPanel,
  Form,
  Icon,
  InputWrapper,
} from './EditModal.styles';
import plume from 'assets/icons/plume.png';
import EditIcon from 'Icons/EditIcon';
import { EditModalProps } from './EditModal.types';
import Modal from 'components/Modal';

const EditModal = ({ handleSubmit }: EditModalProps): JSX.Element => {
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleChnage: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <EditButton onClick={openModal}>
        {isOpen ? <EditIcon /> : <Icon src={plume} alt="edit-icon" />}
        <Label>edit info</Label>
      </EditButton>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <EditPanel width="110%">
          <Title renderAs="h2" level={2}>
            edit info
          </Title>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <InputWrapper>
              <label htmlFor="name">your name</label>
              <Input
                edit={true}
                id="name"
                value={userInfo.name}
                onChange={(e) => handleChnage(e)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="email">your email</label>
              <Input
                edit={true}
                id="email"
                value={userInfo.email}
                onChange={(e) => handleChnage(e)}
              />
            </InputWrapper>
            <Button
              rotation="-1.65deg"
              isRounded={false}
              hasAfter={false}
              shadowColor="#161F21"
              width="100%"
            >
              update Info
            </Button>
          </Form>
        </EditPanel>
      </Modal>
    </>
  );
};

export default EditModal;
