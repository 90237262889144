import splashIcon from 'assets/splash/splash_icon.png';
import { Background, PAs, Wrapper } from './SplashScreen.styles';
import bg from 'assets/splash/spalsh_bg.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const SpalshScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timerid = setTimeout(() => {
      navigate('map', { replace: true });
    }, 5000);
    return () => {
      clearTimeout(timerid);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Background src={bg} alt="splash" />
      {/* <PAs src={splashIcon} alt="pa charachters" /> */}
    </Wrapper>
  );
};

export default SpalshScreen;
