import Modal from 'components/Modal';
import Title from 'components/Typography/Title';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn, Wrapper } from './CreateAccount.styles';
import { CreateAccountProps } from './CreateAccount.types';
import CreateAccountPanel from './CreateAccountPanel';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';

const CreateAccount = ({
  bgColor = '#D8FBEA',
  handleCreateAccountWithFb,
  handleCreateAccountWithGmail,
  handleSubmit,
  ...rest
}: CreateAccountProps) => {
  const [playClick] = useSound(ClickSound);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    playClick();
    setIsOpen(false);
  };
  const openModal = () => {
    playClick();
    setIsOpen(true);
  };
  const { t } = useTranslation();
  return (
    <>
      <Wrapper bgColor={bgColor} {...rest}>
        <Title renderAs="h3" level={3}>
          {t('noAccount')}
        </Title>
        <Btn
          isRounded={false}
          hasAfter={false}
          shadowColor="black"
          width="90%"
          height="3.625rem"
          rotation="-1.65deg"
          onClick={openModal}
        >
          {t('createAccount')}
        </Btn>
      </Wrapper>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <CreateAccountPanel
          handleCreateAccountWithFb={handleCreateAccountWithFb}
          handleSubmit={handleSubmit}
          handleCreateAccountWithGmail={handleCreateAccountWithGmail}
        />
      </Modal>
    </>
  );
};

export default CreateAccount;
