import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MapGL, {
  GeolocateControl,
  MapProvider,
  MapRef,
  Marker,
  Popup,
  useMap,
} from 'react-map-gl';
import { Outlet, useNavigate } from 'react-router-dom';
import { convertDistance, getDistance } from 'geolib';
import { missions } from './dummy';
// Styles
import { GuideWrapper, StyledMarker } from './Map.styles';
import { Cordonate } from './Map.types';
// Components
import Modal from 'components/Modal';
import MarkerPopup from 'components/MarkerPopup';
import SpeechSwiper from 'components/SpeechSwiper';
import WinningPopup from 'components/WinningPopup';
import { Loading, WinningLottie } from 'components/LottieFiles';
import IOSGuideCmpt from 'components/IOSGuide';
// Constants
import { MAPBOX_TOKEN, MAP_STYLE } from 'utils/constants';
// Stores
import useData from 'store/useData';
import useUser from 'store/useUser';
import useMission from 'store/useMission';
import useRewards from 'store/useRewards';
import useOnboarding from 'store/useOnboarding';
// Helpers
import {
  onDialogueCloseHelper,
  onGuideCloseHelper,
  onStart,
} from 'helpers/MapPageHelper';
import { getPabyName } from 'helpers/AvatarsHelper';
import {
  getTotalQuestCoins,
  updateQuest,
  updateSelectedQuest,
} from 'helpers/QuestsHelpers';
// sounds effect
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import applause from 'assets/sounds/applause.wav';
import collectCoins from 'assets/sounds/collectCoins.wav';
// Trnaslation
import i18n from 'i18n';
// images
import NoImage from 'assets/images/noImage.png';
import questIcon from 'assets/icons/map/quest.png';
// hooks
import { usePosition } from 'hooks/usePosition';
import { getImagefromMedia, getMobileOS } from 'helpers/GlobalHelper';
import { useTranslation } from 'react-i18next';
import mapboxgl from 'mapbox-gl';
// Service worker

const MapComponent = () => {
  const { t } = useTranslation();
  // navigator
  const navigate = useNavigate();
  // sounds effect
  const [play] = useSound(ClickSound);
  const [playApplause] = useSound(applause);
  const [playCollect] = useSound(collectCoins);
  // get player position
  const { latitude, longitude } = usePosition(true, { timeout: 3000 });
  let distance: any;
  // map states
  const [popupInfo, setPopupInfo] = useState<null | any>(null);
  const mapRef = useRef<MapRef>(null);
  const [viewState, setViewState] = useState({
    latitude: 33.5723328,
    longitude: -7.7428804,
    zoom: 13,
  });
  const { current: map } = useMap();

  // data
  const { quests } = useData();
  const { user } = useUser();
  const { coins, badges, objects, setCoins, setBadges } = useRewards();
  const {
    selectedQuest,
    mission,
    onGoing,
    object,
    disableMarkerState,
    missionType,
    refresh,
  } = useMission();
  const { IOSGuide, setIOSGuide } = useOnboarding();
  const [guideData, setGuideData] = useState<Array<string>>([]);
  const [dialogueData, setDialogueData] = useState<any>([]);

  // actions
  const { setQuest } = useData();
  const { setObjects } = useRewards();
  const {
    setSelectedQuest,
    setMission,
    toggleMission,
    setObject,
    setDisableMarkerState,
    setMissionType,
    setRefresh,
  } = useMission();

  // Modals states
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [guideModal, setGuideModal] = useState<boolean>(false);
  const [dialogueModal, setDialogueModal] = useState<boolean>(false);
  const [win, setIsWin] = useState<boolean>(false);
  const [isWon, setIsWon] = useState<boolean>(false);
  const [isWonBadge, setIsWonBadge] = useState<boolean>(false);
  const [isWonEnigma, setIsWonEnigma] = useState<boolean>(false);
  const [disableMarker, setDisableMarker] =
    useState<boolean>(disableMarkerState);

  // Loading state
  const [loading, setLoading] = useState<boolean>(true);

  //Animate the trasnition between missions
  const animateTo = useCallback(({ longitude, latitude }: Cordonate) => {
    mapRef.current?.flyTo({ center: [longitude, latitude], duration: 1500 });
  }, []);

  //update viewState lat and long
  useEffect(() => {
    if (latitude && longitude)
      setViewState({ ...viewState, latitude, longitude });
    // eslint-disable-next-line
  }, [latitude, longitude]);

  // Restart mission after returning from another page

  useEffect(() => {
    if (mission && mission?.finished === false && !onGoing && !mission?.starter)
      handleStart();
  }, [mission]);

  // clean up and remove map
  useEffect(() => {
    const cleanup = () => {
      if (mapRef.current) {
        const map = mapRef.current.getMap();
        const gl = map?.getCanvas().getContext('webgl');
        map.remove();
        setPinsLoad(false);
        if (gl) {
          const loseContextExtension = gl.getExtension('WEBGL_lose_context');
          if (loseContextExtension) {
            loseContextExtension.loseContext();
          }
        }
      }
    };

    // Cleanup previous instance when component unmounts
    return cleanup;
  }, []);

  // useEffects
  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // check if the user position at the mission position and show winning popup if distance === 0
  useEffect(() => {
    // if (latitude && longitude)
    //   setViewState({ ...viewState, latitude, longitude });
    if (
      onGoing &&
      mission &&
      missionType === 'enigmas' &&
      latitude &&
      longitude
    ) {
      // set position
      // eslint-disable-next-line
      distance = getDistance(
        {
          latitude: mission?.latitude || mission?.loc[1],
          longitude: mission?.longitude || mission?.loc[0],
        },
        { latitude: latitude, longitude: longitude },
      );

      if (distance <= 500) {
        delete selectedQuest.enigmas;
        setSelectedQuest({
          ...selectedQuest,
          order: selectedQuest?.order?.slice(1),
        });
        playApplause();
        setIsWonEnigma(true);
      }

      setMission({
        ...mission,
        distance:
          convertDistance(distance, 'km') < 1
            ? distance + 'm'
            : convertDistance(distance, 'km').toFixed() + 'km',
      });
    }
    // eslint-disable-next-line
  }, [latitude, longitude, missionType]);

  useEffect(() => {
    if (refresh) handleStart();
    setRefresh(false);
    setDisableMarker(disableMarkerState);
    if (!onGoing) {
      setDisableMarker(false);
      setDisableMarkerState(false);
      toggleMission(false);
    }
    // eslint-disable-next-line
  }, [disableMarkerState, refresh, onGoing]);

  // watch if user position is on the mission position

  // handle close modal
  const handleClose = () => {
    setIsOpen(false);
  };

  const updateQuests = async () => {
    await updateSelectedQuest(selectedQuest, mission).then(async (res) => {
      setSelectedQuest(res);
      await updateQuest(selectedQuest).then((res) => {
        setQuest(res);
      });
    });
  };

  // handle start missions
  const handleStart = () => {
    play();
    onStart({
      play,
      playApplause,
      selectedQuest,
      mission,
      setGuideData,
      setGuideModal,
      onClose: handleClose,
      setDialogueData,
      setDialogueModal,
      updateQuests,
      setObject,
      setMission,
      setIsWon,
      setIsWin,
      navigate,
      toggleMission,
      setDisableMarker,
      setDisableMarkerState,
      setIsWonBadge,
      missionType,
      setMissionType,
      selectedLanguge: i18n.language,
    });
  };

  // Description or guide modal
  const onGuideClose = async () => {
    play();
    onGuideCloseHelper({
      play,
      playApplause,
      selectedQuest,
      mission,
      setGuideData,
      setGuideModal,
      onClose: handleClose,
      setDialogueData,
      setDialogueModal,
      updateQuests,
      setObject,
      setMission,
      setIsWon,
      setIsWin,
      navigate,
      toggleMission,
      setDisableMarker,
      setDisableMarkerState,
      setIsWonBadge,
      missionType,
      setMissionType,
      selectedLanguge: i18n.language,
    });
  };

  // Conversation or dialogue modal
  const onDialogueClose = async () => {
    play();
    onDialogueCloseHelper({
      play,
      playApplause,
      selectedQuest,
      mission,
      setGuideData,
      setGuideModal,
      onClose: handleClose,
      setDialogueData,
      setDialogueModal,
      updateQuests,
      setObject,
      setMission,
      setIsWon,
      setIsWin,
      navigate,
      toggleMission,
      setDisableMarker,
      setDisableMarkerState,
      handleStart,
      setIsWonBadge,
      missionType,
      setMissionType,
      selectedLanguge: i18n.language,
    });
  };

  const onCloseSpeech = () => {
    setPopupInfo(null);
    setIsOpen(false);
    setGuideModal(false);
    setDialogueModal(false);
  };

  // Cellect reward popup CTA
  const collectRewards = async () => {
    // check if object exists in objects
    const objectExists = objects.find((obj: any) => obj._id === object?._id);
    if (!objectExists) {
      const newObjects: any = [...objects, object];
      setObjects(newObjects);
    }
    mission.order = mission.order.slice(1);
    await updateQuests();
    setObject(null);
    setDialogueModal(false);
    setGuideModal(false);
    setIsWon(false);
    toggleMission(false);
    setDisableMarker(false);
    setDisableMarkerState(false);
    handleClose();
    handleStart();
  };

  // Collect Enigmas rewards
  const collectEnigmaRewards = async () => {
    playCollect();
    if (mission?.badges && mission?.badges[0]) {
      setObject(mission.badges[0]);
      playApplause();
      setIsWonBadge(true);
      selectedQuest.hasEnigme = false;
      await updateQuests();
    } else {
      setCoins(coins + mission?.coins);
      setIsWonEnigma(false);
      setMission(selectedQuest?.missions[0]);
      toggleMission(false);
      setMissionType(null);
      setRefresh(true);
      selectedQuest.hasEnigme = false;
      await updateQuests();
    }
  };

  // Collecting Badges
  const collectBadge = () => {
    playCollect();
    const badge = badges.find((badge: any) => {
      if (
        mission?.badges &&
        mission?.badges[0] &&
        badge?._id === mission?.badges[0]?._id
      )
        return badge;
      else return badge?._id === object?._id;
    });

    if (badge) {
      setIsWonBadge(false);
      setIsWonEnigma(false);
      missionType === 'enigmas' && setMission(selectedQuest?.missions[0]);
      setMissionType(null);
      setRefresh(true);
      setGuideModal(false);
    } else {
      const newBadges: any = [...badges, object];
      setBadges(newBadges);
      mission?.coins && setCoins(coins + mission?.coins);
      setIsWonBadge(false);
      setIsWonEnigma(false);
      missionType === 'enigmas' && setMission(selectedQuest?.missions[0]);
      setMissionType(null);
      setRefresh(true);
    }
  };

  const getName = (item: any) => {
    if (item?.dictionary) {
      return item?.dictionary?.name[i18n.language];
    }
    return item?.name;
  };

  const getDescription = (item: any) => {
    if (item?.dictionary && item?.dictionary?.description) {
      return item?.dictionary?.description[i18n.language];
    }
    return item?.description;
  };

  const getImage = (image: string) => {
    let location = image;
    let searchString = 'https://';

    if (
      !location?.includes(searchString) &&
      !location?.includes('data:image/png')
    ) {
      // replace searchString with empty string
      return searchString + location;
    } else return image;
  };

  // create markers using useMemo
  const pins = useMemo(() => {
    if (!quests || quests.length === 0) {
      return null;
    }

    return quests.map((quest, index) => {
      const mission =
        quest.starterMission ||
        (!quest.hasEnigme && quest.missions.length > 0 && quest.missions[0]);

      if (!mission || mission.latitude === undefined) {
        return null;
      }

      const handleClick = (e: {
        originalEvent: { stopPropagation: () => void };
      }) => {
        e.originalEvent.stopPropagation();
        if (!quest.finished && !disableMarker) {
          setIsOpen(true);
          play();
          setMissionType(quest.starterMission ? 'starter' : '');
          setPopupInfo(mission);
          setMission(mission);
          setSelectedQuest(quest);
          animateTo({
            longitude: mission.longitude,
            latitude: mission.latitude,
          });
        }
      };

      return (
        <Marker
          key={quest.name + index}
          longitude={mission.longitude || mission.loc[0]}
          latitude={mission.latitude || mission.loc[1]}
          onClick={handleClick}
        >
          <StyledMarker
            className={index === 0 ? missions[0]?.name : ''}
            isActive={false}
            isDisabled={quest.finished || disableMarker}
          >
            <img src={questIcon} alt={`${quest.name} marker`} />
          </StyledMarker>
        </Marker>
      );
    });
    // eslint-disable-next-line
  }, [quests, disableMarker, animateTo]);

  const geoControlRef = useRef<mapboxgl.GeolocateControl>(null);

  const handleMapLoading = () => {
    geoControlRef.current?.trigger();
  };

  const [pinsLoad, setPinsLoad] = useState(false);

  return loading ? (
    <div className="w-full flex justify-center items-center">
      <Loading />
    </div>
  ) : (
    <MapProvider>
      <Outlet />
      <MapGL
        onLoad={handleMapLoading}
        reuseMaps
        ref={mapRef}
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle={MAP_STYLE}
        style={{ width: '100%', height: 'calc(100vh - 5rem)' }}
        onRender={() => setPinsLoad(true)}
      >
        <GeolocateControl
          ref={geoControlRef}
          position="top-left"
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation
          showUserHeading
          showUserLocation
        />
        {pinsLoad && pins}
        {/* // add Popup */}
        {popupInfo && (
          <Popup
            style={{ transform: 'none' }}
            maxWidth="none"
            closeButton={false}
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={handleClose}
          >
            <Modal position="bottom" closeModal={handleClose} isOpen={isOpen}>
              <MarkerPopup
                type={popupInfo.type}
                name={
                  popupInfo?.dictionary?.name[i18n.language] || popupInfo?.name
                }
                missions={popupInfo?.missions}
                icon={questIcon}
                coins={getTotalQuestCoins(selectedQuest)}
                level={popupInfo?.level}
                distance={popupInfo?.distance}
                onClose={handleClose}
                onStart={handleStart}
              />
            </Modal>
          </Popup>
        )}
        {/* Guid Modal */}
        {guideModal && (
          <GuideWrapper>
            <SpeechSwiper
              isConversation={false}
              name={user?.username || 'Anonymous'}
              type="mission"
              data={guideData}
              paImage={user?.avatarImage || ''}
              paPosition="left"
              onStart={onGuideClose}
              onClose={onCloseSpeech}
            />
          </GuideWrapper>
        )}
        {/* Dialogue Modal */}
        {dialogueModal && (
          <GuideWrapper>
            <SpeechSwiper
              isConversation={true}
              name={user?.username || 'Anonymous'}
              type="mission"
              data={
                dialogueData?.conversation[i18n.language] ||
                dialogueData?.conversation
              }
              paName={
                (dialogueData?.persons[0] &&
                  (dialogueData?.persons[0] === 'PA'
                    ? user?.avatarName
                    : dialogueData?.persons[0])) ||
                ''
              }
              paImage={
                getPabyName(dialogueData?.persons[0]) === 'PA'
                  ? user?.avatarImage
                  : getPabyName(dialogueData?.persons[0] || '')
              }
              paPosition="left"
              paColor="#007AD1"
              objectImage={
                getPabyName(dialogueData?.persons[1]) === 'PA'
                  ? user?.avatarImage
                  : getPabyName(dialogueData?.persons[1] || '')
              }
              objectName={
                (dialogueData?.persons[1] &&
                  (dialogueData?.persons[1] === 'PA'
                    ? user?.avatarName
                    : dialogueData?.persons[1])) ||
                ''
              }
              objectColor="#FE4A4F"
              onStart={onDialogueClose}
              onClose={onCloseSpeech}
              buttonText={dialogueData.end ? `${t('finishmission')}` : ''}
            />
          </GuideWrapper>
        )}
        <Modal closeModal={() => {}} isOpen={isWon}>
          <>
            {object && (
              <WinningPopup
                object={true}
                collectRewards={collectRewards}
                objectImage={
                  getImage(getImagefromMedia(object?.media)) || NoImage
                }
                objectName={getName(object) || ''}
              />
            )}
            {win && <WinningLottie loop={false} />}
          </>
        </Modal>
        <Modal closeModal={() => {}} isOpen={isWonBadge}>
          <>
            {object && (
              <WinningPopup
                object={true}
                collectRewards={collectBadge}
                objectImage={getImagefromMedia(object?.media) || NoImage}
                objectName={getName(object) || ''}
              />
            )}
            {win && <WinningLottie loop={false} />}
          </>
        </Modal>
        <Modal closeModal={() => {}} isOpen={isWonEnigma}>
          <WinningPopup
            coins={+mission?.coins}
            collectRewards={collectEnigmaRewards}
            object={false}
          />
        </Modal>
        <Modal closeModal={() => {}} isOpen={IOSGuide} position="bottom">
          <IOSGuideCmpt
            onClick={() => {
              setIOSGuide(false);
            }}
          />
        </Modal>
      </MapGL>
    </MapProvider>
  );
};

export default MapComponent;
