import tw from 'twin.macro';
import styled from 'styled-components';
import { TileProps } from './PuzzleCard.types';

export const PuzzleCardContainer = styled.div`
  ${tw`w-full h-full rounded-[31px] p-4 flex items-center justify-center`};
  box-shadow: inset 0 0 30px 30px #63feba;
`;

export const PuzzleCardGame = styled.div`
  ${tw`w-full h-full rounded-2xl py-5 px-1 flex items-center justify-center bg-softGray`};
`;

export const Board = tw.div`w-full h-full relative`;

export const Tile = styled.div<TileProps>`
  ${tw`w-[5.4rem] h-[5.4rem] bg-white rounded-[2px] flex items-center justify-center absolute transition-all duration-75 ease-in-out`};
  background-image: url(${({ image }) => image});
  background-size: 270px 270px;
`;
