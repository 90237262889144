import tw from 'twin.macro';
import styled from 'styled-components';
import { QcmQuizItemStylesProps } from './QcmQuizItem.type';
// import Icon from ""

export const QcmQuizItemContainer = styled.div<QcmQuizItemStylesProps>`
  ${tw`w-fit h-[3.563rem] p-3.5 border-2 rounded-xl bg-white
  flex items-center justify-between gap-2.5 cursor-pointer`};
  ${({ checked, correct }) => {
    if (checked && correct) {
      return tw`bg-success`;
    }
    if (checked && !correct) {
      return tw`bg-red`;
    }
    return tw`bg-white`;
  }}
`;

export const QcmQuizItemCheckBox = styled.input<QcmQuizItemStylesProps>`
  ${tw` appearance-none relative w-[0.875rem] h-[0.875rem] rounded-[0.188rem] border-2 cursor-pointer bg-white`};

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    top: -5px;
    left: 1px;
    position: absolute;
    display: inline-block;
    ${({ checked, correct }) => {
      if (checked && correct) {
        return `background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 8.4L7.40909 14L19 2" stroke="%23000000" strokeWidth="3" strokeLinecap="round" stroke-linejoin="round"/></svg>');`;
      }
      if (checked && !correct) {
        return `background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 21L21 2" stroke="%23000000" strokeWidth="3" strokeLinecap="round" stroke-linejoin="round"/><path d="M21 21L2 2" stroke="%23000000" strokeWidth="3" strokeLinecap="round" stroke-linejoin="round"/></svg>');
                top: -4px;
                left: -4px;
                width: 18px;
                height: 18px;
            `;
      }
    }}
  }
`;
