import tw from 'twin.macro';
import styled from 'styled-components';
import Button from 'components/Button';
import { CountItemProps } from './ActiveState.types';

export const StateContainer = tw.div`relative w-full h-full rounded-2xl bg-softGray flex flex-col items-center justify-between p-4 gap-6 pb-8 [&>button]:-mt-3`;

export const StateHeader = tw.div`w-full flex items-center justify-center gap-1`;

export const StateContent = tw.div`w-[16.688rem] p-[1.313rem] gap-2 rounded-xl border-2 border-solid border-darkGray border-opacity-[0.5] flex flex-col items-center justify-center mb-2`;

export const StateDivider = tw.div`w-[14.063rem] border border-solid border-darkGray border-opacity-[0.5]`;

export const StateCountDownContainer = tw.div`w-full flex flex-col items-start justify-center gap-2`;

export const StateCountDown = styled.div`
  ${tw`w-full flex items-center justify-start gap-4 self-start`};
`;

export const StateCountDownItem = styled.div<CountItemProps>`
  ${tw`relative w-[2.851rem] h-[3.125rem] border-[2.5px] border-solid border-darkGray rounded-lg
    flex items-center justify-center text-[1.25rem] text-darkGray font-bold last:after:[content: ""]`};
  border-color: ${({ isDanger }) => isDanger && '#FF0000'};
  &:after {
    ${tw`[content: ":"]  absolute inset-0 translate-x-[1.9rem] flex items-center justify-center`};
  }
`;

export const StateAction = styled(Button)`
  ${tw`absolute bottom-0 left-auto right-auto translate-y-1/2 outline-none`}
`;

export const StateImageIcon = tw.img`w-[8.875rem] h-[3.75rem] object-contain`;
