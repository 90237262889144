import {
  QuizBarItemCoin,
  QuizBarItemContainer,
  QuizBarItemImageCoin,
} from './QuizBarItem.styles';
import { QuizBarItemProps } from './QuizBarItem.type';
import coin from 'assets/icons/coins/coin.png';

const QuizBarItem = ({
  quizNumber,
  isActive = false,
  isCompleted,
  isLocked = true,
  coins,
}: QuizBarItemProps): JSX.Element => {
  return (
    <QuizBarItemContainer
      isActive={isActive}
      isCompleted={isCompleted}
      isLocked={isLocked}
      data-testid="QuizBarItem"
    >
      {isActive ? (
        <>
          Question {quizNumber}
          <QuizBarItemCoin>
            {coins}
            <QuizBarItemImageCoin src={coin} alt={'' + coins} />
          </QuizBarItemCoin>
        </>
      ) : (
        quizNumber
      )}
    </QuizBarItemContainer>
  );
};

export default QuizBarItem;
