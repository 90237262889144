import Button from 'components/Button';
import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import { Shape, Wrapper } from './HowToPlayCard.styles';
import { HowToPlayCardProps } from './HowToPlayCard.types';
import shape from 'assets/icons/Mask_group.svg';
import { useTranslation } from 'react-i18next';

const HowToPlayCard = ({
  description,
  bgColor = '#D8FBDB',
  handlePlay,
  ...rest
}: HowToPlayCardProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Wrapper bgColor={bgColor} {...rest}>
      <Title renderAs="h2" level={2}>
        {t('howtoplay')}
      </Title>
      <Paragraph>{description}</Paragraph>
      <Button
        rotation="-2deg"
        width="90%"
        bgColor="#F9FCFD"
        color="#161F21"
        shadowColor="#161F21"
        isRounded={false}
        hasAfter={false}
        onClick={handlePlay}
      >
        {t('howtoplay')}
      </Button>
      <Shape src={shape} />
    </Wrapper>
  );
};

export default HowToPlayCard;
