import {
  AvatarCardContainer,
  AvatarCardImage,
  AvatarCardText,
} from './AvatarCard.styles';
import { AvatarCardProps } from './AvatarCard.types';

const AvatarCard = ({
  name,
  imageSrc,
  color,
  bgColor,
  borderColor,
  ...rest
}: AvatarCardProps): JSX.Element => {
  return (
    <AvatarCardContainer
      color={color}
      bgColor={bgColor}
      borderColor={borderColor}
      {...rest}
    >
      <AvatarCardImage src={imageSrc} alt={name} />
      <AvatarCardText borderColor={borderColor}>{name}</AvatarCardText>
    </AvatarCardContainer>
  );
};

export default AvatarCard;
