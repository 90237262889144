import Toggle from '../Toggle';
import { Btn, Item, ItemName } from './SettingsItem.styles';
import { SettingsItemProps } from './SettingsItem.types';

const SettingsItem = ({
  itemName,
  Icon,
  isToggle = false,
  handleClick,
  enabled,
  ...rest
}: SettingsItemProps) => {
  return (
    <Item {...rest}>
      <ItemName>{itemName}</ItemName>
      {isToggle ? (
        <Toggle
          enabled={enabled ? enabled : false}
          handleToggleChange={handleClick}
        />
      ) : (
        <Btn aria-label={itemName} onClick={handleClick} id="install-button">
          {Icon && <Icon />}
        </Btn>
      )}
    </Item>
  );
};

export default SettingsItem;
