import { AvatarProps } from 'components/TalkingPA/TalkingPA.types';
import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = tw.div`
 w-[100%] relative z-10 max-w-[28rem]
`;

export const Content = tw.div`w-full h-full flex flex-col items-center gap-3 px-6 py-7`;

export const Avatar = styled.img<AvatarProps>(({ isTall }) => [
  tw`absolute left-1/2 -translate-x-1/2  object-contain -z-20 w-[84%] 
    animate-enterLeftBottomToCenter2 origin-bottom-left`,
  isTall && tw`bottom-[13.5rem] md:bottom-4`,
  !isTall && tw`bottom-0`,
]);
