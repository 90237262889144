import Button from 'components/Button';
import styled from 'styled-components';
import tw, { TwStyle } from 'twin.macro';
import { CircleProps, ItemProps, ObjectPanelProps } from './RewardItem.types';

const types: TwStyle = {
  statistic: tw`gap-2 h-[7.5rem] rounded-[14px]  text-opacity-50 `,
  object: tw`gap-0.5 h-36 rounded-full capitalize  `,
};
export const Item = styled.div<ItemProps>(() => [
  //base styles
  tw`flex flex-col w-28 justify-center items-center gap-4 border-2 border-darkGray  text-center
  bg-softGray font-bold text-sm tracking-tight leading-[1rem] text-darkGray cursor-pointer`,
  ({ type }) => types[type],
]);

export const ItemImage = tw.img`w-16 h-16 object-contain rounded-full outline-none`;

export const ObjectPanel = styled.div<ObjectPanelProps>`
  ${tw`relative w-[94%] flex flex-col justify-center items-center gap-3  max-w-[23.5rem]
       bg-softGray px-8 py-14  border-4 border-black rounded-xl text-center`}
  &:after {
    ${tw`[content: ""] absolute -z-10 inset-0 border-4 border-black rounded-xl text-center
    translate-x-3 translate-y-3`};
    background-color: ${({ shadowColor }) => shadowColor};
  }
`;

export const CloseButton = styled(Button)`
  ${tw`absolute -top-8 -right-6 z-10`}
`;

export const ObjectCircle = styled.div<CircleProps>`
  ${tw`w-56 h-56 rounded-full flex justify-center items-center border-2 border-darkGray`}
  background-color: ${({ circleColor }) => circleColor};
  img {
    ${tw`w-32 h-32 object-contain`}
  }
`;

export const ObjectCTA = styled(Button)`
  ${tw`absolute bottom-0 left-auto right-auto translate-y-1/2`}
`;
