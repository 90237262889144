import Button from 'components/Button';
import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledCreateAccountProps } from './CreateAccount.types';

export const Wrapper = styled.div<StyledCreateAccountProps>`
  ${tw`relative w-full max-w-sm h-[5.125rem] border-2 border-darkGray rounded-2xl
       flex items-start justify-start p-4 `}
  background-color: ${({ bgColor }) => bgColor}
`;

export const Btn = styled(Button)`
  ${tw`absolute left-1/2 -translate-x-1/2 top-14`}
`;
