import { AvatarCardContainer, AvatarImage } from './Avatar.styles';
import { AvatarProps } from './Avatar.types';
import usePersonalAssistant from 'store/usePersonalAssistant';

const Avatar = ({ avatarName, ...rest }: AvatarProps): JSX.Element => {
  const { pa } = usePersonalAssistant();
  return (
    <AvatarCardContainer {...rest}>
      <AvatarImage src={pa.avatar} alt={`${avatarName}-avatar`} />
    </AvatarCardContainer>
  );
};

export default Avatar;
