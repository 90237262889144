import Modal from 'components/Modal';
import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import XMark from 'Icons/XMark';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { theme } from 'twin.macro';
import {
  CloseButton,
  Item,
  ItemImage,
  ObjectCircle,
  ObjectCTA,
  ObjectPanel,
} from './RewardItem.styles';
import { RewardItemProps } from './RewardItem.types';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import NoImage from 'assets/images/noImage.png';

const RewardItem = ({
  type,
  name,
  image,
  statistics,
  description,
  shadowColor = '#FDC800',
  circleColor = '#D8FBEA',
  is3D = false,
  object3d,
  ...rest
}: RewardItemProps): JSX.Element => {
  const [playClick] = useSound(ClickSound);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    playClick();
    setIsOpen(false);
  };
  const openModal = () => {
    playClick();
    setIsOpen(true);
  };

  // open 3D object
  const open3D = (name: string) => {
    playClick();
    navigate(`/camera`, { state: { image: object3d } });
  };

  const getImage = (image: string) => {
    let location = image;
    let searchString = 'https://';

    if (
      !location?.includes(searchString) &&
      !location?.includes('data:image/png')
    ) {
      // replace searchString with empty string
      return searchString + location;
    } else return image;
  };
  return (
    <>
      <Item onClick={openModal} type={type} {...rest}>
        <ItemImage src={getImage(image) || NoImage} alt={name} />
        {type === 'statistic' ? (
          <p>
            {statistics} {name}
          </p>
        ) : (
          <p>{name}</p>
        )}
      </Item>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <ObjectPanel shadowColor={shadowColor}>
          <ObjectCircle circleColor={circleColor}>
            <img src={getImage(image) || NoImage} alt={name} />
          </ObjectCircle>
          <Title renderAs="h3" level={3}>
            {type === 'statistic' ? (
              <p>
                {statistics} {name}
              </p>
            ) : (
              <p>{name}</p>
            )}
          </Title>
          <Paragraph>{description}</Paragraph>
          <CloseButton
            bgColor="#F9FCFD"
            hasAfter={true}
            afterBg="#FE4A4F"
            isRounded={true}
            onClick={closeModal}
            aria-label="close-object-modal"
          >
            <XMark />
          </CloseButton>
          {is3D && (
            <ObjectCTA
              width="15.801rem"
              height="3.813rem"
              hasAfter={false}
              isRounded={false}
              rotation="-2.36deg"
              bgColor={theme`colors.success`}
              borderColor={theme`colors.darkBlue`}
              shadowColor={theme`colors.darkBlue`}
              onClick={() => open3D(name)}
            >
              {t('open3d')}
            </ObjectCTA>
          )}
        </ObjectPanel>
      </Modal>
    </>
  );
};

export default RewardItem;
