import tw from 'twin.macro';

export const Wrapper = tw.section`w-full h-full p-[18px] flex flex-col justify-start items-start gap-7
                                  border-2 border-b-0 border-darkGray rounded-t-3xl bg-[#D8F9FB] 
                              
                                  `;

export const HeaderWrapper = tw.div` w-full flex flex-col gap-2 [&>p]:(opacity-80 text-darkGray)`;

export const ItemsWrapper = tw.div`w-full flex flex-col gap-2.5 
                                  [&>button]:[&>div]:(justify-start items-start py-3 px-4 active:bg-yellow)`;
