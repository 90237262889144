import FindPlacePopup from 'components/FindPlace/FindPlacePopup';
import { findPlaceData } from './dummy';
import { FindPlaceWrapper } from './Map.styles';

const FindPlace = () => {
  const handleCollectRewards = () => {};
  return (
    <FindPlaceWrapper>
      <FindPlacePopup
        title={findPlaceData.title}
        points={findPlaceData.points}
        distance={findPlaceData.distance}
        collectRewards={handleCollectRewards}
      />
    </FindPlaceWrapper>
  );
};

export default FindPlace;
