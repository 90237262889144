import TalkingPA from 'components/TalkingPA';
import { NotifContent, NotificationWrapper } from './Map.styles';
import { useTranslation } from 'react-i18next';
import Camera from 'Icons/Camera';
import Title from 'components/Typography/Title';
import Paragraph from 'components/Typography/Paragraph';
import usePersonalAssistant from 'store/usePersonalAssistant';
import Onboarding from 'components/Onboarding';
import { Step } from 'react-joyride';
//data
const name = 'youssef';
const steps: Step[] = [
  {
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    target: '.open-3d',
    placement: 'top',
    disableScrolling: true,
    disableBeacon: true,
    disableOverlayClose: true,
  },
];
const Notifications = () => {
  const { pa } = usePersonalAssistant();
  const { t } = useTranslation();
  const openCamera = () => {};

  return (
    <>
      {/* <Onboarding steps={steps} page="notifications" /> */}
      <NotificationWrapper>
        <TalkingPA
          avatar={pa.avatar}
          isTall={false}
          label={t('collectObject')}
          icon={<Camera />}
          onClick={openCamera}
        >
          <NotifContent>
            <Title renderAs="h1" level={1}>
              {t('hey') + name}
            </Title>
            <Paragraph>A 3D object is near your. Check it out</Paragraph>
          </NotifContent>
        </TalkingPA>
      </NotificationWrapper>
    </>
  );
};

export default Notifications;
