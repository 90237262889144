import { API_MISSIONS, API_QUESTS } from 'config/api';
import { fetchit } from 'lib';
import useData from 'store/useData';
import quest from 'assets/icons/map/quest.png';
import {
  getEnigmasItems,
  getMission,
  getMissionsItems,
  getQuestTasks,
} from 'helpers/QuestsHelpers';
import {
  getDialogueConversation,
  getMissionTask,
  getSingleDialogue,
  handleLatLong,
} from 'helpers/MissionsHelper';
import { getQuizzesItems } from 'helpers/QuizzesHelpers';

export const getMissions = async (placeId: string) => {
  if (!placeId) return;

  const data = await fetchMissionsData(placeId);

  const updatedResults = await Promise.all(
    data?.results.map(async (item: any, index: number) => {
      const { latitude, longitude } = handleLatLong(item?.loc);

      const endDialogue =
        item?.endDialogue &&
        (await getDialogueConversation(
          item?.endDialogue,
          item?.dictionary?.endDialogue,
        ));
      const startDialogue =
        item?.startDialogue &&
        (await getDialogueConversation(
          item?.startDialogue,
          item?.dictionary?.startDialogue,
        ));
      const description =
        item?.description &&
        getSingleDialogue(item?.description, item?.dictionary);
      const quizzes =
        item?.quiz.length > 0 && (await getQuizzesItems(item?.quiz));
      const objects = item.object;
      const badges = item.badge;
      const order = item?.order && getMissionTask(item?.order);
      const imageSrc = item?.media[0]?.location;

      return {
        ...item,
        endDialogue,
        startDialogue,
        description,
        quizzes,
        objects,
        badges,
        order,
        latitude,
        longitude,
        type: 'mission',
        finished: false,
        imageSrc,
      };
    }),
  );

  useData.setState({ missions: updatedResults });

  return { ...data, results: updatedResults };
};

const fetchMissionsData = async (placeId: string) => {
  const populate = 'quiz,badge.media,object.media,puzzle.media,media';
  return await fetchit(
    API_MISSIONS + `/?place=${placeId}&populate=${populate}`,
    null,
    'GET',
  );
};

export const getQuests = async (placeId: string) => {
  if (!placeId) return;
  const populate = 'badges.media,media';
  const data = await fetchit(
    API_QUESTS + `/?place=${placeId}&populate=${populate}`,
    null,
    'GET',
  );

  const updatedResults = await Promise.all(
    data?.results.map(async (item: any) => {
      const starterMission =
        item?.starterMission && (await getMission(item.starterMission));
      const missions = await getMissionsItems(
        item?.missions,
        item?.starterMission,
      );
      const enigmas = await getEnigmasItems(item?.enigmas);
      // const badges =
      //   item.badges.length > 0 && (await getBadgesItems(item?.badges));
      const order = item?.order && (await getQuestTasks(item?.order));
      const imageSrc = item?.media[0]?.location;

      return {
        ...item,
        starterMission,
        missions,
        enigmas,
        order,
        icon: quest,
        type: 'quest',
        finished: false,
        imageSrc,
        hasEnigme: enigmas && enigmas.length > 0 ? true : false,
      };
    }),
  );

  return { ...data, results: updatedResults };
};
