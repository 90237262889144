import Label from 'components/Typography/Label';
import StateItem from '../StateItem';
import {
  StateContent,
  StateCountDown,
  StateCountDownContainer,
  StateCountDownItem,
  StateDivider,
  StateHeader,
} from './ActiveState.styles';
import { ActiveMissionProps } from './ActiveState.types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useMission from 'store/useMission';
import useRewards from 'store/useRewards';

function useCounter(min: number): [number, number, number] {
  const now = new Date().getTime();
  const storedTime = localStorage.getItem('counterTime');
  let counterTime: number;

  if (storedTime) {
    counterTime = Number(storedTime);
  } else {
    counterTime = now + min * 60 * 1000;
    localStorage.setItem('counterTime', counterTime.toString());
  }

  const [counter, setCounter] = useState<[number, number, number]>(() => {
    const remainingTime = counterTime - now;
    const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
    const seconds = Math.floor((remainingTime / 1000) % 60);
    return [hours, minutes, seconds];
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const remainingTime = counterTime - now;

      if (remainingTime <= 0) {
        clearInterval(interval);
        localStorage.removeItem('counterTime');
        setCounter([0, 0, 0]);
      } else {
        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
        const seconds = Math.floor((remainingTime / 1000) % 60);
        setCounter([hours, minutes, seconds]);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counterTime]);

  return counter;
}

const ActiveMission = ({
  missionCoins,
  missionName,
  finishedMission,
  missionTime = 0,
  missionCounter,
}: ActiveMissionProps): JSX.Element => {
  const { t } = useTranslation();
  const { setMission, mission, setMissionTimeLeft, selectedQuest } =
    useMission();
  const [expiredTime, setExpired] = useState(false);
  const [hours, minutes, seconds] = useCounter(mission?.missionTime);
  const [newCoins, setNewCoins] = useState<number>(0);
  const { coins, setCoins } = useRewards();

  useEffect(() => {
    var currentDate = new Date();
    if (
      (missionCounter && !mission?.missionTime) ||
      mission?.missionTime === 0
    ) {
      setMission({
        counter: currentDate.setMinutes(currentDate.getMinutes() + missionTime),
        name: missionName,
        coins: missionCoins,
        missionTime,
        _id: '',
        latitude: 0,
        longitude: 0,
        description: [],
        quizzes: [],
        distance: '',
      });
      setMissionTimeLeft(currentDate);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hours + minutes + seconds <= 0 && mission?.missionTime > 0) {
      setExpired(true);
      setNewCoins(+missionCoins / 2);
    }
    // eslint-disable-next-line
  }, [hours, minutes, seconds, mission]);

  useEffect(() => {
    if (newCoins > 0 && !selectedQuest.finished) {
      setCoins(coins + newCoins);
    }
    // eslint-disable-next-line
  }, [newCoins]);

  return (
    <>
      <StateHeader>
        <StateItem
          label={t('ongoingMission')}
          value={missionName}
          iconName="mission"
        />
        <StateItem
          label={t('finishedMission')}
          value={finishedMission}
          iconName="level"
        />
      </StateHeader>
      <StateContent>
        <StateHeader>
          {(missionTime || missionTime !== 0) && (
            <StateItem
              label={t('durationSpent')}
              value={missionTime + 'min'}
              iconName="time"
            />
          )}
          <StateItem
            label={t('pointsWon')}
            value={missionCoins || '-'}
            iconName="coins"
          />
        </StateHeader>
        {missionCounter && (
          <>
            <StateDivider />
            <StateCountDownContainer>
              <Label>{t('timeLeft')}</Label>
              <StateCountDown data-testid="count-down">
                <StateCountDownItem
                  data-testid="count-item"
                  isDanger={hours <= 0}
                >
                  {!expiredTime ? hours : 0}
                </StateCountDownItem>
                <StateCountDownItem
                  data-testid="count-item"
                  isDanger={minutes < 1}
                >
                  {!expiredTime ? minutes : 0}
                </StateCountDownItem>
                <StateCountDownItem
                  data-testid="count-item"
                  isDanger={minutes < 1}
                >
                  {!expiredTime ? seconds : 0}
                </StateCountDownItem>
              </StateCountDown>
            </StateCountDownContainer>
          </>
        )}
      </StateContent>
    </>
  );
};

export default ActiveMission;
