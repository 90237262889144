import WrapperBox from 'components/WrapperBox';
import {
  StyledFindPlaceImageWrapper,
  StyledFindPlaceImage,
} from './FindPlaceImageCard.styles';
import { FindPlaceImageProps } from './FindPlaceImageCard.types';

const FindPlaceImageCard = ({
  imageSrc,
  ...rest
}: FindPlaceImageProps): JSX.Element => {
  return (
    <WrapperBox {...rest}>
      <StyledFindPlaceImageWrapper borderRadius={rest?.borderRadius}>
        <StyledFindPlaceImage
          src={imageSrc}
          alt="findtheplace"
          data-testid="findtheplaceimg"
        />
      </StyledFindPlaceImageWrapper>
    </WrapperBox>
  );
};

export default FindPlaceImageCard;
