import { API_QUIZZES } from 'config/api';
import { fetchit } from 'lib';
import useData from 'store/useData';

// get All quizzes
export const getAllQuizzes = async (placeId: string) => {
  const data = await fetchit(API_QUIZZES + '/?place=' + placeId, null, 'GET');
  useData.setState({ quizzes: data.results });
  return data;
};
