import ChevronUp from 'Icons/ChevronUp';
import StateWrapper from '../StateWrapper';
import { StateAction, StateContainer } from './ActiveState.styles';
import { ActiveStateProps } from './ActiveState.types';
import ActiveMission from './ActiveMission';
import ActiveWithoutMission from './ActiveWithoutMission';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import useMission from 'store/useMission';
import { useNavigate } from 'react-router-dom';
import useData from 'store/useData';
import { updateQuest } from 'helpers/QuestsHelpers';
import i18n from 'i18n';

const ActiveState = ({
  missionInfo,
  missionCounter,
  finishedMission,
  ongoingMission,
  coinsEarned,
  onClose,
  ...rest
}: ActiveStateProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    missionType,
    setMission,
    setSelectedQuest,
    setMissionType,
    setObject,
    setRefresh,
    toggleMission,
    setDisableMarkerState,
    setMissionTimeLeft,
    selectedQuest,
  } = useMission();
  const { setQuest } = useData();
  const navigate = useNavigate();
  const allQuests = localStorage.getItem('allData');

  const remindeClue = () => {
    navigate('/map/find-image');
  };

  const handleQuestAfterExit = async () => {
    if (allQuests) {
      const quest = JSON.parse(allQuests)?.filter(
        (quest: { _id: string }) => quest._id === selectedQuest._id,
      );

      if (selectedQuest.finished) quest[0].finished = true;
      // update quests
      await updateQuest(quest[0]).then((res) => {
        setQuest(res);
      });
    }
  };

  const exitMission = () => {
    setMission(null);
    setSelectedQuest(null);
    setMissionType(null);
    setObject(null);
    setRefresh(false);
    toggleMission(false);
    setDisableMarkerState(false);
    setMissionTimeLeft(null);
    localStorage.setItem('counterTime', '');
    handleQuestAfterExit();
    onClose();
    navigate(0);
    // navigate('/map');
  };

  return (
    <StateWrapper width="18.938rem" {...rest}>
      <StateContainer>
        {ongoingMission ? (
          <ActiveMission
            missionName={
              missionInfo?.dictionary?.name[i18n.language] || missionInfo?.name
            }
            missionCoins={missionInfo.coins}
            {...missionInfo}
            missionCounter={missionCounter}
            finishedMission={finishedMission}
          />
        ) : (
          <ActiveWithoutMission
            finishedMission={finishedMission}
            coinsEarned={coinsEarned}
          />
        )}
        {ongoingMission && missionType === 'enigmas' && (
          <Button
            rotation="-1deg"
            shadowColor="black"
            width="100%"
            onClick={remindeClue}
          >
            {t('remindeclue')}
          </Button>
        )}

        {ongoingMission && (
          <Button
            rotation="-1deg"
            bgColor="white"
            color="black"
            shadowColor="black"
            width="100%"
            onClick={exitMission}
          >
            {t('exitmission')}
          </Button>
        )}

        <StateAction
          width="2.333rem"
          height="2.333rem"
          afterBg="#FE4A4F"
          bgColor="#EEEEEE"
          hasAfter
          isRounded
          onClick={onClose}
        >
          <ChevronUp />
        </StateAction>
      </StateContainer>
    </StateWrapper>
  );
};

export default ActiveState;
