import Button from 'components/Button';
import Input from 'components/Input';
import Title from 'components/Typography/Title';
import { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Buttons,
  Devider,
  Form,
  InputWrapper,
  Panel,
} from './CreateAccountPanel.styles';
import { CreateAccountPanelProps } from './CreateAccountPanel.types';

const CreateAccountPanel = ({
  handleSubmit,
  handleCreateAccountWithFb,
  handleCreateAccountWithGmail,
}: CreateAccountPanelProps) => {
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
  });

  const handleChnage: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const { t } = useTranslation();
  return (
    <Panel width="100%">
      <Title renderAs="h2" level={2}>
        {t('createAccount')}
      </Title>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <InputWrapper>
          <label htmlFor="name">{t('yourName')}</label>
          <Input
            edit={false}
            type="text"
            placeholder="typeName"
            id="name"
            value={userInfo.name}
            onChange={(e) => handleChnage(e)}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="email">{t('yourEmail')}</label>
          <Input
            edit={false}
            id="email"
            type="email"
            placeholder="typeEmail"
            value={userInfo.email}
            onChange={(e) => handleChnage(e)}
          />
        </InputWrapper>
        <Button
          rotation="-1.65deg"
          isRounded={false}
          hasAfter={false}
          shadowColor="#161F21"
          width="100%"
        >
          {t('createAccount')}
        </Button>
      </Form>
      <Devider>{t('or')}</Devider>
      <Buttons>
        <Button
          rotation="-1.65deg"
          isRounded={false}
          hasAfter={false}
          shadowColor="#161F21"
          bgColor="#F9FCFD"
          color="#161F21"
          width="100%"
          onClick={handleCreateAccountWithGmail}
        >
          {t('gmail')}
        </Button>
        <Button
          rotation="-1.65deg"
          isRounded={false}
          hasAfter={false}
          shadowColor="#161F21"
          bgColor="#F9FCFD"
          color="#161F21"
          width="100%"
          onClick={handleCreateAccountWithFb}
        >
          {t('facebook')}
        </Button>
      </Buttons>
    </Panel>
  );
};

export default CreateAccountPanel;
