import Title from 'components/Typography/Title';
import WrapperBox from 'components/WrapperBox';
import { locales } from 'i18n';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Content, Language, Languages } from './Switcher.styles';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';

const Switcher = (): JSX.Element => {
  const [playClick] = useSound(ClickSound);
  const { t } = useTranslation();
  const changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang);
  };
  return (
    <WrapperBox width="100%">
      <Content>
        <Title renderAs="h2" level={2}>
          {t('changeLanguage')}
        </Title>
        <Languages>
          {locales?.map((language) => (
            <Language
              key={language}
              onClick={() => {
                playClick();
                changeLanguage(language);
              }}
              isActive={language === i18next.language}
            >
              {t(language)}
            </Language>
          ))}
        </Languages>
      </Content>
    </WrapperBox>
  );
};

export default Switcher;
