import { fetchit } from 'lib';
import { API_AVATARS } from 'config/api';
import useData from 'store/useData';

export const getAvatars = async (placeId: String) => {
  if (!placeId) return;
  const data = await fetchit(API_AVATARS + '/?place=' + placeId, null, 'GET');
  useData.setState({ avatars: data.results });
  return data;
};
