import tw from 'twin.macro';
import styled from 'styled-components';

export const QcmQuizContainer = styled.form`
  ${tw`w-full py-[4rem] md:p-[4rem] md:py-[8rem] flex flex-col items-center justify-between gap-2 z-10`};
`;

export const QcmQuizItems = styled.div`
  ${tw`w-full flex flex-wrap justify-center items-center gap-3`}
`;
export const QcmQuizAction = styled.div`
  ${tw`w-full flex justify-around items-center gap-2.5 mt-10`}
`;

export const QcmQuizItemSkip = tw.div`flex items-center justify-center gap-1 cursor-pointer`;
