import LanguageSelect from 'components/LanguageSelect';
import { useNavigate } from 'react-router-dom';
import { ChooseLngWrapper } from './Start.styles';

const ChooseLanguage = () => {
  const navigate = useNavigate();

  const onChange = () => {
    navigate('choose-area');
  };

  return (
    <ChooseLngWrapper>
      <LanguageSelect onChange={onChange} />
    </ChooseLngWrapper>
  );
};

export default ChooseLanguage;
