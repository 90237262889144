import Button from 'components/Button';
import Title from 'components/Typography/Title';
import StateItem from '../StateItem';
import brokenArrow from 'assets/icons/missions/brokenArrow.png';
import {
  StateContent,
  StateHeader,
  StateImageIcon,
} from './ActiveState.styles';
import { useTranslation } from 'react-i18next';
import { ActiveWithoutMissionProps } from './ActiveState.types';
import { handleCoins } from 'helpers/MissionsHelper';

const ActiveWithoutMission = ({
  finishedMission,
  coinsEarned,
}: ActiveWithoutMissionProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <StateHeader>
        <StateItem
          label={t('finishedMission')}
          value={finishedMission}
          iconName="level"
        />
        <StateItem
          label={t('coinsEarned')}
          value={handleCoins(coinsEarned)}
          iconName="coins"
        />
      </StateHeader>
      <StateContent>
        <StateImageIcon
          src={brokenArrow}
          alt="no-mission"
          data-testid="brokenArrow"
        />
        <Title level={3} renderAs="label">
          {t('noMission')}
        </Title>
      </StateContent>
    </>
  );
};

export default ActiveWithoutMission;
