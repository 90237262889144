import { Player } from '@lottiefiles/react-lottie-player';
import { LottieProps } from './Lottie.type';
import ArrowsJson from './Arrows.json';

export default function Arrows({
  className,
  autoplay = true,
  loop = true,
  speed = 1.5,
  height = '100px',
  width = '100px',
}: LottieProps) {
  return (
    <Player
      className={className}
      autoplay={autoplay}
      speed={speed}
      loop={loop}
      src={ArrowsJson}
      style={{ height: height, width: width }}
    />
  );
}
