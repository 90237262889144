import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = tw.div`
    w-full flex flex-col justify-center items-center 
    px-4 py-8 pb-[7rem] gap-4 bg-softGray
`;

//All pages
export const AllWrapper = tw.div`
    w-full flex flex-col justify-start items-start
    bg-[#F2E8E8] px-4 py-6 pb-[7rem] gap-4 min-h-screen
`;

export const Content = tw.section`
 w-full flex flex-col justify-center items-center [&>h1]:self-start gap-2.5
`;

export const BackButton = tw.button`
 flex flex-col justify-center items-center w-10 h-10
 bg-lightBlue border-2 border-darkGray rounded-lg
`;

export const Grid = tw.div`
 w-full grid grid-cols-3 gap-4
`;

export const BadgesGrid = styled(Grid)`
  ${tw`gap-y-10 pb-6`}
`;
