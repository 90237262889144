import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DataProps {
  missions?: null | Array<any>;
  questsData?: null | Array<any>;
  quests?: null | Array<any>;
  quizzes?: null | Array<any>;
  puzzles?: null | Array<any>;
  avatars?: null | Array<any>;
  badges?: null | Array<any>;
  objects?: null | Array<any>;
  enigmas?: null | Array<any>;
  setMissions: (missions: []) => void;
  setQuestsData: (questsData: []) => void;
  setQuest: (quests: any[] | null | undefined) => void;
  setQuizzes: (quizzes: []) => void;
  setPuzzle: (puzzle: []) => void;
  setAvatars: (avatars: []) => void;
  setBadges: (badges: []) => void;
  setObjects: (objects: []) => void;
  setEnigmas: (enigmas: []) => void;
}

// add devtools

const useData = create<DataProps>()(
  persist(
    (set) => ({
      missions: null,
      questsData: null,
      quests: null,
      quizzes: null,
      puzzles: null,
      avatars: null,
      badges: null,
      objects: null,
      enigmas: null,
      setMissions: (missions: []) =>
        set((state) => {
          return {
            ...state,
            missions,
          };
        }),
      setQuest: (quests: any[] | null | undefined) =>
        set({
          quests,
        }),
      setQuestsData: (questsData: []) => set({ questsData }),
      setQuizzes: (quizzes: []) => set({ quizzes }),
      setPuzzle: (puzzles: []) => set({ puzzles }),
      setAvatars: (avatars: []) => set({ avatars }),
      setBadges: (badges: []) => set({ badges }),
      setObjects: (objects: []) => set({ objects }),
      setEnigmas: (enigmas: []) => set({ enigmas }),
    }),
    {
      name: 'data',
    },
  ),
);

export default useData;
