import styled from 'styled-components';
import tw from 'twin.macro';
import {
  StyledAvatarCardProps,
  StyledAvatarCardTextProps,
} from './AvatarCard.types';

export const AvatarCardContainer = styled.div<StyledAvatarCardProps>`
  ${tw`relative w-full h-[13rem] border-solid p-1.5 cursor-pointer
    border-[3px] border-black rounded-[1.25rem]`};
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  border-color: ${({ borderColor }) => borderColor};
`;

export const AvatarCardImage = tw.img`absolute bottom-0 left-0`;

export const AvatarCardText = styled.p<StyledAvatarCardTextProps>`
  ${tw`absolute bottom-3 bg-white  w-[90%] h-[2.938rem] 
    flex justify-center items-center text-[1.5rem] px-1
    tracking-tighter font-bold leading-8
    text-center border-2 rounded-2xl border-black `};
  box-shadow: 3px 4px 0px ${({ borderColor }) => borderColor};
  border-color: ${({ borderColor }) => borderColor};
`;
