import Button from 'components/Button';
import styled from 'styled-components';
import tw from 'twin.macro';
import { AvatarProps } from './TalkingPA.types';

export const Wrapper = tw.div`
 w-[90%] relative z-10 max-w-[28rem]
`;

export const Avatar = styled.img<AvatarProps>(({ isTall }) => [
  tw`absolute left-1/2 -translate-x-1/2  object-contain -z-20 w-[84%] 
  animate-enterLeftBottomToCenter2 origin-bottom-left`,
  isTall && tw`bottom-10 md:bottom-4`,
  !isTall && tw`bottom-0`,
]);

export const ActionButton = styled(Button)`
  ${tw`absolute left-9 -bottom-8`}
`;

export const ButtonChild = tw.span`
   flex justify-center items-center gap-3
`;
