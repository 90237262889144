import { Content, Shape, WelcomeWrapper } from './Start.styles';
import shape from 'assets/icons/nameShape.svg';
import TalkingPA from 'components/TalkingPA';
import ArrowRight from 'Icons/ArrowRight';
import { useTranslation } from 'react-i18next';
import usePersonalAssistant from 'store/usePersonalAssistant';
import { useNavigate } from 'react-router-dom';
import Title from 'components/Typography/Title';
import Paragraph from 'components/Typography/Paragraph';
import useUser from 'store/useUser';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
const Welcome = () => {
  const [play] = useSound(ClickSound);

  const { user } = useUser();
  const { pa } = usePersonalAssistant();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleStart = () => {
    // setIsLogged(true);
    play();
    navigate('/map');
  };

  return (
    <WelcomeWrapper>
      <Shape src={shape} />
      <TalkingPA
        avatar={pa.avatar}
        icon={<ArrowRight />}
        label={t('startGame')}
        onClick={handleStart}
        isTall
      >
        <Content>
          <Title renderAs="h1" level={1}>
            {t('welcome')} {user?.username}
          </Title>
          <Paragraph>{t('welcomeDescription')}</Paragraph>
        </Content>
      </TalkingPA>
    </WelcomeWrapper>
  );
};

export default Welcome;
