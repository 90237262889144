import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface userInfo {
  id?: string;
  username?: string;
  email?: string;
  avatarName?: string;
  avatarImage?: string;
}

interface userData {
  user: null | userInfo;
  isLogged: boolean;
  activeNotification: boolean;
  visitorId: string;
  setUser: (user: userInfo) => void;
  setIsLogged: (isLogged: boolean) => void;
  setActiveNotification: (activeNotification: boolean) => void;
  setVisitorId: (visitorId: string) => void;
}

const useUser = create<userData>()(
  persist(
    (set) => ({
      user: null,
      isLogged: false,
      activeNotification: true,
      visitorId: '',
      setUser: (user: userInfo) =>
        set((state) => ({
          ...state,
          user,
        })),
      setIsLogged: (isLogged: boolean) =>
        set((state) => ({
          ...state,
          isLogged,
        })),
      setActiveNotification: (activeNotification: boolean) =>
        set((state) => ({
          ...state,
          activeNotification,
        })),
      setVisitorId: (visitorId: string) =>
        set((state) => ({
          ...state,
          visitorId,
        })),
    }),
    {
      name: 'user',
    },
  ),
);

export default useUser;
