import ChamaImage from 'assets/images/avatars/chama_pa.png';
import KamalImage from 'assets/images/avatars/kamal_pa.png';
import KharbouchaImage from 'assets/images/avatars/kharboucha_pa.png';
import SimoImage from 'assets/images/avatars/simo_pa.png';

export const data = [
  {
    bgColor: '#F6B242',
    borderColor: '#000000',
    color: '#000000',
    name: 'Chama',
    imageSrc: ChamaImage,
  },
  {
    borderColor: '#000000',
    color: '#000000',
    bgColor: '#D50207',
    name: 'Kamal',
    imageSrc: KamalImage,
  },
  {
    borderColor: '#000000',
    color: '#000000',
    bgColor: '#FA8483',
    name: 'Kharboucha',
    imageSrc: KharbouchaImage,
  },
  {
    borderColor: '#000000',
    color: '#000000',
    bgColor: '#FABD85',
    name: 'Simo',
    imageSrc: SimoImage,
  },
];
