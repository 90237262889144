const ChevronLeft = ({
  width = '40',
  height = '45',
  color = '#F9FCFD',
  stroke = '#161F21',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 29V6C7.5 4.89543 8.39543 4 9.5 4H37C38.1046 4 39 4.89543 39 6V30.1716C39 30.702 38.7893 31.2107 38.4142 31.5858L26.5858 43.4142C26.2107 43.7893 25.702 44 25.1716 44H8.82843C7.04662 44 6.15429 41.8457 7.41422 40.5858L13.5858 34.4142C14.8457 33.1543 13.9534 31 12.1716 31H9.5C8.39543 31 7.5 30.1046 7.5 29Z"
        fill={color}
        stroke={stroke}
      />
      <path
        d="M3.5 26V3C3.5 1.89543 4.39543 1 5.5 1H33C34.1046 1 35 1.89543 35 3V27.1716C35 27.702 34.7893 28.2107 34.4142 28.5858L22.5858 40.4142C22.2107 40.7893 21.702 41 21.1716 41H4.82843C3.04662 41 2.15429 38.8457 3.41422 37.5858L9.58579 31.4142C10.8457 30.1543 9.95338 28 8.17157 28H5.5C4.39543 28 3.5 27.1046 3.5 26Z"
        fill={color}
        stroke={stroke}
      />
    </svg>
  );
};

export default ChevronLeft;
