const Corn = ({
  width = '83',
  height = '31',
  color = 'white',
  stroke = 'black',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 83 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5 7L29.4048 22.2054C25.9898 26.4985 20.8036 29 15.318 29H0V31H83V29H68.0298C62.631 29 57.5173 26.5767 54.0986 22.3983L41.5 7Z"
        fill={color}
      />
      <path
        d="M57.1051 18.5775L41.5 0L26.401 18.4134C22.9821 22.5828 17.8741 25 12.4822 25H0V29H15.318C20.8036 29 25.9898 26.4985 29.4048 22.2054L41.5 7L54.0986 22.3983C57.5173 26.5767 62.631 29 68.0298 29H83V25H70.8877C65.5704 25 60.5251 22.649 57.1051 18.5775Z"
        fill={stroke}
      />
    </svg>
  );
};

export default Corn;
