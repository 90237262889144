const defaultHeaders = {
  'Content-Type': 'application/json',
};

const multipartHeaders = {
  'Content-Type': 'multipart/form-data',
};

/**
 * fetchit
 *
 * @param {String} url
 * @param {*} data json or file
 * @param {Function} method ['POST', 'GET', 'PATCH', 'DELETE'] @default 'GET'
 * @param {String} accessToken
 * @param {Boolean} multipart @default false
 * @returns {Object} response.json()
 */
async function fetchit(
  url: string,
  data = {} || null,
  method: string,
  accessToken?: string,
  multipart = false,
) {
  let options = {};
  Object.assign(options, {
    method: method || 'GET',
    headers: {
      ...defaultHeaders,
    },
  });
  if (accessToken) {
    Object.assign(options, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  if (data && multipart) {
    Object.assign(options, {
      headers: {
        ...multipartHeaders,
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    });
  }

  if (data && !multipart) {
    Object.assign(options, {
      body: JSON.stringify(data),
    });
  }

  const response = await fetch(url, { ...options });

  if (response.status > 299) {
    throw new Error(response.statusText);
  }

  return response.json();
}

export default fetchit;
