const ChevronUp = ({ width = '22', height = '15', color = '#FE4A4F' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.64112 9.35387C0.76961 10.2254 0.769609 11.6384 1.64112 12.5099C2.51263 13.3814 3.92564 13.3814 4.79715 12.5099L11.0495 6.25759L17.1305 12.3386C17.9697 13.1778 19.3303 13.1778 20.1696 12.3386C21.0088 11.4994 21.0088 10.1387 20.1696 9.29953L12.3895 1.51947C11.5503 0.680249 10.1896 0.680249 9.35042 1.51947C9.20001 1.66987 9.07656 1.83703 8.98007 2.01492L1.64112 9.35387Z"
        fill={color}
      />
      <path
        d="M1.64112 9.35387L1.04006 8.75281L1.64112 9.35387ZM4.79715 12.5099L4.19608 11.9088L4.79715 12.5099ZM11.0495 6.25759L11.6505 5.65652L11.0495 5.05546L10.4484 5.65652L11.0495 6.25759ZM20.1696 9.29953L19.5685 9.90059L20.1696 9.29953ZM12.3895 1.51947L12.9906 0.918405L12.3895 1.51947ZM8.98007 2.01492L9.58113 2.61599L9.66841 2.52871L9.72727 2.42021L8.98007 2.01492ZM2.24218 11.9088C1.70263 11.3693 1.70263 10.4945 2.24219 9.95494L1.04006 8.75281C-0.163412 9.95628 -0.163413 11.9075 1.04006 13.111L2.24218 11.9088ZM4.19608 11.9088C3.65653 12.4484 2.78174 12.4484 2.24218 11.9088L1.04006 13.111C2.24353 14.3144 4.19474 14.3144 5.39821 13.111L4.19608 11.9088ZM10.4484 5.65652L4.19608 11.9088L5.39821 13.111L11.6505 6.85865L10.4484 5.65652ZM17.7315 11.7375L11.6505 5.65652L10.4484 6.85865L16.5294 12.9397L17.7315 11.7375ZM19.5685 11.7375C19.0612 12.2448 18.2388 12.2448 17.7315 11.7375L16.5294 12.9397C17.7006 14.1109 19.5995 14.1109 20.7706 12.9397L19.5685 11.7375ZM19.5685 9.90059C20.0758 10.4079 20.0758 11.2303 19.5685 11.7375L20.7706 12.9397C21.9418 11.7685 21.9418 9.86964 20.7706 8.69847L19.5685 9.90059ZM11.7884 2.12053L19.5685 9.90059L20.7706 8.69847L12.9906 0.918405L11.7884 2.12053ZM9.95148 2.12053C10.4587 1.61327 11.2812 1.61327 11.7884 2.12053L12.9906 0.918405C11.8194 -0.252773 9.92053 -0.252773 8.74936 0.918405L9.95148 2.12053ZM9.72727 2.42021C9.78514 2.3135 9.85957 2.21244 9.95148 2.12053L8.74936 0.918405C8.54046 1.1273 8.36799 1.36055 8.23288 1.60964L9.72727 2.42021ZM2.24219 9.95494L9.58113 2.61599L8.37901 1.41386L1.04006 8.75281L2.24219 9.95494Z"
        fill="#161F21"
      />
    </svg>
  );
};

export default ChevronUp;
