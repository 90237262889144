import tw from 'twin.macro';

export const Wrapper = tw.section`w-full h-screen overflow-scroll flex flex-col items-start justify-between gap-[15rem] bg-pageBg`;

export const HeaderPage = tw.header`w-full flex flex-col items-start justify-start gap-4 p-8 pr-0`;

export const QuizzContent = tw.section`w-full flex flex-col items-center justify-center gap-0 z-10`;

//  Result page Styles

export const ResultPage = tw.section`w-full h-full flex flex-col items-center justify-center bg-yellow relative overflow-hidden`;

// hedaer Image
export const ResultHeaderImg = tw.img`w-[18rem] h-[20rem] md:w-[20rem] md:h-[24rem]`;

// Result content
export const ResultContent = tw.section`w-full h-full flex flex-col items-center justify-center gap-0`;

// Shapes ClassNames

export const Shape1ClassName =
  'absolute -right-[10rem] top-[23%] md:right-0 md:top-[23rem] z-10';

export const Shape2ClassName =
  'absolute -left-[2rem] top-[14rem] md:left-[7rem] md:top-[24rem] z-10';

export const Shape3ClassName = 'absolute -left-[2rem] top-[3rem] z-10';

export const Shape4ClassName = 'absolute -right-[1rem] top-[3rem] z-10';
