import Label from 'components/Typography/Label';
import Title from 'components/Typography/Title';
import WrapperBox from 'components/WrapperBox';
import { theme } from 'twin.macro';
import {
  PopupContainer,
  PopupContent,
  PopupContentItem,
  PopupImage,
  RetryButton,
} from './WinningPopup.styles';
import coinsImage from 'assets/icons/coins/coins.png';
import { useTranslation } from 'react-i18next';
import { WinningProps } from './WinningPopup.types';

const WinningPopup = ({
  isWon = true,
  object,
  coins,
  objectImage,
  objectName,
  collectRewards,
  wheel,
  ...rest
}: WinningProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <WrapperBox
      width="100%"
      height="18.25rem"
      borderColor={theme`colors.darkBlue`}
      {...rest}
    >
      <PopupContainer wheel={wheel}>
        <PopupContent>
          {isWon && (
            <Title level={1} renderAs="h2">
              {t('congratulation')}
            </Title>
          )}
          <PopupContentItem>
            {isWon && <Label>{t('youWon')}</Label>}
            <PopupImage
              wheel={wheel}
              src={object ? objectImage : coinsImage}
              alt={object ? objectName : coins + ''}
            />
          </PopupContentItem>
          <Title level={1} renderAs="h1">
            {object ? objectName : coins + ` ${t('coins')}`}
          </Title>
        </PopupContent>
      </PopupContainer>
      <RetryButton
        width="15.801rem"
        height="3.813rem"
        hasAfter={false}
        isRounded={false}
        rotation="-2.36deg"
        bgColor={theme`colors.success`}
        borderColor={theme`colors.darkBlue`}
        shadowColor={theme`colors.darkBlue`}
        onClick={collectRewards}
      >
        {t('collectprize')}
      </RetryButton>
    </WrapperBox>
  );
};

export default WinningPopup;
