import { Route, Routes, useNavigate } from 'react-router-dom';
import FindImage from './FindImage';
import FindPlace from './FindPlace';
import FindQuote from './FIndQuote';
import Map from './Map';
import Missions from './Missions';
import PuzzlePage from './Puzzle';
import Notifications from './Notifications';
import WheelPage from './WheelPage';
import useUser from 'store/useUser';
import { useEffect } from 'react';

const MapRoutes = () => {
  const { isLogged } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      navigate('/start');
    }
    // eslint-disable-next-line
  }, [isLogged]);
  return (
    <Routes>
      <Route path="/" element={<Map />}>
        <Route index element={<Missions />} />
        {/* <Route path="guide" element={<Guide />} />
        <Route path="guides" element={<Guides />} />
        <Route path="guide-cta" element={<GuidWithCTA />} /> */}
        <Route path="find-place" element={<FindPlace />} />
        <Route path="find-image" element={<FindImage />} />
        <Route path="find-quote" element={<FindQuote />} />
        <Route path="puzzle" element={<PuzzlePage />} />
        <Route path="wheel" element={<WheelPage />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>
    </Routes>
  );
};

export default MapRoutes;
