import React, { FormEvent } from 'react';
import CreateAccount from 'components/CreateAccount';

// NoAccount component for displaying options to create an account
const NoAccount: React.FC = () => {
  // Function to create an account with Facebook
  const createAccountWithFb = () => {
    // To do: implement the logic for creating an account with Facebook
  };

  // Function to create an account with Gmail
  const createAccountWithGmail = () => {
    // To do: implement the logic for creating an account with Gmail
  };

  // handleSubmit function to handle form submission
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add any additional logic for form submission if needed
  };

  // Render the CreateAccount component and pass the necessary functions as props
  return (
    <CreateAccount
      handleCreateAccountWithFb={createAccountWithFb}
      handleCreateAccountWithGmail={createAccountWithGmail}
      handleSubmit={(e) => handleSubmit(e)}
    />
  );
};

export default NoAccount;
