import HowToPlayCard from 'components/HowToPlayCard';
import InviteCard from 'components/InviteCard';
import SettingsCard from 'components/SettingsCard';
import { useEffect, useState } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import useUser from 'store/useUser';
import { inviteDescription, inviteLink, playDescription } from './data';
import { Wrapper } from './Profile.styles';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}
const Profile = () => {
  const [playClick] = useSound(ClickSound);
  const { isLogged, activeNotification, setActiveNotification } = useUser();
  const navigate = useNavigate();

  // check if the user is logged or not
  useEffect(() => {
    if (!isLogged) {
      navigate('/start');
    }
    // eslint-disable-next-line
  }, []);
  //Function for how to play and the settings
  const handlePLay = () => {
    //This is just an example to check if the redirecting is working. IT SHOULD NOT BE HERE
    // setIsLogged(true);
    playClick();
  };
  // PWA add to home function
  // const handleDownload = () => {};
  const handleEmergncy = () => {
    playClick();
  };
  const handleNotifications = () => {
    playClick();

    setActiveNotification(!activeNotification);
  };

  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the default install prompt from showing
      e.preventDefault();
      // Store the event for later use
      setDeferredPrompt(e as BeforeInstallPromptEvent);
    });
  }, []);

  /// handle PWA download (add to home)
  const handleInstallClick = () => {
    playClick();
    // check if beforeinstallprompt ! in window
    if (!('beforeinstallprompt' in window)) {
      alert(
        'To install this app, please follow the instructions in your browser menu.',
      );
    }

    // Check if the deferredPrompt is available
    if (deferredPrompt) {
      // Show the installation prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the installation prompt');
        } else {
          console.log('User dismissed the installation prompt');
        }
        // Reset the deferredPrompt variable
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <Wrapper isLogged={isLogged}>
      <Outlet />
      <InviteCard link={inviteLink} description={inviteDescription} />
      <HowToPlayCard handlePlay={handlePLay} description={playDescription} />
      <SettingsCard
        handleToggleNotifications={handleNotifications}
        handleEmrgencyCall={handleEmergncy}
        handleDownloadApp={handleInstallClick}
      />
    </Wrapper>
  );
};

export default Profile;
