import SpeechBox from 'components/SpeechBox';
import { ActionButton, Avatar, ButtonChild, Wrapper } from './TalkingPA.styles';
import { TalkingPaProps } from './TalkingPA.types';

const TalkingPA = ({
  children,
  avatar,
  isTall,
  label,
  icon,
  onClick,
  shadowColor = '#FDC800',
  ...rest
}: TalkingPaProps) => {
  return (
    <Wrapper {...rest}>
      <SpeechBox cornPosition="top" hasLightBorder={false} width="100%">
        {children}
      </SpeechBox>
      <Avatar src={avatar} alt="pa avatar" isTall={isTall} />
      <ActionButton
        className="open-3d"
        width="80%"
        hasAfter={false}
        rotation="-2.36deg"
        shadowColor="black"
        onClick={onClick}
      >
        <ButtonChild>
          {label}
          {icon && icon}
        </ButtonChild>
      </ActionButton>
    </Wrapper>
  );
};

export default TalkingPA;
