import Label from 'components/Typography/Label';
import {
  StateItemContent,
  StateItemInfo,
  StateItemInfoImage,
} from './StateItem.styles';
import MissionsIcon from 'assets/icons/missions/mission.png';
import Coins from 'assets/icons/coins/coins.png';
import Level from 'assets/icons/missions/Level.png';
import Time from 'assets/icons/time.png';
import { StateItemProps } from './StateItem.types';
import Paragraph from 'components/Typography/Paragraph';

const getImageIcon = (icon: string) => {
  switch (icon) {
    case 'mission':
      return MissionsIcon;
    case 'coins':
      return Coins;
    case 'level':
      return Level;
    case 'time':
      return Time;
    default:
      return MissionsIcon;
  }
};

const StateItem = ({ label, value, iconName }: StateItemProps): JSX.Element => {
  return (
    <StateItemContent data-testid="state-item">
      <Label>{label}</Label>
      <StateItemInfo>
        <StateItemInfoImage src={getImageIcon(iconName)} alt={iconName} />
        <Paragraph>{value}</Paragraph>
      </StateItemInfo>
    </StateItemContent>
  );
};

export default StateItem;
