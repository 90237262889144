import { ElementType } from 'react';
import { StyledTitle } from './Title.styles';
import { TitleProps } from './Title.types';

const Title = <T extends ElementType = 'h1'>({
  children,
  renderAs,
  level,
  color,
  ...rest
}: TitleProps<T>): JSX.Element => {
  return (
    <StyledTitle as={renderAs} color={color} level={level} {...rest}>
      {children}
    </StyledTitle>
  );
};

export default Title;
