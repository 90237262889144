import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledInputProps } from './Input.types';

export const InputContainer = tw.div`relative `;

export const StyledInput = styled.input<StyledInputProps>`
  ${tw`w-full h-[3.75rem] border border-solid  border-black rounded-lg  
  outline-none font-primary font-normal text-xl leading-[1.625rem] p-4 cursor-text`};
  ${({ width }) => width && `width: ${width}`};
  background-color: ${({ bgColor }) => bgColor || '#F9FCFD'};
  color: ${({ color }) => color};
  border-color: ${({ borderColor }) => borderColor};
`;

export const InputIcon = tw.div`absolute right-4 top-5`;
