import Title from 'components/Typography/Title';
import { useRef } from 'react';
import {
  QcmQuizItemCheckBox,
  QcmQuizItemContainer,
} from './QcmQuizItem.styles';
import { QcmQuizItemProps } from './QcmQuizItem.type';

const QcmQuizItem = ({
  checked = false,
  correct = true,
  title,
  onChange,
}: QcmQuizItemProps): JSX.Element => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    checkboxRef?.current?.click();
  };

  return (
    <QcmQuizItemContainer
      checked={checked}
      correct={correct}
      onClick={handleClick}
      data-testid="QcmQuizItem"
    >
      <QcmQuizItemCheckBox
        ref={checkboxRef}
        id={title}
        name={title}
        checked={checked}
        correct={correct}
        type="checkbox"
        onChange={onChange}
        onClick={handleClick}
      />
      <Title level={3} renderAs="label">
        {title}
      </Title>
    </QcmQuizItemContainer>
  );
};

export default QcmQuizItem;
