const EditIcon = ({ width = '17', height = '19', stroke = '#161F21' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.61225 13.0001L12.4865 4.12582L11.2493 2.88857L2.375 11.7628V13.0001H3.61225ZM4.33763 14.7501H0.625V11.0374L10.6306 1.03182C10.7947 0.867786 11.0172 0.775635 11.2493 0.775635C11.4813 0.775635 11.7038 0.867786 11.8679 1.03182L14.3432 3.5072C14.5073 3.67128 14.5994 3.8938 14.5994 4.12582C14.5994 4.35784 14.5073 4.58036 14.3432 4.74445L4.33763 14.7501ZM0.625 16.5001H16.375V18.2501H0.625V16.5001Z"
        fill={stroke}
      />
    </svg>
  );
};

export default EditIcon;
