import Modal from 'components/Modal';
import {
  CloseButton,
  ObjectCircle,
  ObjectPanel,
} from 'components/RewardItem/RewardItem.styles';
import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import TraingleDown from 'Icons/TriangleDown';
import XMark from 'Icons/XMark';
import { useState } from 'react';
import { BadgeImage, Item, Trainagle, Wrapper } from './BadgeItem.styles';
import { BadgeItemProps } from './BadgeItem.types';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';

const BadgeItem = ({
  badgeImage,
  badgeName,
  badgeDescription,
  ...rest
}: BadgeItemProps): JSX.Element => {
  const [playClick] = useSound(ClickSound);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    playClick();
    setIsOpen(false);
  };
  const openModal = () => {
    playClick();
    setIsOpen(true);
  };
  return (
    <Wrapper>
      <Item onClick={openModal} {...rest}>
        <BadgeImage src={badgeImage} alt="badge" />
        <Trainagle>
          <TraingleDown />
        </Trainagle>
      </Item>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <ObjectPanel shadowColor={'#FDC800'}>
          <ObjectCircle circleColor={'#D8FBEA'}>
            <img src={badgeImage} alt={badgeName} />
          </ObjectCircle>
          <Title renderAs="h2" level={2}>
            {badgeName}
          </Title>
          <Paragraph>{badgeDescription}</Paragraph>
          <CloseButton
            bgColor="#F9FCFD"
            hasAfter={true}
            afterBg="#FE4A4F"
            isRounded={true}
            onClick={closeModal}
            aria-label="close-object-modal"
          >
            <XMark />
          </CloseButton>
        </ObjectPanel>
      </Modal>
    </Wrapper>
  );
};

export default BadgeItem;
