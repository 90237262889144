import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';

import AvatarCard from 'components/AvatarCard';
import { Empty, Loading } from 'components/LottieFiles';
import Title from 'components/Typography/Title';
import { getMobileOS } from 'helpers/GlobalHelper';
import { requestNotificationPermission } from 'serviceWorkerRegistration';
import useData from 'store/useData';
import useUser from 'store/useUser';

import { ChoosePaWrapper, Grid } from './Start.styles';
import { data } from './data';
import ClickSound from 'assets/sounds/Tap.wav';
import usePersonalAssistant from 'store/usePersonalAssistant';

const ChoosePa = () => {
  const { t } = useTranslation();
  const [play] = useSound(ClickSound);
  const { setUser, isLogged } = useUser();
  const { setPa } = usePersonalAssistant();
  const navigate = useNavigate();
  const { avatars } = useData();

  const handleChoosePA = async (card: any) => {
    if (getMobileOS() !== 'iOS') {
      requestNotificationPermission();
    }

    setUser({
      avatarImage: card?.media[0]?.location,
      avatarName: card.name,
    });

    setPa({
      avatar: card?.media[0]?.location,
      name: card.name,
    });

    navigate('/start/choose-name');
  };

  useEffect(() => {
    if (isLogged) {
      navigate('/map');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const renderAvatars = () => {
    if (avatars && avatars.length > 0) {
      return avatars.slice(0, 2).map((card, index) => (
        <AvatarCard
          key={card.name}
          name={card.name}
          imageSrc={card?.media[0]?.location}
          bgColor={data[index]?.bgColor || ''}
          onClick={() => {
            play();
            handleChoosePA(card);
          }}
        />
      ));
    } else if (avatars?.length === 0) {
      return <Empty />;
    } else {
      return <Loading />;
    }
  };

  return (
    <ChoosePaWrapper>
      <Title renderAs="h1" level={1}>
        {t('choosePa')}
      </Title>
      <Grid>{renderAvatars()}</Grid>
    </ChoosePaWrapper>
  );
};

export default ChoosePa;
