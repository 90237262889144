import { Player } from '@lottiefiles/react-lottie-player';
import { LottieProps } from './Lottie.type';

export default function LoadingButton({
  className,
  autoplay = true,
  loop = true,
  speed = 1.5,
}: LottieProps) {
  return (
    <Player
      className={className}
      autoplay={autoplay}
      speed={speed}
      loop={loop}
      src="https://assets10.lottiefiles.com/packages/lf20_Uld7cMjJ7V.json"
      style={{ height: '100px', width: '100px' }}
    />
  );
}
