import { fetchit } from 'lib';
import { API_PUZZLES } from 'config/api';
import useData from 'store/useData';
import { getDialogueConversation, handleLatLong } from 'helpers/MissionsHelper';

// get puzzles
export const getPuzzles = async (placeId: String) => {
  if (!placeId) return;
  const data = await fetchit(API_PUZZLES + '/?place=' + placeId, null, 'GET');
  await data?.results.map(async (item: any, index: number) => {
    const { latitude, longitude } = handleLatLong(item?.loc);

    Object.assign(item, {
      dialogue:
        item?.dialogue &&
        (await getDialogueConversation(
          item?.dialogue,
          item?.dictionary?.dialogue,
        )),

      latitude,
      longitude,
    });
  });
  useData.setState({ puzzles: data.results });
  return data;
};
