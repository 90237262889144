const ChevronLeft = ({
  width = '20',
  height = '31',
  color = 'white',
  stroke = 'black',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5766 14.1878L2.69419 6.65117C1.46933 5.48005 1.46882 3.52383 2.69305 2.35206C3.84303 1.25136 5.65573 1.25104 6.8061 2.35134L17.2339 12.3252C19.0428 14.0553 19.0428 16.9447 17.2339 18.6748L6.8061 28.6487C5.65573 29.749 3.84303 29.7486 2.69305 28.6479C1.46882 27.4762 1.46933 25.5199 2.69419 24.3488L10.5766 16.8122C11.3244 16.0972 11.3244 14.9028 10.5766 14.1878Z"
        fill={color}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
};

export default ChevronLeft;
