import styled from 'styled-components';
import tw from 'twin.macro';
export const NavBarContainer = styled.nav<{ isHidden: boolean }>`
  ${tw`flex  items-center justify-center w-full max-w-screen-md h-[6rem] z-50 
       fixed bottom-0 left-1/2 -translate-x-1/2 bg-softGray border-t-2 border-solid`};
  display: ${({ isHidden }) => isHidden && 'none'};
  border-top-left-radius: 900px 50px;
  border-top-right-radius: 900px 50px;
`;

export const LinksList = tw.div`w-full flex justify-around items-center py-4 px-6`;
export const NavBarLink = styled.div<{ isActive: boolean; disabled: boolean }>`
  ${tw`w-fit cursor-pointer flex flex-col  justify-center items-center gap-1 [&>span]:text-center`};
  ${({ isActive }) =>
    isActive &&
    tw`[&>span]:(text-[#FE4A4F] opacity-100  transition-all ease-in duration-75) [&>img]:scale-125`};
  ${({ disabled }) => disabled && tw`opacity-80 cursor-none`};
`;

export const NavBarLogo = tw.img`w-8 h-8 object-fill`;
export const NavBarIcon = tw.button`w-8 h-8 relative mt-2.5`;
