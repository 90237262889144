const ArrowsRight = ({ width = 15, height = 13, color = '#161F21' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.24289 7.07111C4.63342 6.68058 4.63342 6.04742 4.24289 5.65689L0.707001 2.121C0.316535 1.73054 0.316534 1.09747 0.707 0.707C1.09747 0.316535 1.73053 0.316535 2.121 0.707L7.07089 5.65689C7.46142 6.04742 7.46142 6.68058 7.07089 7.07111L2.121 12.021C1.73053 12.4115 1.09747 12.4115 0.707 12.021C0.316534 11.6305 0.316535 10.9975 0.707 10.607L4.24289 7.07111Z"
        fill={color}
      />
      <path
        d="M11.021 7.07111C11.4115 6.68058 11.4115 6.04742 11.021 5.65689L7.48508 2.121C7.09461 1.73054 7.09461 1.09747 7.48508 0.707C7.87554 0.316535 8.50861 0.316535 8.89908 0.707L13.849 5.65689C14.2395 6.04742 14.2395 6.68058 13.849 7.07111L8.89908 12.021C8.50861 12.4115 7.87554 12.4115 7.48508 12.021C7.09461 11.6305 7.09461 10.9975 7.48508 10.607L11.021 7.07111Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowsRight;
