import { fetchit } from 'lib';
import { API_AREAS } from 'config/api';
import useArea from 'store/useArea';

export const getAreas = async (placeId: String) => {
  if (!placeId) return;
  const data = await fetchit(API_AREAS + '/?place=' + placeId, null, 'GET');
  useArea.setState({ areasList: data.results });
  return data;
};
