import { Switch } from '@headlessui/react';
import { toggleProps } from '../SettingsItem/SettingsItem.types';

const Toggle = ({ enabled, handleToggleChange }: toggleProps) => {
  return (
    <Switch checked={enabled} onChange={handleToggleChange}>
      <div
        className={`${enabled ? 'bg-success' : 'bg-[#A8A8A8]'}
          relative inline-flex h-[23px] w-[43px] shrink-0 cursor-pointer rounded-full border-2 border-darkGray shadow-button  
          transition-colors duration-200 ease-in-out `}
      >
        <span className="sr-only">activate notifications</span>
        <span
          aria-hidden="true"
          className={`${
            enabled
              ? 'translate-x-[21.5px] bg-softGray'
              : 'translate-x-0.5 bg-[#D0D0D0]'
          }
            pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full  ring-0 
            border-2 border-darkGray transition duration-200 ease-in-out translate-y-0.5`}
        />
      </div>
    </Switch>
  );
};

export default Toggle;
