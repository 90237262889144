import Onboarding from 'components/Onboarding';
import ActiveState from 'components/States/ActiveState';
import State from 'components/States/State';
import { calcuateMissions as calculateMissions } from 'helpers/MissionsHelper';
import { useState } from 'react';
import { Step } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import useData from 'store/useData';
import useMission from 'store/useMission';
import useRewards from 'store/useRewards';
import { MissionAvatar, MissionWrapper, QuestButton } from './Map.styles';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import {
  QuestsSwiperButton,
  QuestsSwiperModal,
} from 'components/QuestsSwiperModal';
// import { t } from 'i18next';
import { useMap } from 'react-map-gl';
import { FocusTrap } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

const Missions = () => {
  const { t } = useTranslation();
  const { current: map } = useMap();
  const [playClick] = useSound(ClickSound);
  const { onGoing, mission } = useMission();
  const { quests } = useData();
  const { coins } = useRewards();
  const navigate = useNavigate();
  const steps: Step[] = [
    {
      content: t('missionBoarding_1'),
      target: '.medina',
      placement: 'bottom',
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
    },
    {
      content: t('missionBoarding_2'),
      target: '.open-dropdown-button',
      placement: 'bottom',
      disableScrolling: true,
      disableBeacon: true,
      disableOverlayClose: true,
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [SwiperModal, setSwiperModal] = useState<boolean>(false);
  map?.triggerRepaint();
  const toggle = () => {
    playClick();
    setIsOpen((prevState) => !prevState);
  };

  const onOpenSwiperModal = () => {
    playClick();
    setSwiperModal(true);
  };

  const onCloseSwiperModal = () => {
    playClick();
    setSwiperModal(false);
  };

  return (
    <FocusTrap>
      {/* <Onboarding steps={steps} page="missions" /> */}
      <MissionWrapper>
        {isOpen ? (
          <ActiveState
            className="absolute right-6 top-10"
            ongoingMission={onGoing}
            finishedMission={calculateMissions(quests)}
            coinsEarned={coins}
            onClose={toggle}
            missionCounter={mission?.missionTime}
            missionInfo={{ ...mission, missionState: 'active' }}
          />
        ) : (
          <State
            className="absolute right-6 top-10"
            ongoingMission={onGoing}
            missionName={mission?.name.slice(0, 5) + '...'}
            finishedMissions={calculateMissions(quests)}
            coinsEarned={coins}
            onClick={toggle}
          />
        )}
        {/* ƒ
        {!onGoing && (
          <QuestButton className="questButton">
            <QuestsSwiperButton
              onClick={onOpenSwiperModal}
              className="absolute bottom-[14%] right-4"
            />
          </QuestButton>
        )} */}
        {/* <QuestsSwiperModal
          autoFocus
          isOpen={SwiperModal}
          onClose={onCloseSwiperModal}
        /> */}
      </MissionWrapper>
    </FocusTrap>
  );
};

export default Missions;
