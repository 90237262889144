import coinImage from 'assets/icons/coins/coin.png';
import badge from 'assets/icons/badges/badge.png';

const statisticsItems = [
  {
    name: 'badges',
    statistics: 0,
    image: badge,
    description: 'badgesDescription',
  },
  {
    name: 'coins',
    statistics: 0,
    image: coinImage,
    description: 'coinsDescription',
  },
  {
    name: 'objects',
    statistics: 0,
    image: badge,
    description: 'objectsDescription',
  },
];

// Calculate Total badges and objects and coins
export const handleStatisticsItems = (
  badges: number,
  objects: number,
  coins: number,
) => {
  const statisticsItemsCopy = [...statisticsItems];
  statisticsItemsCopy[0].statistics = badges;
  statisticsItemsCopy[1].statistics = coins;
  statisticsItemsCopy[2].statistics = objects;
  return statisticsItemsCopy;
};
