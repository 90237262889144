import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoggedIn from './LoggedIn';
import NoAccount from './NoAccount';
import Profile from './Profile';

// ProfileRoutes component for managing routes related to user profiles
const ProfileRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Main profile route */}
      <Route path="/" element={<Profile />}>
        {/* Sub-routes for different profile states */}
        <Route path="no-account" element={<NoAccount />} />{' '}
        {/* Route for users with no account */}
        <Route path="account" element={<LoggedIn />} />{' '}
        {/* Route for logged-in users */}
      </Route>
    </Routes>
  );
};

export default ProfileRoutes;
