import { Route, Routes } from 'react-router-dom';
import AllBadges from './AllBadges';
import AllObjects from './AllObjects';
import Rewards from './Rewards';

const RewardsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Rewards />} />
      <Route path="all-objects" element={<AllObjects />} />
      <Route path="all-badges" element={<AllBadges />} />
    </Routes>
  );
};

export default RewardsRoutes;
