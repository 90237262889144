import ContentWrapper from 'components/ContentWrapper';
import Title from 'components/Typography/Title';
import PuzzleCard from '../PuzzleCard';
import {
  HelpImage,
  PuzzleContent,
  PuzzleHeader,
  PuzzleHelpModal,
  PuzzleHelpModalCloseButton,
  PuzzleInfo,
} from './Puzzle.styles';
import { PuzzleProps } from './Puzzle.types';
import infoIcon from 'assets/icons/info.png';
import { useState } from 'react';
import Modal from 'components/Modal';
import XMark from 'Icons/XMark';
import { useTranslation } from 'react-i18next';

const Puzzle = ({
  image,
  onSolve,
  avatar,
  ...rest
}: PuzzleProps): JSX.Element => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const onHelp = () => {
    setShowModal(!showModal);
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <ContentWrapper hasSpeechBox={false} avatar={avatar} {...rest}>
      <PuzzleContent>
        <PuzzleHeader>
          <Title level={3} renderAs="label">
            {t('finishpuzzle')}
          </Title>
          <PuzzleInfo src={infoIcon} alt="help" onClick={onHelp} />
        </PuzzleHeader>
        <PuzzleCard image={image} onSolve={onSolve} />
      </PuzzleContent>
      <Modal isOpen={showModal} closeModal={onClose}>
        <PuzzleHelpModal>
          <HelpImage src={image} alt="help Image" />
          <PuzzleHelpModalCloseButton onClick={onClose}>
            <XMark />
          </PuzzleHelpModalCloseButton>
        </PuzzleHelpModal>
      </Modal>
    </ContentWrapper>
  );
};

export default Puzzle;
