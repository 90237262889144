import styled from 'styled-components';
import tw from 'twin.macro';
import { WrapperProps, StyledBtnProps } from './Button.types';

export const StyledBtn = styled.button<StyledBtnProps>`
  width: ${({ width }) => width};
  rotate: ${({ rotation }) => rotation};
`;

export const Wrapper = styled.div<WrapperProps>`
  ${tw`relative w-full  py-[clamp(12px, 2.5vh, 18px)] px-5  flex justify-center items-center bg-blue 
  border-2 border-black rounded-xl text-sm text-white capitalize font-bold outline-none`};
  ${({ hasAfter }) => (hasAfter ? tw`p-2.5 px-4 ` : tw`shadow-button`)};
  ${({ isRounded }) => isRounded && tw`rounded-full p-0 w-12 h-12`}
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  border-color: ${({ borderColor }) => borderColor};
  box-shadow: ${({ shadowColor }) => `3px 4px 0px ${shadowColor}`};

  &:after {
    ${({ hasAfter }) =>
      hasAfter &&
      tw`[content: ""] absolute -z-10 inset-0 bg-blue border-2 border-black rounded-xl 
    translate-x-1.5 translate-y-1.5`};
    border-color: ${({ borderColor }) => borderColor};
    background-color: ${({ afterBg, bgColor }) =>
      afterBg ? afterBg : bgColor};
    ${({ isRounded }) =>
      isRounded && tw`rounded-full translate-x-1 translate-y-1`}
  }
`;
