import { SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { PlayOptions } from 'use-sound/dist/types';
import { finishMission, finishQuest, handleNextMission } from './QuestsHelpers';
import useRewards from 'store/useRewards';

interface onStartTypes {
  play: { (options?: PlayOptions | undefined): void; (): void };
  playApplause: { (options?: PlayOptions | undefined): void; (): void };
  selectedQuest: any;
  mission: any;
  setGuideData: {
    (value: SetStateAction<string[]>): void;
  };
  setGuideModal: {
    (value: SetStateAction<boolean>): void;
  };
  onClose: { (): void };
  setDialogueData: {
    (value: any): void;
  };
  setDialogueModal: {
    (value: SetStateAction<boolean>): void;
  };
  updateQuests: {
    (): Promise<void>;
  };
  setObject: {
    (object: any): void;
  };
  setMission: {
    (mission: any): void;
  };
  setIsWon: {
    (value: SetStateAction<boolean>): void;
  };
  setIsWin: {
    (value: SetStateAction<boolean>): void;
  };
  navigate: NavigateFunction;
  toggleMission: {
    (onGoing: boolean): void;
  };
  setDisableMarker: {
    (value: SetStateAction<boolean>): void;
  };
  setDisableMarkerState: {
    (disabled: boolean): void;
  };
  setIsWonBadge: {
    (value: SetStateAction<boolean>): void;
  };
  missionType: string | null;
  setMissionType: {
    (value: any): void;
  };
  selectedLanguge: string;
}

interface onDialogueClose extends onStartTypes {
  handleStart: {
    (): void;
  };
}

// on Start Function
export const onStart = async ({
  play,
  playApplause,
  selectedQuest,
  mission,
  setGuideData,
  setGuideModal,
  onClose,
  setDialogueData,
  setDialogueModal,
  updateQuests,
  setObject,
  setMission,
  setIsWon,
  setIsWin,
  navigate,
  toggleMission,
  setDisableMarker,
  setDisableMarkerState,
  setIsWonBadge,
  missionType,
  setMissionType,
  selectedLanguge,
}: onStartTypes) => {
  const { coins } = useRewards.getState();
  // play();
  if (!mission) return;
  // eslint-disable-next-line
  const {
    // eslint-disable-next-line
    dictionary,
    description,
    startDialogue,
    order,
    objects,
    puzzle,
    endDialogue,
    badges,
    quizzes,
    _id,
  } = mission;

  if (description && (!missionType || missionType === 'starter')) {
    setGuideData(description[selectedLanguge]);
    setGuideModal(true);
    onClose();
    return;
  }

  if (startDialogue) {
    setDialogueData(startDialogue);
    setDialogueModal(true);
    onClose();
    return;
  }

  if (order && order[0]) {
    if (order[0] === 'object') {
      setObject(objects[0]);
      setIsWon(true);
      playApplause();
    } else if (order[0] === 'puzzle') {
      setMission(puzzle[0]);
      setMissionType('puzzle');
      navigate('/map/puzzle');
    } else {
      navigate('/' + order[0]);
      await updateQuests();
      mission.order = order.slice(1);
      await updateQuests();
    }
    return;
  }

  if (endDialogue) {
    setDialogueData({ ...endDialogue, end: true });
    setDialogueModal(true);
    onClose();
    return;
  }

  if (badges && badges[0] && quizzes?.length === 0) {
    setObject(mission.badges[0]);
    setIsWon(true);
    setIsWin(true);
    playApplause();
    onClose();
    return;
  }

  useRewards.setState({ coins: coins + (mission.coins ? mission.coins : 0) });
  mission.finished = true;
  await updateQuests();
  const nextMission = await handleNextMission(selectedQuest?.missions, _id);
  if (mission.badges && mission.badges[0] && !mission.quizzes) {
    setIsWonBadge(true);
    setObject(mission.badges[0]);
    mission.badges.shift();
  } else if (nextMission) {
    setMission(nextMission);
    selectedQuest?.missions.shift();
    await updateQuests();
  } else if (missionType === 'starter') {
    if (selectedQuest?.enigmas.length > 0) {
      delete selectedQuest?.starterMission;
      navigate('/map/find-image');
      setMission(selectedQuest?.enigmas[0]);
      setMissionType('enigmas');
    }
  } else {
    if (
      selectedQuest.badges &&
      selectedQuest.badges[0] &&
      selectedQuest?.missions?.length === 0
    ) {
      setIsWonBadge(true);
      setObject(selectedQuest.badges[0]);
      selectedQuest.badges.shift();
    } else {
      useRewards.setState({
        coins: coins + (mission.coins ? mission.coins : 0),
      });
      finishQuest(selectedQuest?._id);
      await updateQuests();
    }
    setDisableMarker(false);
    setDisableMarkerState(false);
    toggleMission(false);
  }
  onClose();
};

// on Dialogue Close
export const onDialogueCloseHelper = async ({
  play,
  playApplause,
  mission,
  setDialogueModal,
  selectedQuest,
  updateQuests,
  setMission,
  navigate,
  toggleMission,
  setDisableMarker,
  setDisableMarkerState,
  setIsWon,
  setObject,
  handleStart,
  setIsWonBadge,
  missionType,
  setMissionType,
}: onDialogueClose) => {
  const { coins } = useRewards.getState();
  // play();
  setDialogueModal(false);
  delete mission.startDialogue;
  await updateQuests();
  if (mission && mission.starter) {
    setDisableMarker(true);
    setDisableMarkerState(true);
    toggleMission(true);
    setDialogueModal(false);
    finishMission(mission._id);
    const nextMission = handleNextMission(
      selectedQuest?.missions,
      mission?._id,
    );
    setMission(nextMission);
    selectedQuest?.missions.shift();
    await updateQuests();
  } else if (mission?.order && mission?.order[0]) {
    if (mission?.order[0] === 'object') {
      if (mission?.objects) {
        setObject(mission?.objects[0]);
        setIsWon(true);
        playApplause();
      }
    } else if (mission.order[0] === 'puzzle') {
      setMissionType('puzzle');
      navigate('/map/puzzle');
      setMission(mission?.puzzle[0]);
      mission.order = mission.order.slice(1);
      await updateQuests();
    } else {
      navigate('/' + mission.order[0]);
      mission.order = mission.order.slice(1);
      await updateQuests();
    }
  } else {
    delete mission.endDialogue;
    await updateQuests();
    finishMission(mission._id);
    setDisableMarker(true);
    setDisableMarkerState(true);
    toggleMission(true);
    setDialogueModal(false);
    const nextMission = await handleNextMission(
      selectedQuest?.missions,
      mission?._id,
    );
    if (mission.badges && mission.badges[0] && !mission.quizzes) {
      setIsWonBadge(true);
      setObject(mission.badges[0]);
      mission.badges.shift();
    } else if (nextMission) {
      useRewards.setState({
        coins: coins + (mission.coins ? mission.coins : 0),
      });
      selectedQuest?.missions.shift();
      await updateQuests();
      setMission(nextMission);
    } else if (missionType === 'starter') {
      if (selectedQuest?.enigmas.length > 0) {
        delete selectedQuest?.starterMission;
        navigate('/map/find-image');
        setMission(selectedQuest?.enigmas[0]);
        setMissionType('enigmas');
      }
    } else {
      if (selectedQuest.badges && selectedQuest.badges[0]) {
        setIsWonBadge(true);
        setObject(selectedQuest.badges[0]);
        selectedQuest.badges.shift();
      } else {
        useRewards.setState({
          coins: coins + (mission.coins ? mission.coins : 0),
        });
        finishQuest(selectedQuest?._id);
        await updateQuests();
      }
      handleStart();
    }
  }
};

// on Guide Close
export const onGuideCloseHelper = async ({
  play,
  playApplause,
  mission,
  setGuideModal,
  selectedQuest,
  updateQuests,
  setMission,
  navigate,
  toggleMission,
  setDisableMarker,
  setDisableMarkerState,
  setIsWon,
  setObject,
  setDialogueData,
  setDialogueModal,
  setIsWonBadge,
  missionType,
  setMissionType,
  selectedLanguge,
}: onStartTypes) => {
  const { coins } = useRewards.getState();
  // play();
  delete mission.description;
  setGuideModal(false);
  await updateQuests();

  if (mission && mission.starter) {
    setDisableMarker(true);
    setDisableMarkerState(true);
    toggleMission(true);
    setGuideModal(false);
    finishMission(mission._id);
    const nextMission = handleNextMission(
      selectedQuest?.missions,
      mission?._id,
    );
    setMission(nextMission);
    selectedQuest?.missions.shift();
    await updateQuests();
  } else if (mission?.startDialogue) {
    setDialogueData(mission?.startDialogue);
    setDialogueModal(true);
    delete mission.startDialogue;
    await updateQuests();
  } else if (mission?.order && mission?.order[0]) {
    if (mission?.order[0] === 'object') {
      if (mission?.objects) {
        setObject(mission?.objects[0]);
        setIsWon(true);
        playApplause();
      }
    } else {
      if (mission.order[0] === 'puzzle') {
        navigate('/map/puzzle');
        setMissionType('puzzle');
        setMission(mission?.puzzle[0]);

        mission.order = mission.order.slice(1);
        await updateQuests();
      } else {
        navigate('/' + mission.order[0]);
        mission.order = mission.order.slice(1);
        await updateQuests();
      }
    }
  } else {
    finishMission(mission._id);
    const nextMission = handleNextMission(
      selectedQuest?.missions,
      mission?._id,
    );
    if (nextMission) {
      useRewards.setState({
        coins: coins + (mission.coins ? mission.coins : 0),
      });
      setMission(nextMission);
      selectedQuest?.missions.shift();
      // update quests
      await updateQuests();
    } else if (missionType === 'starter') {
      if (selectedQuest?.enigmas.length > 0) {
        delete selectedQuest?.starterMission;
        navigate('/map/find-image');
        setMission(selectedQuest?.enigmas[0]);
        setMissionType('enigmas');
      }
    } else {
      if (selectedQuest.badges && selectedQuest.badges[0]) {
        setIsWonBadge(true);
        setObject(selectedQuest.badges[0]);
        selectedQuest.badges.shift();
      } else {
        finishQuest(selectedQuest?._id);
        await updateQuests();
      }
      await updateQuests();
      setGuideModal(false);
    }
    setGuideModal(false);
  }
};
