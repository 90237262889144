import WrapperBox from 'components/WrapperBox';
import styled from 'styled-components';
import tw from 'twin.macro';

export const EditButton = tw.button`
  flex justify-center items-end gap-1
`;

export const Icon = tw.img`
  shrink-0 w-5 h-8
`;

export const EditPanel = styled(WrapperBox)`
  ${tw`flex flex-col justify-start items-center gap-[1.375rem] p-8 pb-11`}
`;

export const Form = styled.form`
  ${tw`w-full flex flex-col gap-4`}
  label {
    ${tw`capitalize font-bold text-lg tracking-tight`}
  }

  button {
    ${tw`mt-8 self-center`}
  }
`;

export const InputWrapper = tw.div`
  flex flex-col gap-2
`;
