import { persist } from 'zustand/middleware';
import { create } from 'zustand';
import { Page } from 'components/Onboarding/Onboarding.types';

interface OnBoardingState {
  completedPages: Page[];
  IOSGuide: boolean;
  addPage: (page: Page) => void;
  setIOSGuide: (state: boolean) => void;
}

const useOnboarding = create<OnBoardingState>()(
  persist(
    (set) => ({
      completedPages: [],
      IOSGuide: false,
      addPage: (page) =>
        set((prevState) => ({
          ...prevState,
          completedPages: [...prevState.completedPages, page],
        })),
      setIOSGuide: (state: boolean) => set({ IOSGuide: state }),
    }),
    {
      name: 'onboarding',
    },
  ),
);

export default useOnboarding;
