import { StyledAvatarCardProps } from 'components/AvatarCard/AvatarCard.types';
import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div<StyledAvatarCardProps>`
  ${tw`w-full max-w-sm flex justify-between items-center
      border-2 border-darkGray  rounded-[18px] p-3`}
  background-color: ${({ bgColor }) => bgColor}
`;

export const Content = tw.div`
  flex justify-center items-center gap-2
`;

export const Info = tw.div`
 flex flex-col justify-center items-start gap-2
`;
