import React from 'react';
import Title from 'components/Typography/Title';
import { HeaderWrapper, ItemsWrapper, Wrapper } from './ChooseArea.styles';
import Paragraph from 'components/Typography/Paragraph';
import PlaceCard from 'components/PlaceCard';
import { Empty } from 'components/LottieFiles';
import useArea from 'store/useArea';
import { useTranslation } from 'react-i18next';
import useData from 'store/useData';

interface AreaProps {
  id: string;
  name: string;
  distance: string; // distance can be in "m" or "km"
}

const ChooseArea: React.FC<any> = ({ items }) => {
  const { setSelectedArea } = useArea();
  const { quests = [], setQuest } = useData();
  const { t } = useTranslation();

  const handleAreaSelect = (item: any) => {
    setSelectedArea(item);
    setQuest(
      quests?.filter(
        (quest: any) =>
          quest.missions[0]?.loc[0] === item.loc[0] &&
          quest.missions[0]?.loc[1] === item.loc[1],
      ),
    );
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title level={2}>{t('areaTitle')}</Title>
        <Paragraph>{t('areaDescription')}</Paragraph>
      </HeaderWrapper>
      <ItemsWrapper>
        {/* @ts-ignore */}
        {items.length > 0 ? (
          items.map((item: AreaProps) => {
            return (
              <PlaceCard
                key={item.id}
                name={item.name}
                location={item.distance}
                handleSelectArea={() => handleAreaSelect(item)}
              />
            );
          })
        ) : (
          <Empty />
        )}
      </ItemsWrapper>
    </Wrapper>
  );
};

export default ChooseArea;
