import { WrapperBox, WrapperBoxContainer } from './StateWrapper.styles';
import { StateWrapperProps } from './StateWrapper.types';

const StateWrapper = ({
  children,
  width,
  height,
  ...rest
}: StateWrapperProps): JSX.Element => {
  return (
    <WrapperBox width={width} height={height} {...rest}>
      <WrapperBoxContainer data-testid="content-wrapper">
        {children}
      </WrapperBoxContainer>
    </WrapperBox>
  );
};

export default StateWrapper;
