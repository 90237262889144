import tw from 'twin.macro';

export const PuzzleContent = tw.div`
  relative w-full flex flex-col items-center justify-center px-2 pt-[1.5rem] gap-4`;

export const PuzzleHeader = tw.div`w-full px-6 py-1 flex items-center justify-between`;

export const PuzzleInfo = tw.img`w-8 h-8 object-cover cursor-pointer`;

export const PuzzleHelpModal = tw.div`w-full h-auto rounded-3xl bg-white p-8 
flex justify-center items-center relative`;

export const PuzzleHelpModalCloseButton = tw.button`absolute top-0 right-2
transform translate-x-1/2 -translate-y-1/2 w-8 h-8 rounded-full bg-white
border border-darkBlue
flex justify-center items-center`;

export const HelpImage = tw.img`h-[15rem] object-contain rounded-3xl`;
