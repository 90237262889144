import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

interface AreaProps {
  id: string;
  name: string;
  distance: string; // distance can be in "m" or "km"
  latitude: string;
  longitude: string;
}

interface DataProps {
  areasList?: null | Array<any>;
  selectedArea?: null | any;
  setAreasList: (areasList: []) => void;
  setSelectedArea: (selected: any) => void;
}

// add devtools
const useArea = create<DataProps>()(
  devtools(
    persist(
      (set) => ({
        areasList: null,
        selectedArea: null,
        setAreasList: (areasList: []) => set({ areasList }),
        setSelectedArea: (selected: any) => set({ selectedArea: selected }),
      }),
      {
        name: 'areas',
      },
    ),
  ),
);

export default useArea;
