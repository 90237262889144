import Button from 'components/Button';
import ContentWrapper from 'components/ContentWrapper';
import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import WrapperBox from 'components/WrapperBox';
import TotalResultItem from '../TotalResultItem';
import {
  CoinsRewardsContainter,
  CoinsRewardsImage,
  ResultsContainer,
  RewardsContainer,
  RewardsImage,
  RewardsItemsContainer,
  TotalResultContainer,
} from './FinalResults.styles';
import coin from 'assets/icons/coins/coin.png';
import { FinalResultProps } from './FinalResults.type';
import { useTranslation } from 'react-i18next';

const FinalResults = ({
  EarnedCoins,
  EarnedObject,
  TotalWrongAnswers,
  TotalCorrectAnswers,
  onClick,
}: FinalResultProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ContentWrapper hasSpeechBox={false}>
      <ResultsContainer>
        <Title level={2} renderAs="h2">
          {t('challengecompleted')}
        </Title>
        <Paragraph>{t('total')}</Paragraph>
        <TotalResultContainer>
          <TotalResultItem status="wrong" totalAnswers={TotalWrongAnswers} />
          <TotalResultItem
            status="correct"
            totalAnswers={TotalCorrectAnswers}
          />
        </TotalResultContainer>
        <RewardsContainer>
          <Paragraph>{t('rewards')}</Paragraph>
          <RewardsItemsContainer>
            <WrapperBox width="8rem" height="6rem" rotation="-2.15deg">
              <CoinsRewardsContainter>
                <CoinsRewardsImage src={coin} alt="coins" />
                <Title level={2} renderAs="label">
                  {EarnedCoins ? EarnedCoins : 0}
                </Title>
              </CoinsRewardsContainter>
            </WrapperBox>
            {EarnedObject && (
              <WrapperBox width="8rem" height="6rem" rotation="1.99deg">
                {EarnedObject && (
                  <RewardsImage src={EarnedObject} alt="badge" />
                )}
              </WrapperBox>
            )}
          </RewardsItemsContainer>
        </RewardsContainer>
        <Button
          rotation="-1.89deg"
          shadowColor="black"
          width="100%"
          onClick={onClick}
        >
          {t('collectCoins')}
        </Button>
      </ResultsContainer>
    </ContentWrapper>
  );
};

export default FinalResults;
