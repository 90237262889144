import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledStatisticsCardProps } from './StatisticsCard.types';

export const Wrapper = styled.div<StyledStatisticsCardProps>`
  ${tw`relative w-full max-w-sm h-20 border-2 border-darkGray rounded-2xl
       flex items-start justify-start p-4 `}
  background-color: ${({ bgColor }) => bgColor}
`;

export const Items = tw.div`
 flex justify-center items-center gap-3 
 absolute left-3 right-3 top-[50%] -translate-y-1/2
`;
