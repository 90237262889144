import Button from 'components/Button';
import ContentWrapper from 'components/ContentWrapper';
import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMission from 'store/useMission';
import FindPlaceImageCard from '../FindPlaceImageCard';
import {
  FindPlaceImageClue,
  FindPlaceImageContent,
} from './FindPlaceImage.styles';
import { FindPlaceImageProps } from './FindPlaceImage.types';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import { updateQuest } from 'helpers/QuestsHelpers';
import useData from 'store/useData';

const FindPlaceImage = ({
  image,
  clue,
  avatar,
  onClick,
  ...rest
}: FindPlaceImageProps): JSX.Element => {
  const [playClick] = useSound(ClickSound);
  const { t } = useTranslation();
  const { onGoing, selectedQuest } = useMission();
  const { setQuest } = useData();
  const navigate = useNavigate();
  const allQuests = localStorage.getItem('allData');

  const handleQuestAfterExit = async () => {
    if (allQuests) {
      const quest = JSON.parse(allQuests)?.filter(
        (quest: { _id: string }) => quest._id === selectedQuest._id,
      );
      // update quests
      await updateQuest(quest[0]).then((res) => {
        setQuest(res);
      });
    }
  };

  const onBack = () => {
    playClick();
    navigate(-1);
  };

  const exit = () => {
    playClick();
    handleQuestAfterExit();
    navigate(-1);
  };

  return (
    <ContentWrapper hasSpeechBox={false} avatar={avatar} {...rest}>
      <FindPlaceImageContent>
        <Title level={3} renderAs="label">
          {t('findplace')}
        </Title>
        <FindPlaceImageCard
          borderRadius="31px"
          borderWidth="2px"
          imageSrc={image}
          shadowColor="#007AD1"
          shadowSpace="8px"
          width="100%"
        />
        <FindPlaceImageClue>
          <Title level={3} renderAs="h3">
            {t('clue')}
          </Title>
          <Paragraph>{clue}</Paragraph>
        </FindPlaceImageClue>
        <Button
          rotation="-1.89deg"
          shadowColor="black"
          width="100%"
          onClick={onGoing ? onBack : onClick}
        >
          {onGoing ? t('back') : t('startmission')}
        </Button>
        {!onGoing && (
          <Button
            rotation="-1.89deg"
            shadowColor="black"
            width="100%"
            bgColor="white"
            color="black"
            onClick={exit}
          >
            {t('cancel')}
          </Button>
        )}
      </FindPlaceImageContent>
    </ContentWrapper>
  );
};

export default FindPlaceImage;
