import { Player } from '@lottiefiles/react-lottie-player';
import { LottieProps } from './Lottie.type';

export default function Loading({
  className,
  autoplay = true,
  loop = true,
  speed = 1.5,
}: LottieProps) {
  return (
    <Player
      className={className}
      autoplay={autoplay}
      speed={speed}
      loop={loop}
      src="https://assets4.lottiefiles.com/private_files/lf30_vut4pyyx.json"
      style={{ height: '200px', width: '200px' }}
    />
  );
}
