import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import kamal from 'assets/images/avatars/kamal.png';

interface AssistantState {
  pa: Pa;
  setPa: (pa: Pa) => void;
}

type Pa = {
  name: string;
  avatar: string;
};

const usePersonalAssistant = create<AssistantState>()(
  persist(
    (set) => ({
      pa: {
        name: 'kamal',
        avatar: kamal,
      },
      setPa: (pa) => set({ pa }),
    }),
    {
      name: 'personal-assistant',
    },
  ),
);

export default usePersonalAssistant;
