import tw from 'twin.macro';
import styled from 'styled-components';
import {
  StyledStateWrapperProps,
  StyledStateWrapperBox,
} from './StateWrapper.types';

export const WrapperBox = styled.div<StyledStateWrapperBox>`
  ${tw`min-w-[13.438rem] max-w-[18.938rem] min-h-[5.25rem] z-20`};
  width: ${({ width }) => width} !important;
  height: ${({ height }) => height} !important;
`;

export const WrapperBoxContainer = styled.div<StyledStateWrapperProps>`
  ${tw`relative min-w-[13.438rem] max-w-[18.938rem] min-h-[5.25rem] bg-softGray transition duration-75 ease-in
  border-[3px] border-black rounded-2xl`};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ bgColor }) => bgColor};
  border-color: ${({ borderColor }) => borderColor};
  border-width: ${({ borderWidth }) => borderWidth};
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: ${({ clickable }) => clickable && 'pointer'};

  &:after {
    ${tw`[content: ""] absolute -z-10 inset-0 bg-yellow border-[3px] border-black rounded-xl 
    translate-x-1 translate-y-2.5`};
  }
`;
