import React, { FormEvent, useEffect } from 'react';
import ChooseForm from 'components/ChooseInput';
import {
  Avatar,
  AvatarWrapper,
  ChooseNameWrapper,
  Name,
  Shape,
} from './Start.styles';
import shape from 'assets/icons/nameShape.svg';
import Title from 'components/Typography/Title';
import { useTranslation } from 'react-i18next';
import SpeechBox from 'components/SpeechBox';
import useKeyboard from 'store/useKeyboard';
import useUser from 'store/useUser';
import { useNavigate } from 'react-router-dom';
import ClickSound from 'assets/sounds/Tap.wav';
import useSound from 'use-sound';
import usePersonalAssistant from 'store/usePersonalAssistant';

const ChooseName: React.FC = () => {
  const { pa } = usePersonalAssistant();
  const [play] = useSound(ClickSound);
  const { hasKeyboard } = useKeyboard();
  const { user, setUser, isLogged, setIsLogged } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    play();
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get('username') as string;
    setIsLogged(true);
    setUser({
      username: name,
      avatarImage: pa.avatar,
      avatarName: pa.name,
    });
  };

  useEffect(() => {
    if (user?.username && isLogged) {
      navigate('/start/welcome');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLogged, navigate]);

  return (
    <ChooseNameWrapper>
      <AvatarWrapper hasKeyboard={hasKeyboard}>
        <Avatar src={pa.avatar} alt="pa-avatar" />
        <Name>
          {!hasKeyboard && (
            <SpeechBox
              className="pt-5 pb-6"
              hasLightBorder={false}
              cornPosition="top"
              width="100%"
            >
              <span className="p-5">
                <Title renderAs="h1" level={1}>
                  {t('askForName')}
                </Title>
              </span>
            </SpeechBox>
          )}
        </Name>
      </AvatarWrapper>
      <ChooseForm handleSubmit={handleSubmit} />
      <Shape src={shape} />
    </ChooseNameWrapper>
  );
};

export default ChooseName;
