import { ButtonProps } from './Button.types';
import { StyledBtn, Wrapper } from './Button.styles';
import { forwardRef } from 'react';

const Button = forwardRef(
  (
    {
      children,
      width,
      height,
      bgColor,
      color,
      shadowColor,
      borderColor,
      rotation,
      hasAfter = false,
      afterBg,
      isRounded = false,
      ...rest
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <StyledBtn
        width={width}
        height={height}
        rotation={rotation}
        {...rest}
        ref={ref}
      >
        <Wrapper
          bgColor={bgColor}
          color={color}
          hasAfter={hasAfter}
          shadowColor={shadowColor}
          borderColor={borderColor}
          afterBg={afterBg}
          isRounded={isRounded}
        >
          {children}
        </Wrapper>
      </StyledBtn>
    );
  },
);

export default Button;
