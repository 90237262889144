import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import './styles.css';
// @ts-ignore
// import Ibnbatouta from './scene.gltf';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import { ModelViewerElement } from '@google/model-viewer';
import useUser from 'store/useUser';

type ToastARModelViewerProps = {
  alt: string;
  glbUrl: string;
  id: string;
  posterUrl?: string;
  // add ref
  ref?: React.RefObject<ModelViewerElement>;
};

const ToastARModelViewer = ({
  alt,
  id,
  glbUrl,
  posterUrl,
  ref,
}: ToastARModelViewerProps) => {
  const [playClick] = useSound(ClickSound);
  const { t } = useTranslation();

  useEffect(() => {
    var modelViewer: any = document.getElementById(id);

    if (modelViewer) {
      modelViewer.addEventListener('ar-status', (event: any) => {
        if (event.detail.status === 'failed') {
          const error: any = document.getElementById('error');
          error.classList.remove('hide');
          error.addEventListener('transitionend', (event: any) => {
            error.classList.add('hide');
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [document, id]);

  // const mobileOS = getMobileOS();
  return (
    <model-viewer
      ref={ref}
      src={glbUrl}
      alt={alt}
      id={id}
      ar
      ar-modes="webxr scene-viewer quick-look"
      auto-rotate
      camera-controls
      poster={posterUrl}
      touch-action="pan-y"
      ar-placement="wall"
      shadow-intensity="1"
      xr-environment
    >
      <Button
        width="15.801rem"
        height="3.813rem"
        hasAfter={false}
        isRounded={false}
        rotation="-2.36deg"
        shadowColor="black"
        slot="ar-button"
        id="ar-button"
        className="hide"
        onClick={() => playClick()}
      >
        {t('viewinspace')}
      </Button>

      <div id="error" className="hide">
        {t('ArnotSupported')}
      </div>
    </model-viewer>
  );
};

export default function Camera() {
  const location = useLocation();
  const [image, setImage] = useState<string>('');
  const navigate = useNavigate();
  // global states
  const { isLogged } = useUser();

  useEffect(() => {
    if (!isLogged) {
      navigate('/start');
    }
    // eslint-disable-next-line
  }, [isLogged]);

  useEffect(() => {
    if (location?.state?.image) setImage(location?.state?.image);
    else navigate('/map');
    // eslint-disable-next-line
  }, [location?.state?.image]);

  return (
    <ToastARModelViewer
      alt="3D"
      glbUrl={image}
      // glbUrl={Ibnbatouta}
      id="model-viewer"
    />
  );
}
