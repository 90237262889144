const TraingleDown = () => {
  return (
    <svg viewBox="0 0 112 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M112 4.07401V0H5V3.80372C5 11.871 9.62127 19.2251 16.8899 22.7247L49.8414 38.5903C55.6261 41.3755 62.3671 41.3615 68.1402 38.5522L100.189 22.957C107.414 19.4409 112 12.1097 112 4.07401Z"
        fill="#FED132"
      />
      <path
        d="M112 4.07389V0H109V1.78596C109 9.86232 104.368 17.2231 97.0873 20.7179L67.9981 34.6808C62.3045 37.4137 55.6834 37.4391 49.969 34.75L20.0583 20.6744C12.6979 17.2107 8 9.80776 8 1.67316V0H5V3.8036C5 11.8708 9.62127 19.2249 16.8899 22.7246L49.8414 38.5902C55.6261 41.3754 62.3671 41.3614 68.1402 38.5521L100.189 22.9569C107.414 19.4408 112 12.1096 112 4.07389Z"
        fill="black"
      />
      <path
        d="M63.1402 34.5522L95.1887 18.957C102.414 15.4409 107 8.03572 107 0H0C0 7.94724 4.55253 15.192 11.713 18.6396L44.8414 34.5903C50.6261 37.3755 57.3671 37.3615 63.1402 34.5522Z"
        fill="white"
      />
      <path
        d="M63.1402 34.5522L95.1887 18.957C102.414 15.4409 107 8.03572 107 0H104C104 6.72321 100.144 12.8507 94.0832 15.76L62.9981 30.6809C57.3045 33.4138 50.6834 33.4392 44.969 30.7501L12.953 15.6838C6.87771 12.8248 3 6.71438 3 0H0C0 7.94724 4.55253 15.192 11.713 18.6396L44.8414 34.5903C50.6261 37.3755 57.3671 37.3615 63.1402 34.5522Z"
        fill="black"
      />
    </svg>
  );
};

export default TraingleDown;
