// get quizzes items and filter with quizzes id's it from store
export const getQuizzesItems = async (quizzes: any) => {
  const quizzesData = await handleQuizzes(quizzes);
  return quizzesData;
};

// hanndle Quizzes Object
export const handleQuizzes = async (quizzes: any) => {
  const quizzesItems = await quizzes?.map((item: any) => {
    const {
      _id,
      question,
      answers1,
      answers2,
      answers3,
      answers4,
      correctIndex,
      coins,
      dictionary,
    } = item;
    const translatedAnswers = dictionary?.answers1
      ? [
          dictionary?.answers1,
          dictionary?.answers2,
          dictionary?.answers3,
          dictionary?.answers4,
        ]
      : [];
    const answers = [answers1, answers2, answers3, answers4];
    const correctAnswers = correctIndex
      .split(',')
      .map((item: any) => answers[item - 1]);

    const translatedcorrectAnswers = correctIndex
      .split(',')
      .map((item: any) => translatedAnswers[item.trim() - 1]);

    return {
      id: _id,
      type: 'qcm',
      question,
      answers,
      correctAnswers: correctAnswers,
      completed: false,
      active: false,
      coins,
      isCorrect: false,
      retryCount: 0,
      dictionary,
      translatedAnswers,
      translatedcorrectAnswers,
    };
  });
  return quizzesItems;
};
