import styled from 'styled-components';
import tw from 'twin.macro';
import { LanguageItemProps } from './LanguageSelect.types';

export const LangugaesWrapper = tw.div`w-full h-screen flex flex-col justify-center items-center gap-10`;

export const LanguagesIcon = tw.img`w-[18.125rem] h-[18.125rem]`;

export const LanguagesContainer = tw.div`w-full p-4 flex flex-col justify-center items-center gap-8`;

export const LanguageItemContainer = tw.div`w-full flex flex-col justify-center items-center gap-3`;

export const LanguageItem = styled.button<LanguageItemProps>(({ isActive }) => [
  tw`w-[84%] xs:px-16 sm:px-20 flex justify-center items-center gap-4  py-3 bg-[#F9FCFD] rounded-xl
       text-base text-darkGray font-medium capitalize border-2 border-[#FDC800]`,
  isActive && tw`[&>div]:bg-yellow`,
]);

export const LanguageItemText = tw.span`text-base text-darkGray font-medium capitalize`;

export const LanguageItemRadio = tw.div`
    w-5 h-5 rounded-full border-2 border-darkGray
`;
