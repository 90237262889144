import { Player } from '@lottiefiles/react-lottie-player';
import { LottieProps } from './Lottie.type';

export default function Winning({
  className,
  autoplay = true,
  loop = false,
  speed = 1.5,
}: LottieProps) {
  return (
    <Player
      className={className}
      autoplay={autoplay}
      loop={loop}
      speed={speed}
      src={'https://assets10.lottiefiles.com/packages/lf20_5ki7ru7q.json'}
    />
  );
}
