import WrapperBox from 'components/WrapperBox';
import QuotesMark from 'Icons/QuotesMark';
import {
  StyledFindPlaceQuoteWrapper,
  StyledFindPlaceImage,
  StyledQuotesMark,
  StyledQuotesText,
} from './FindPlaceQuoteCard.styles';
import { FindPlaceQuoteProps } from './FindPlaceQuoteCard.types';

const FindPlaceQuoteCard = ({
  quote,
  ...rest
}: FindPlaceQuoteProps): JSX.Element => {
  return (
    <WrapperBox {...rest}>
      <StyledFindPlaceQuoteWrapper borderRadius={rest?.borderRadius}>
        <StyledFindPlaceImage>
          <StyledQuotesMark>
            <QuotesMark />
            <QuotesMark />
          </StyledQuotesMark>
          <StyledQuotesText>{quote}</StyledQuotesText>
        </StyledFindPlaceImage>
      </StyledFindPlaceQuoteWrapper>
    </WrapperBox>
  );
};

export default FindPlaceQuoteCard;
