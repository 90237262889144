import styled from 'styled-components';
import tw from 'twin.macro';
import { CircleProps } from './ProfileAvatar.types';

export const Wrapper = tw.div`
 relative 
`;

export const Circle = styled.div<CircleProps>`
  ${tw`relative w-[3.538rem]  h-[3.538rem] flex justify-center items-center bg-white
    rounded-full text-sm text-white capitalize font-bold`};
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};

  &:after {
    ${tw`[content: ""] absolute  inset-0 border-2 border-black  
       translate-x-1 translate-y-1 rounded-full`}
    background-color: ${({ shadowColor }) => shadowColor};
  }
`;

export const ProfilePic = tw.img`
 absolute top-0 left-0 w-[3.538rem]  h-[3.538rem] object-cover
 border-2 border-black rounded-full
`;
