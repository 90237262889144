import Button from 'components/Button';
import ContentWrapper from 'components/ContentWrapper';
import Title from 'components/Typography/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMission from 'store/useMission';
import FindPlaceQuoteCard from '../FindPlaceQuoteCard';
import {
  FindPlaceImageClue,
  FindPlaceImageContent,
} from './FindPlaceQuote.styles';
import { FindPlaceQuoteProps } from './FindPlaceQuote.types';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import useData from 'store/useData';
import { updateQuest } from 'helpers/QuestsHelpers';

const FindPlaceQuote = ({
  avatar,
  quote,
  onClick,
  ...rest
}: FindPlaceQuoteProps): JSX.Element => {
  const [playClick] = useSound(ClickSound);
  // state data
  const {
    onGoing,
    selectedQuest,
    setSelectedQuest,
    setMission,
    setMissionType,
    setMissionTimeLeft,
    setObject,
    setRefresh,
    toggleMission,
    setDisableMarkerState,
  } = useMission();
  const { t } = useTranslation();

  const { setQuest } = useData();
  const navigate = useNavigate();
  const allQuests = localStorage.getItem('allData');

  const handleQuestAfterExit = async () => {
    if (allQuests) {
      const quest = JSON.parse(allQuests)?.filter(
        (quest: { _id: string }) => quest._id === selectedQuest._id,
      );
      // update quests
      setMission(null);
      setSelectedQuest(null);
      setMissionType(null);
      setObject(null);
      setRefresh(false);
      toggleMission(false);
      setDisableMarkerState(false);
      setMissionTimeLeft(null);
      localStorage.setItem('counterTime', '');
      await updateQuest(quest[0]).then((res) => {
        setQuest(res);
      });
    }
  };
  const onBack = () => {
    playClick();
    navigate(-1);
  };

  const exit = () => {
    playClick();
    handleQuestAfterExit();
    navigate(-1);
  };

  return (
    <ContentWrapper hasSpeechBox={false} avatar={avatar} {...rest}>
      <FindPlaceImageContent>
        <Title level={3} renderAs="label">
          {t('findplace')}
        </Title>

        <FindPlaceQuoteCard
          borderRadius="31px"
          borderWidth="2px"
          height="294.15px"
          quote={quote}
          shadowColor="#007AD1"
          shadowSpace="8px"
          width="100%"
        />
        <FindPlaceImageClue>
          <Button
            className="find-qoute-button"
            rotation="-1.89deg"
            shadowColor="black"
            width="100%"
            onClick={onGoing ? onBack : onClick}
          >
            {onGoing ? t('back') : t('startmission')}
          </Button>
        </FindPlaceImageClue>
        {!onGoing && (
          <Button
            rotation="-1.89deg"
            shadowColor="black"
            width="100%"
            bgColor="white"
            color="black"
            onClick={exit}
          >
            {t('cancel')}
          </Button>
        )}
      </FindPlaceImageContent>
    </ContentWrapper>
  );
};

export default FindPlaceQuote;
