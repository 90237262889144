import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import WrapperBox from 'components/WrapperBox';
import XMark from 'Icons/XMark';
import { useTranslation } from 'react-i18next';
import { theme } from 'twin.macro';
import {
  CloseButton,
  QcmQuizPopupContent,
  QcmQuizPopupDescription,
  QcmQuizPopupImage,
  QcmQuizPopupTitle,
  RetryButton,
} from './QcmQuizPopup.styles';
import { QcmQuizPopupProps } from './QcmQuizPopup.types';

const QcmQuizPopup = ({
  title,
  description,
  status,
  image,
  onClose,
  onButtonClick,
  failed,
}: QcmQuizPopupProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    // <QcmQuizPopupContainer>
    <WrapperBox
      width="100%"
      height="13.188rem"
      shadowColor={
        status === 'wrong' ? theme`colors.red` : theme`colors.success`
      }
    >
      <QcmQuizPopupContent data-testid="QcmQuizPopup">
        <QcmQuizPopupImage src={image} alt={title} />
        <Title level={2} renderAs="h2">
          {title}
        </Title>
        {description && (
          <QcmQuizPopupDescription>
            <QcmQuizPopupTitle>{t('correctAnswer')}</QcmQuizPopupTitle>
            {description && <Paragraph>{description}</Paragraph>}
          </QcmQuizPopupDescription>
        )}
      </QcmQuizPopupContent>
      <CloseButton
        bgColor={theme`colors.softGray`}
        hasAfter={true}
        afterBg={status === 'wrong' ? theme`colors.red` : theme`colors.success`}
        isRounded={true}
        onClick={onClose}
        aria-label="close-marker-popup"
      >
        <XMark
          color={status === 'wrong' ? theme`colors.red` : theme`colors.success`}
        />
      </CloseButton>
      <RetryButton
        width="15.801rem"
        height="3.813rem"
        hasAfter={false}
        isRounded={false}
        rotation="-2.36deg"
        bgColor={status === 'wrong' ? theme`colors.red` : theme`colors.success`}
        color={
          status === 'wrong' ? theme`colors.softGray` : theme`colors.darkGray`
        }
        shadowColor="black"
        onClick={onButtonClick}
      >
        {status === 'wrong' ? (!failed ? 'Retry' : 'Next') : 'Next'}
      </RetryButton>
    </WrapperBox>
    // </QcmQuizPopupContainer>
  );
};

export default QcmQuizPopup;
