import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import CopyIcon from 'Icons/CopyIcon';
import { useRef } from 'react';
import { CopyButton, CopyWrapper, Link, Wrapper } from './InviteCard.styles';
import { InviteCardProps } from './InviteCard.types';
import { useTranslation } from 'react-i18next';

const InviteCard = ({
  link,
  description,
  bgColor = '#D8E2FB',
  ...rest
}: InviteCardProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const copyLink = () => {
    if (inputRef.current) {
      const text = inputRef.current.value;
      navigator.clipboard.writeText(text);
    }
  };
  return (
    <Wrapper bgColor={bgColor} {...rest}>
      <Title renderAs="h2" level={2}>
        {t('inviteFriends')}
      </Title>
      <Paragraph>{description}</Paragraph>
      <CopyWrapper>
        <Link
          aria-label="invite link"
          ref={inputRef}
          type="text"
          value={link}
          readOnly
        />
        <CopyButton type="button" onClick={copyLink}>
          <CopyIcon />
        </CopyButton>
      </CopyWrapper>
    </Wrapper>
  );
};

export default InviteCard;
