import WrapperBox from 'components/WrapperBox';
import styled from 'styled-components';
import tw from 'twin.macro';

export const Panel = styled(WrapperBox)`
  ${tw`min-w-[17rem] flex flex-col justify-start items-center gap-[1.375rem] p-8 pb-11`}
`;

export const Form = styled.form`
  ${tw`w-full flex flex-col gap-4`}
  label {
    ${tw`capitalize font-bold text-lg tracking-tight`}
  }

  button {
    ${tw`mt-8 mb-7 self-center`}
  }
`;

export const InputWrapper = tw.div`
  flex flex-col gap-2
`;

export const Devider = styled.span`
  ${tw`w-full flex justify-between items-center text-2xl font-bold tracking-tight text-darkGray capitalize`}
  &:after,  &:before {
    ${tw`[content: ''] w-[42%] h-[1px] bg-darkGray`}
  }
`;

export const Buttons = tw.div`
  w-full flex flex-col justify-center items-center gap-4
`;
