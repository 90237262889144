import styled from 'styled-components';
import tw from 'twin.macro';
import { LanguageProps } from './Switcher.types';

export const Content = tw.div`
 w-full flex flex-col justify-center items-center gap-6 
 text-center py-8
`;

export const Languages = tw.div`
 w-full flex flex-col justify-center items-center gap-2
`;

export const Language = styled.button<LanguageProps>(({ isActive }) => [
  tw`w-[84%] flex justify-center items-center py-4 bg-softGray rounded-lg
     text-base text-darkGray font-medium capitalize border-2 border-darkGray`,
  isActive && tw`bg-yellow`,
]);
