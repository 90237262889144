import { BoxWarpper, StyledCorn, Wrapper } from './SpeechBox.styles';
import { SpeechBoxProps } from './SpeechBox.types';
import SmallCorn from 'Icons/SmallCorn';
import Corn from 'Icons/Corn';

const SpeechBox = ({
  children,
  width,
  height,
  afterBg = '#FFD949',
  cornPosition,
  translateCorn,
  hasLightBorder,
  ...rest
}: SpeechBoxProps): JSX.Element => {
  return (
    <Wrapper {...rest}>
      <BoxWarpper
        hasLightBorder={hasLightBorder}
        width={width}
        height={height}
        afterBg={afterBg}
      >
        <StyledCorn
          data-testid="corn"
          cornPosition={cornPosition}
          translateCorn={translateCorn}
          hasLightBorder={hasLightBorder}
        >
          {hasLightBorder ? <SmallCorn /> : <Corn />}
        </StyledCorn>
        {children}
      </BoxWarpper>
    </Wrapper>
  );
};

export default SpeechBox;
