// Importing styles and components
import { ResultContent, ResultHeaderImg, ResultPage } from './Quizzes.styles';
import CoinsChess from 'assets/icons/coins/CoinsChess.png';
import FinalResults from 'components/Quizzes/FinalResults';
import useMission from 'store/useMission';
import { useNavigate } from 'react-router-dom';
import { CelebrateLottie } from 'components/LottieFiles';
import useRewards from 'store/useRewards';
import useUser from 'store/useUser';
import useSound from 'use-sound';
import collectCoins from 'assets/sounds/collectCoins.wav';
import { useEffect } from 'react';
import {
  finishQuest,
  handleNextMission,
  updateQuest,
} from 'helpers/QuestsHelpers';
import { useMap } from 'react-map-gl';

// Main component
const Result = () => {
  // Hook to access the current map
  const { current: map } = useMap();

  // Hook for playing sound effects
  const [playCollectCoins] = useSound(collectCoins);

  // State hooks
  const { isLogged } = useUser();
  const {
    mission,
    setMission,
    selectedQuest,
    toggleMission,
    setRefresh,
    missionType,
    setMissionType,
    setObject,
    setQuestBadge,
  } = useMission();
  const { coins, setCoins, badges, setBadges } = useRewards();
  const navigate = useNavigate();

  // Check if the user is logged in and navigate if not
  useEffect(() => {
    if (!isLogged) {
      navigate('/start');
    }
    // eslint-disable-next-line
  }, []);

  // Function to get the total number of correct answers
  const getCorrectNumber = () => {
    return mission?.quizzes?.filter((item: any) => item.isCorrect).length;
  };

  // Function to get the total number of wrong answers
  const getWrongNumber = () => {
    return mission?.quizzes?.filter(
      (item: any) => item.completed && !item.isCorrect,
    ).length;
  };

  // Function to get the total number of coins earned
  const getTotalCoins = () => {
    const coins = mission?.quizzes
      ?.filter((item: any) => item.completed && item.isCorrect)
      .map((item: any) => item.coins);

    if (coins === null) return 0;
    return coins?.reduce((a: any, b: any) => a + b, 0);
  };

  // Function to handle result collection
  const collectResult = (e: any) => {
    map?.triggerRepaint();
    playCollectCoins();
    e.preventDefault();

    // Check if a badge exists and add it to the list of badges
    if (mission?.badges && mission?.badges[0]) {
      const badgeExists = badges.find(
        (badge: any) => badge?._id === mission?.badges[0]?._id,
      );
      if (!badgeExists) {
        const newBadges: any = [...badges, mission?.badges[0]];
        setBadges(newBadges);
      }
    }

    const newMission = { ...mission };

    // Handle different scenarios based on mission properties
    if (mission.endDialogue) {
      setCoins(+coins + getTotalCoins());
      setRefresh(true);
      navigate('/map');
    } else {
      setMission(newMission);
      setCoins(+coins + getTotalCoins());

      if (newMission.order.length > 0) {
        setMissionType('');
        setRefresh(true);
        navigate('/map');
      } else {
        newMission.finished = true;
        setMission(newMission);
        const nextMission = handleNextMission(
          selectedQuest?.missions,
          newMission?._id,
        );

        if (nextMission) {
          setMission(nextMission);
          selectedQuest?.missions.shift();
          updateQuest(selectedQuest);
          setRefresh(true);
          navigate('/map');
        } else if (missionType === 'starter') {
          if (selectedQuest?.enigmas.length > 0) {
            navigate('/map/find-image');
            setMission(selectedQuest?.enigmas[0]);
            setMissionType('enigmas');
          }
        } else {
          selectedQuest.missions.shift();

          if (
            selectedQuest.badges &&
            selectedQuest.badges[0] &&
            selectedQuest.missions.length === 0
          ) {
            setRefresh(true);
            navigate('/map');
          } else {
            finishQuest(selectedQuest?._id);
            updateQuest(selectedQuest);
            toggleMission(false);
          }
          navigate('/map');
        }
      }
    }
  };

  // Render the result page
  return (
    <ResultPage>
      {/* Display celebration animation if more correct answers than wrong */}
      {mission && getCorrectNumber() > getWrongNumber() && (
        <CelebrateLottie className="absolute left-0 top-0 w-full" />
      )}
      {/* Display chess icon */}
      <ResultHeaderImg src={CoinsChess} alt="chess" />
      {/* Display final results component */}
      <ResultContent>
        <FinalResults
          TotalCorrectAnswers={getCorrectNumber()}
          TotalWrongAnswers={getWrongNumber()}
          EarnedCoins={getTotalCoins()}
          EarnedObject={
            mission?.badges && mission?.badges[0]?.media[0]?.location
          }
          onClick={collectResult}
        />
      </ResultContent>
    </ResultPage>
  );
};

export default Result;
