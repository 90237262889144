import styled from 'styled-components';
import tw from 'twin.macro';
//PA PAGE
export const ChoosePaWrapper = tw.div`
  self-stretch w-full max-w-sm mx-auto 
  flex flex-col gap-12 pt-10 px-6 pb-[6rem]
`;

export const Grid = tw.div`
w-full grid grid-cols-2 gap-x-4 gap-y-20
`;

//NAME PAGE
export const ChooseNameWrapper = tw.div`
  relative w-full max-w-screen-md mx-auto isolate overflow-x-hidden
  flex flex-col justify-end  self-stretch bg-yellow overflow-y-hidden
`;
//Choose Area
export const ChooseAreaWrapper = tw.div`
  relative w-full max-w-screen-md mx-auto isolate overflow-x-hidden px-4 pt-10
  flex flex-col justify-start items-center  self-stretch  overflow-y-hidden
`;
export const ChooseLngWrapper = tw.div`
  relative w-full h-full max-w-screen-md mx-auto isolate
  flex flex-col justify-center xs:pb-32 xs:pt-10 sm:pt-0 sm:pb-0`;

export const Shape = styled.img`
  ${tw`absolute w-full left-0 top-20 -z-10 pointer-events-none`};
`;

export const AvatarWrapper = styled.div(
  ({ hasKeyboard }: { hasKeyboard: boolean }) => [
    tw`absolute bottom-[3rem] w-[114%] max-w-[28rem]
     left-1/2 -translate-x-1/2`,
    hasKeyboard && tw`-bottom-60 `,
  ],
);

export const Avatar = tw.img`
  w-full h-[86vh]  object-contain
`;

export const Name = tw.div`
  absolute left-1/2 -translate-x-[56%] top-[50%] w-[70%]
`;

//WELCOME PAGE
export const WelcomeWrapper = tw.div`
relative w-full max-w-screen-md  self-stretch bg-yellow
flex flex-col justify-end  items-center 
pb-[10rem] pt-64
`;

export const Content = tw.div`
  flex flex-col justify-center items-center text-center 
  px-5 pt-4 pb-12 
`;
