import {
  AbsoluteSpeechBox,
  Animation,
  Avatar,
  BackgroundShape,
  ContentContainer,
  WrapperContainer,
} from './ContentWrapper.styles';
import { WrapperProps } from './ContentWrapper.types';
import QuizShape from 'assets/icons/QuizShape.svg';
import Paragraph from 'components/Typography/Paragraph';
import useKeyboard from 'store/useKeyboard';

const ContentWrapper = ({
  children,
  avatar,
  hasSpeechBox,
  text,
  ...rest
}: WrapperProps): JSX.Element => {
  const { hasKeyboard } = useKeyboard();
  return (
    <Animation>
      <WrapperContainer
        data-testid="content-wrapper"
        {...rest}
        hasKeyboard={hasKeyboard}
      >
        {avatar && <Avatar hasSpeechBox={hasSpeechBox} src={avatar} />}
        {hasSpeechBox && (
          <AbsoluteSpeechBox
            cornPosition="left"
            translateCorn="-20"
            hasLightBorder={true}
            width="90%"
            height="11rem"
          >
            <Paragraph className="py-4 px-3 h-full overflow-y-auto flex justify-center items-center text-left">
              {text}
            </Paragraph>
          </AbsoluteSpeechBox>
        )}
        <BackgroundShape src={QuizShape} alt="shape" />
        <ContentContainer>{children}</ContentContainer>
      </WrapperContainer>
    </Animation>
  );
};

export default ContentWrapper;
