import { Circle, ProfilePic, Wrapper } from './ProfileAvatar.styles';
import { ProfileAvatarProps } from './ProfileAvatar.types';

const ProfileAvatar = ({
  avatarSrc,
  bgColor,
  shadowColor = '#FDC800',
  ...rest
}: ProfileAvatarProps) => {
  return (
    <Wrapper {...rest}>
      <Circle bgColor={bgColor} shadowColor={shadowColor} />
      <ProfilePic src={avatarSrc} alt="profile picture" />
    </Wrapper>
  );
};

export default ProfileAvatar;
