import Title from 'components/Typography/Title';
import { TotalContent, TotalImage, Wrapper } from './TotalResultItem.styles';
import Correct from 'assets/icons/Correct.png';
import Wrong from 'assets/icons/Wrong.png';
import { TotalProps } from './TotalResultItem.types';
import { useTranslation } from 'react-i18next';
import Label from 'components/Typography/Label';

const TotalResultItem = ({ status, totalAnswers }: TotalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Wrapper data-testid="TotalResultItem">
      <TotalImage src={status === 'correct' ? Correct : Wrong} alt="status" />
      <TotalContent>
        <Label>
          {status === 'correct' ? t('correctAnswers') : t('wrongAnswers')}
        </Label>
        <Title
          level={1}
          renderAs="label"
          color={status === 'correct' ? '#52D502' : '#FE4A4F'}
        >
          {totalAnswers}
        </Title>
      </TotalContent>
    </Wrapper>
  );
};

export default TotalResultItem;
