import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface MissionState {
  onGoing: boolean;
  mission: any | null;
  missionType: string | null;
  selectedQuest: any | null;
  object: any | null;
  disableMarkerState: boolean;
  refresh: boolean;
  questBadge: boolean;
  missionTimeLeft: any | null;
  setMission: (mission: any) => void;
  toggleMission: (onGoing: boolean) => void;
  setMissionType: (missionType: string | null) => void;
  setSelectedQuest: (selectedQuest: any) => void;
  setObject: (object: any) => void;
  setDisableMarkerState: (disabled: boolean) => void;
  setRefresh: (disabled: boolean) => void;
  setMissionTimeLeft: (missionTimeLeft: any) => void;
  setQuestBadge: (questBadge: boolean) => void;
}

const useMission = create<MissionState>()(
  persist(
    (set) => ({
      onGoing: false,
      mission: null,
      missionType: null,
      selectedQuest: null,
      object: null,
      disableMarkerState: false,
      refresh: false,
      questBadge: false,
      missionTimeLeft: null,
      setMissionTimeLeft: (missionTimeLeft) => set({ missionTimeLeft }),
      setMission: (mission) =>
        set((state) => ({
          ...state,
          mission,
        })),
      toggleMission: (onGoing: boolean) =>
        set((state) => ({
          ...state,
          onGoing,
        })),
      setMissionType: (missionType: string | null) =>
        set((state) => ({
          ...state,
          missionType,
        })),
      setSelectedQuest: (selectedQuest: any) =>
        set((state) => ({
          ...state,
          selectedQuest,
        })),
      setObject: (object: any) =>
        set((state) => ({
          ...state,
          object,
        })),
      setDisableMarkerState: (disabled: boolean) =>
        set((state) => ({
          ...state,
          disableMarkerState: disabled,
        })),
      setRefresh: (disabled: boolean) =>
        set((state) => ({
          ...state,
          refresh: disabled,
        })),
      setQuestBadge: (questBadge: boolean) =>
        set((state) => ({
          ...state,
          questBadge,
        })),
    }),
    {
      name: 'mission',
    },
  ),
);

export default useMission;
