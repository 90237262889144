import Avatar from 'components/Avatar';
import styled, { keyframes } from 'styled-components';
import tw from 'twin.macro';

//Map styles
const pulseAnimation = keyframes`
  0% { scale: 0.9; opacity: 0.7 }
  100% {scale: 1.5; opacity: 0.5}
`;
export const StyledMarker = styled.div<{
  isActive: boolean;
  isDisabled: boolean;
}>`
  ${tw`relative`};
  &:after,
  &:before {
    ${({ isActive }) => isActive && tw`[content: '']`}
    ${tw`absolute inset-0 rounded-full bg-white/70`}
  }

  &:after {
    z-index: -10;
    animation-name: ${pulseAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  // check if is disabled
  ${({ isDisabled }) =>
    isDisabled && tw`pointer-events-none grayscale text-grey`}
`;

//NESTED ROUTES PAGES
export const PagesWrapper = tw.div`
  absolute top-0 w-full min-h-screen z-40
  flex items-end pt-[16rem]
`;

//missions page styles
export const MissionWrapper = tw.div`
  absolute inset-0  
`;

export const MissionAvatar = styled(Avatar)`
  ${tw`absolute bottom-[14%] left-4`}
`;

export const QuestButton = tw.div`absolute bottom-[14%] right-0 z-20`;

//Notificationq page
export const NotificationWrapper = tw.div`
  absolute top-0 min-h-screen w-full flex z-40
  justify-center items-end pb-40
`;

//Find place pages
export const FindPlaceWrapper = tw.div`
  absolute top-0 min-h-screen w-full flex z-40
  justify-center items-end [&>div]:mb-32
`;

export const Clue = tw.div`
  w-full flex flex-col px-6 pt-2 pb-14 gap-2
`;

export const Coins = tw.span`
   self-center flex justify-center items-center gap-1 text-3xl font-bold
`;

export const CoinsImage = tw.img`w-[4.75rem] h-[2.563rem] object-contain`;

//speech pages
export const GuideWrapper = tw.div`
   absolute top-0 w-full min-h-screen z-40 flex
   items-end pb-[calc(6rem + 5vh)] pt-[calc(16rem + 3vh)]
`;

export const GuidesWrapper = styled(GuideWrapper)`
  ${tw`pt-[calc(12rem + 3vh)]`}
`;

export const GuideCTAWrapper = tw.div`
  absolute top-0 w-full min-h-screen z-40
  flex flex-col justify-end gap-10 pt-[calc(14rem + 3vh)]
`;

export const NotifContent = styled.div`
  ${tw`flex flex-col p-4 pb-14 gap-1`}
  span {
    ${tw`text-red`}
  }
`;
