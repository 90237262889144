import { Player } from '@lottiefiles/react-lottie-player';
import { LottieProps } from './Lottie.type';

export default function Empty({
  className,
  autoplay = true,
  loop = true,
  speed = 1.5,
  height = '200px',
  width = '200px',
}: LottieProps) {
  return (
    <Player
      className={className}
      autoplay={autoplay}
      speed={speed}
      loop={loop}
      src="https://assets3.lottiefiles.com/private_files/lf30_e3pteeho.json"
      style={{ height: height, width: width }}
    />
  );
}
