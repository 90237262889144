// Importing styles and components
import React, { useState, ChangeEvent, MouseEvent } from 'react';
import {
  QcmQuizAction,
  QcmQuizContainer,
  QcmQuizItems,
  QcmQuizItemSkip,
} from './QcmQuiz.styles';
import QcmQuizItem from '../QcmQuizItem';
import Button from 'components/Button';
import Paragraph from 'components/Typography/Paragraph';
import ContentWrapper from 'components/ContentWrapper';
import { QcmQuizItemType, QcmQuizProps } from './QcmQuiz.type';
import ArrowsRight from 'Icons/ArrowsRight';
import Modal from 'components/Modal';
import QcmQuizPopup from '../QcmQuizPopup';
import CorrectIcon from 'assets/icons/Correct.png';
import WrongIcon from 'assets/icons/Wrong.png';
import { useTranslation } from 'react-i18next';
import { ClientJS } from 'clientjs';
import Arrows from 'components/LottieFiles/Arrows';

// Main QcmQuiz component
const QcmQuiz: React.FC<QcmQuizProps> = ({
  question,
  avatar,
  items,
  correctAnswers,
  onSkip,
  onCorrect,
  onWrong,
  showCorrectAnswers = false,
}: QcmQuizProps) => {
  // Create a new ClientJS object
  const client = new ClientJS();
  // get device resolution
  const deviceResolution = client.getCurrentResolution().split('x');

  // Translation hook
  const { t } = useTranslation();

  // State hooks
  const [data, setData] = useState<QcmQuizItemType[]>(items);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [quizState, setQuizState] = useState<boolean>(false);
  const [retry, setRetry] = useState<number>(1);

  // Handle change of checked items
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    const newData = data.map((item: QcmQuizItemType, i: number) => {
      if (i === index) {
        setDisabledBtn(false);
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setData(newData);
  };

  // Handle wrong answers
  const handleWrongAnswers = (): void => {
    onWrong && onWrong();
    onClose();
  };

  // Check correct answers
  const checkCorrectAnswers = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    let newData: QcmQuizItemType[] = [];
    const checkedAnswers = data.filter((item) => item.checked === true);
    const checkedAnswersTitles = checkedAnswers.map((item) => item.title);
    const isCorrect = checkedAnswersTitles.every((item) =>
      correctAnswers.includes(item),
    );

    if (showCorrectAnswers) {
      newData = data.map((item) => {
        if (correctAnswers.includes(item.title)) {
          return { ...item, correct: true };
        }
        if (checkedAnswersTitles.includes(item.title)) {
          return { ...item, correct: false };
        }
        return item;
      });
    }

    newData.length > 0 && setData(newData);
    setQuizState(isCorrect);
    setShowModal(true);
  };

  // Close modal
  const onClose = (): void => {
    setShowModal(false);
  };

  // Handle wrong answer
  const handleWrongAnswer = (): void => {
    // Retry 2 times and show correct answer
    if (retry < 2) {
      setRetry(retry + 1);
      setShowModal(false);
      setQuizState(false);
    }
    if (retry === 2) {
      setQuizState(true);
      handleWrongAnswers();
    }
  };

  // Handle correct answer
  const handleCorrectAnswer = (): void => {
    onCorrect && onCorrect();
    onClose();
  };

  return (
    <ContentWrapper avatar={avatar} hasSpeechBox={true} text={question}>
      <QcmQuizContainer data-testid="QcmQuiz">
        {deviceResolution[0] <= 375 && (
          <Arrows className="absolute -top-5 -translate-x-1/2" />
        )}
        <QcmQuizItems>
          {data.map(
            (item: QcmQuizItemType, index) =>
              item.title && (
                <QcmQuizItem
                  key={item.title}
                  {...item}
                  onChange={(e) => handleChange(e, index)}
                />
              ),
          )}
        </QcmQuizItems>
        <QcmQuizAction>
          <Button
            rotation="-3.21deg"
            shadowColor="black"
            width="15rem"
            onClick={checkCorrectAnswers}
            disabled={disabledBtn}
          >
            {t('continue')}
          </Button>
          <QcmQuizItemSkip onClick={onSkip}>
            <Paragraph>{t('skip')}</Paragraph>
            <ArrowsRight />
          </QcmQuizItemSkip>
        </QcmQuizAction>
      </QcmQuizContainer>
      <Modal isOpen={showModal} closeModal={onClose}>
        <QcmQuizPopup
          failed={retry === 2}
          title={quizState ? t('goodAnswers') : t('wrongAnswers')}
          status={quizState ? 'correct' : 'wrong'}
          image={quizState ? CorrectIcon : WrongIcon}
          onClose={onClose}
          description={retry === 2 ? correctAnswers?.toString() : ''}
          onButtonClick={quizState ? handleCorrectAnswer : handleWrongAnswer}
        />
      </Modal>
    </ContentWrapper>
  );
};

export default QcmQuiz;
