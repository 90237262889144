import React, { FormEvent } from 'react';
import ProfileCard from 'components/ProfileCard';
import { user } from './data'; // Assuming UserData is a type for the user data

// LoggedIn component for displaying the profile card of a logged-in user
const LoggedIn: React.FC = () => {
  // handleSubmit function to handle form submission
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add any additional logic for form submission if needed
  };

  // Assuming 'user' is imported from 'data'
  // Pass user data and handleSubmit function as props to the ProfileCard component
  return <ProfileCard {...user} handleSubmit={handleSubmit} />;
};

export default LoggedIn;
