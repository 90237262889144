import Button from 'components/Button';
import ChevronDown from 'Icons/ChevronDown';
import StateItem from '../StateItem';
import StateWrapper from '../StateWrapper';
import { StateContainer } from './State.styles';
import { StateProps } from './State.types';
import { useTranslation } from 'react-i18next';
import { handleCoins } from 'helpers/MissionsHelper';

const State = ({
  ongoingMission,
  missionName,
  finishedMissions,
  coinsEarned,
  onClick,
  ...rest
}: StateProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StateWrapper
      width={ongoingMission ? '18.938rem' : '13.438rem'}
      height="5.25rem"
      {...rest}
    >
      <StateContainer>
        {ongoingMission ? (
          <>
            <StateItem
              label={t('ongoingMission')}
              value={missionName || ''}
              iconName="mission"
            />
            <StateItem
              label={t('finishedMission')}
              value={finishedMissions || ''}
              iconName="level"
            />
          </>
        ) : (
          <>
            <StateItem
              label={t('finishedMission')}
              value={finishedMissions || ''}
              iconName="level"
            />
            <StateItem
              label={t('coinsEarned')}
              value={(coinsEarned && handleCoins(coinsEarned)) || '0'}
              iconName="coins"
            />
          </>
        )}

        <Button
          className="open-dropdown-button outline-none"
          afterBg="#FE4A4F"
          bgColor="#EEEEEE"
          hasAfter
          isRounded
          onClick={onClick}
        >
          <ChevronDown />
        </Button>
      </StateContainer>
    </StateWrapper>
  );
};

export default State;
