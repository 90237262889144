import Button from 'components/Button';
import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledImageProps } from './SpeechSwiper.types';

export const Wrapper = tw.div`
   w-[86%] relative mx-auto max-w-[23.375rem]  animate-fadeFromTop
`;

export const PrevButton = styled(Button)`
  ${tw`absolute z-10 bottom-0 left-[4%] translate-y-[50%]`};
`;

export const NextButton = styled(Button)`
  ${tw`absolute z-10 bottom-0 right-[4%] translate-y-[50%]`};
`;

export const StyledImage = styled.img<StyledImageProps>(
  ({ imagePosition, isActive, isConversation }) => [
    tw`absolute -z-20 top-0 w-[64%] h-full object-contain  transition-all duration-700 
    translate-y-[-80%] animate-enterLeftBottom origin-bottom-left `,
    imagePosition === 'center' &&
      tw`left-[50%] translate-x-[-48%] top-28 w-[90%] animate-enterLeftBottomToCenter origin-left`,
    imagePosition === 'left' && tw`left-[4%]`,
    imagePosition === 'right' && tw`right-[-3%]`,
    isConversation && tw`translate-y-[-74%] w-[60%] animate-none`,
    !isActive && tw`grayscale scale-[0.9] -z-30 `,
  ],
);
