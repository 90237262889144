import styled from 'styled-components';
import tw from 'twin.macro';
import { NameProps } from './SwiperContent.types';

export const Wrapper = tw.div`
  h-full flex flex-col justify-center items-start gap-2 sm:gap-4 px-4 py-4 sm:py-8 [&>button]:mb-6 sm:[&>button]:mb-3 [&>button]:self-center
  [&>p]:pb-5
`;

export const Name = styled.span<NameProps>`
  color: ${({ color }) => color};
`;

export const Start = tw.div`
   flex justify-center items-center gap-1 
`;
