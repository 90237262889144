import tw from 'twin.macro';
import styled from 'styled-components';
import Button from 'components/Button';

export const QcmQuizPopupContainer = tw.div`relative w-[90%] min-w-[10.813rem] max-w-[23.375rem]`;

export const QcmQuizPopupContent = styled.div`
  ${tw`w-full h-full flex flex-col justify-start items-center gap-2 p-4`};
`;

export const QcmQuizPopupImage = tw.img`w-[4.75rem] h-[4.75rem] rounded-full object-cover`;

export const QcmQuizPopupDescription = tw.span`w-full flex items-center justify-center gap-1.5`;
export const QcmQuizPopupTitle = tw.p`text-sm font-medium text-center text-success`;

export const CloseButton = styled(Button)`
  ${tw`absolute -top-5 -right-4 z-10`}
`;

export const RetryButton = styled(Button)`
  ${tw`absolute bottom-0 left-auto right-auto translate-y-1/2`}
`;
