import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledObjectsCardProps } from './ObjectsCard.types';

export const Wrapper = styled.div<StyledObjectsCardProps>`
  ${tw`relative w-full max-w-sm h-32 border-2 border-darkGray rounded-2xl
       flex flex-col items-start justify-start p-4 `}
  background-color: ${({ bgColor }) => bgColor}
`;

export const Top = tw.div`
   w-full flex justify-between items-center  text-lg leading-[1.438rem] capitalize font-bold tracking-tighter
`;

export const Items = tw.div`
 flex justify-center items-center gap-3 
 absolute left-3 right-3 bottom-0 translate-y-1/2
`;
