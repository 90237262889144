import { Route, Routes } from 'react-router-dom';
import ChooseName from './ChooseName';
import ChoosePa from './ChoosePa';
import Welcome from './Welcome';
import ChooseLanguage from './ChooseLanguage';
import ChooseArea from './ChooseAreaPage';

const StartRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ChooseLanguage />} />
      <Route path="choose-pa" element={<ChoosePa />} />
      <Route path="choose-name" element={<ChooseName />} />
      <Route path="welcome" element={<Welcome />} />
      <Route path="choose-area" element={<ChooseArea />} />
      <Route path="*" element={<ChooseLanguage />} />
    </Routes>
  );
};

export default StartRoutes;
