import SpeechBox from 'components/SpeechBox';
import styled, { keyframes } from 'styled-components';
import tw from 'twin.macro';
import { StyleProps } from './ContentWrapper.types';

const bounceInUp = keyframes`
  0% { transform: translateY(100%); opacity: 0;}
  30%  {opacity: 1}
  80% { transform: translateY(-20px) ; }
  100% { transform: translateY(0) ; }
`;

export const Animation = styled.div`
  ${tw`w-full`}
  animation: ${bounceInUp} 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s backwards;
`;
export const WrapperContainer = styled.div<StyleProps>`
  ${tw`relative w-full border-t-2 rounded-t-2xl bg-softGray 
       flex justify-center items-start `};

  height: ${({ height }) => height};
  padding-bottom: ${({ hasKeyboard }) => (hasKeyboard ? '0px' : '7rem')};
`;

export const BackgroundShape = tw.img`
   absolute w-full h-full top-0 left-0  object-cover 
`;

export const ContentContainer = styled.div`
  ${tw`w-full max-w-[26.25rem] z-30 `}
`;

export const Avatar = styled.img(
  ({ hasSpeechBox }: { hasSpeechBox: boolean }) => [
    tw`absolute  -top-[14rem] -z-10 w-[15.375rem]`,
    hasSpeechBox ? tw`left-0` : tw` left-1/2 -translate-x-1/2`,
  ],
);

export const AbsoluteSpeechBox = styled(SpeechBox)`
  ${tw`absolute left-[14rem] -top-[12rem]`}
`;
