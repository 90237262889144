import Modal from 'components/Modal';
import TalkingPA from 'components/TalkingPA';
import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePersonalAssistant from 'store/usePersonalAssistant';
import { Clue, Coins, CoinsImage, PagesWrapper } from './Map.styles';
import coinsIcon from 'assets/icons/coins/coins.png';
import FindPlaceQuote from 'components/FindPlace/FindPlaceQuote';
import Onboarding from 'components/Onboarding';
import { Step } from 'react-joyride';
const steps: Step[] = [
  {
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    target: '.find-qoute-button',
    placement: 'top',
    disableScrolling: true,
    disableBeacon: true,
    disableOverlayClose: true,
  },
];
const FindQuote = () => {
  const [isOpen, setIsOpen] = useState(false);
  const pa = usePersonalAssistant((state) => state.pa);

  const closeModal = () => {
    setIsOpen(false);
  };
  const exchange = () => {};
  const { t } = useTranslation();
  //api data
  const quote = 'The place where the mediterranean meets the atlantic';
  const coins = 25;
  return (
    <>
      {/* <Onboarding steps={steps} page="find-quote" /> */}
      <PagesWrapper>
        <FindPlaceQuote
          avatar={pa?.avatar}
          quote={quote}
          //JUST AN EXAMPLE TO OPEN THE MODAL FOR NOW
          onClick={() => setIsOpen(true)}
        />
        <Modal isOpen={isOpen} closeModal={closeModal}>
          <TalkingPA
            onClick={exchange}
            avatar={pa?.avatar}
            isTall={false}
            label={t('exchange')}
          >
            <Clue>
              <Title renderAs="h1" level={1}>
                {t('needClue')}
              </Title>
              <Paragraph>{t('buy clue')}</Paragraph>
              <Coins>
                {coins}
                <CoinsImage src={coinsIcon} alt="coins-icon" />
              </Coins>
            </Clue>
          </TalkingPA>
        </Modal>
      </PagesWrapper>
    </>
  );
};

export default FindQuote;
