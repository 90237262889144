import { WrapperBoxProps } from './WrapperBox.types';
import { Wrapper, WrapperBoxContainer } from './WrapperBox.styles';

const WrapperBox = ({ children, ...rest }: WrapperBoxProps): JSX.Element => {
  return (
    <Wrapper {...rest}>
      <WrapperBoxContainer {...rest} data-testid="wrapper-box">
        {children}
      </WrapperBoxContainer>
    </Wrapper>
  );
};

export default WrapperBox;
