import Modal from 'components/Modal';
import Title from 'components/Typography/Title';
import ChevronRight from 'Icons/ChevronRight';
import EditIcon from 'Icons/EditIcon';
// import Phone from 'Icons/Phone';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switcher from './Switcher';
import { SettingItems, Wrapper } from './SettingsCard.styles';
import { SettingsCardProps } from './SettingsCard.types';
import SettingsItem from './SettingsItem';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import useUser from 'store/useUser';

export default function SettingsCard({
  bgColor = '#FBF3D8',
  handleDownloadApp,
  handleEmrgencyCall,
  handleToggleNotifications,
}: SettingsCardProps) {
  const [playClick] = useSound(ClickSound);
  const { t } = useTranslation();
  //open and close the language switcher modal
  const [isOpen, setIsOpen] = useState(false);
  const { activeNotification } = useUser();

  const openModal = () => {
    playClick();
    setIsOpen(true);
  };
  const closeModal = () => {
    playClick();
    setIsOpen(false);
  };

  return (
    <Wrapper bgColor={bgColor}>
      <Title renderAs="h2" level={2}>
        {t('generaleSettings')}
      </Title>
      <SettingItems>
        <SettingsItem
          Icon={EditIcon}
          itemName={t('changeLanguage')}
          handleClick={openModal}
          isToggle={false}
        />
        {/* <SettingsItem
          Icon={ChevronRight}
          itemName={t('downloadApp')}
          handleClick={handleDownloadApp}
          isToggle={false}
        />
        <SettingsItem
          isToggle={true}
          itemName={t('activateNotif')}
          handleClick={handleToggleNotifications}
          enabled={activeNotification}
        /> */}
        {/* <SettingsItem
          Icon={Phone}
          itemName={t('emergency')}
          handleClick={handleEmrgencyCall}
          isToggle={false}
        /> */}
      </SettingItems>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <Switcher />
      </Modal>
    </Wrapper>
  );
}
