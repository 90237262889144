export const API_BASE_URL = 'https://casagames.fly.dev/v1/';
export const API_CATEGORY = API_BASE_URL + 'category';
export const API_DOCUMENTS = API_BASE_URL + 'document';
export const API_AVATARS = API_BASE_URL + 'avatar';
export const API_BADGES = API_BASE_URL + 'badge';
export const API_OBJECT = API_BASE_URL + 'object';
export const API_ENIGMAS = API_BASE_URL + 'enigma';
export const API_PUZZLES = API_BASE_URL + 'puzzle';
export const API_QUIZZES = API_BASE_URL + 'quiz';
export const API_MISSIONS = API_BASE_URL + 'mission';
export const API_QUESTS = API_BASE_URL + 'quest';
export const API_USERS = API_BASE_URL + 'user';
export const API_REDEEM = API_BASE_URL + 'product';
export const API_COUPON = API_BASE_URL + 'coupon';
export const API_AREAS = API_BASE_URL + 'area';
