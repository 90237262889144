import Button from 'components/Button';
import Paragraph from 'components/Typography/Paragraph';
import Title from 'components/Typography/Title';
import ChevronRight from 'Icons/ArrowRight';
import { theme } from 'twin.macro';
import { Name, Start, Wrapper } from './SwiperContent.styles';
import { SwiperContentProps } from './SwiperContent.types';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'components/LottieFiles/LoadingButton';
import { useState } from 'react';

const ContentSwiper = ({
  type,
  name,
  text,
  isConversation,
  role,
  paName,
  paColor,
  objectName,
  objectColor,
  onStart,
  buttonText,
}: SwiperContentProps): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Wrapper>
      {name && !isConversation && (
        <Title level={2} renderAs="h2">
          {t('hey') + ' ' + name}
        </Title>
      )}
      <Paragraph>
        {isConversation ? (
          <Name color={role === 'pa' ? paColor : objectColor}>
            {role === 'pa' ? paName : objectName}:
          </Name>
        ) : null}
        {text}
      </Paragraph>
      {onStart && (
        <Button
          width="80%"
          hasAfter={false}
          isRounded={false}
          rotation="-2.36deg"
          color={theme`colors.softGray`}
          shadowColor="black"
          onClick={() => {
            setLoading(true);
            onStart();
          }}
        >
          {loading ? (
            <LoadingButton />
          ) : (
            <Start>
              {buttonText ? buttonText : <p>{t('startmission')}</p>}
              {!buttonText && <ChevronRight />}
            </Start>
          )}
        </Button>
      )}
    </Wrapper>
  );
};

export default ContentSwiper;
