import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, EffectFade, Navigation, Pagination } from 'swiper';
//styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { SpeechSwiperProps } from './SpeechSwiper.types';
import {
  NextButton,
  PrevButton,
  StyledImage,
  Wrapper,
} from './SpeechSwiper.styles';
import { theme } from 'twin.macro';
import ChevronLeft from 'Icons/ChevronLeft';
import SpeechBox from 'components/SpeechBox';
import ChevronRight from 'Icons/ChevronRight';
import ContentSwiper from './SwiperContent';
import { CloseButton } from 'components/MarkerPopup/MarkerPopup.styles';
import XMark from 'Icons/XMark';
import ClickSound from 'assets/sounds/Tap.wav';
import useSound from 'use-sound';

function SpeechSwiper({
  data,
  isConversation,
  name,
  paImage,
  objectImage,
  paPosition,
  type,
  paName,
  paColor,
  objectName,
  objectColor,
  onStart,
  onClose,
  buttonText,
  ...rest
}: SpeechSwiperProps) {
  const [playClick] = useSound(ClickSound);
  const [activeRole, setActiveRole] = useState(
    isConversation ? data[0].role : 'pa',
  );

  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  //we need the object to be on the contrary side of the PA
  const getObjectPosition = () => {
    switch (paPosition) {
      case 'left':
        return 'right';
      case 'right':
        return 'left';
      default:
        return 'left';
    }
  };
  //The corn position should always follow the active role
  const getCornPosition = () => {
    let position;
    if (activeRole === 'pa') {
      position = paPosition;
    } else {
      position = getObjectPosition();
    }

    switch (position) {
      case 'left':
        return '-60%';
      case 'center':
        return '';
      case 'right':
        return '60%';
      default:
        return '60%';
    }
  };

  const getShodowColor = (): string => {
    let color = theme`colors.yellow`;
    if (isConversation) {
      if (activeRole === 'pa') color = paColor;
      if (activeRole === 'object') color = objectColor;
    }
    return color;
  };

  const handleRoleChange = () => {
    if (swiper && isConversation) {
      const activeIndex = swiper.realIndex;
      const activeSide = swiper.slides[activeIndex];
      if (activeSide.id === 'pa' || activeSide.id === 'object')
        setActiveRole(activeSide.id);
    }
  };

  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  return (
    <Wrapper {...rest}>
      <SpeechBox
        translateCorn={getCornPosition()}
        hasLightBorder={false}
        width="100%"
        cornPosition="top"
        afterBg={getShodowColor()}
      >
        <CloseButton
          bgColor={theme`colors.softGray`}
          hasAfter={true}
          afterBg={theme`colors.red`}
          isRounded={true}
          onClick={() => {
            playClick();
            onClose();
          }}
          aria-label="close-marker-popup"
        >
          <XMark />
        </CloseButton>
        <Swiper
          speed={1000}
          slidesPerView={1}
          pagination={true}
          modules={[Pagination, A11y, Navigation]}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSwiper={(swiperCore) => setSwiper(swiperCore)}
          onSlideChange={handleRoleChange}
          className="mySwiperI"
        >
          {isConversation
            ? Array.isArray(data) &&
              data.length > 0 &&
              data.map((item: any, index) => (
                <SwiperSlide id={item.role} key={index} className="!h-fit">
                  <ContentSwiper
                    role={item.role}
                    name={index === 0 ? name : ''}
                    text={item.text}
                    isConversation={true}
                    type={type}
                    onStart={index === data.length - 1 ? onStart : undefined}
                    paName={paName}
                    paColor={paColor}
                    objectName={objectName}
                    objectColor={objectColor}
                    buttonText={buttonText}
                  />
                </SwiperSlide>
              ))
            : Array.isArray(data)
            ? data.map((item, index) => (
                <SwiperSlide id="pa" key={index}>
                  <ContentSwiper
                    name={index === 0 ? name : ''}
                    text={item}
                    isConversation={false}
                    type={type}
                    buttonText={buttonText}
                    onStart={index === data.length - 1 ? onStart : undefined}
                  />
                </SwiperSlide>
              ))
            : ''}
        </Swiper>
      </SpeechBox>
      <StyledImage
        isActive={activeRole === 'pa'}
        src={paImage}
        alt={paName}
        imagePosition={paPosition}
        isConversation={isConversation}
      />
      {isConversation && objectImage && (
        <StyledImage
          isActive={activeRole === 'object'}
          src={objectImage}
          alt={objectName}
          imagePosition={getObjectPosition()}
          isConversation={isConversation}
        />
      )}
      {data.length > 1 && (
        <>
          <PrevButton
            ref={prevRef}
            hasAfter={true}
            bgColor={theme`colors.lightBlue`}
            afterBg={theme`colors.blue`}
            isRounded={false}
          >
            <ChevronLeft />
          </PrevButton>
          <NextButton
            ref={nextRef}
            hasAfter={true}
            bgColor={theme`colors.lightBlue`}
            afterBg={theme`colors.blue`}
            isRounded={false}
          >
            <ChevronRight />
          </NextButton>
        </>
      )}
    </Wrapper>
  );
}

export default SpeechSwiper;
