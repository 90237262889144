import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Content,
  Needle,
  PrizeIcon,
  WheelImages,
  WheelImg,
  WheelLight,
  WheelShadow,
  WheelWrapper,
} from './Wheel.styles';
import spinImage from 'assets/wheel/spin.png';
import needle from 'assets/wheel/needle.png';
import light from 'assets/wheel/lighting.png';
import shadow from 'assets/wheel/shadow.png';
// with noName
import Smile from 'assets/wheel/smile.png';

// en version
import NextTime_en from 'assets/wheel/en/next_time.png';

// fr version
import NextTime_fr from 'assets/wheel/fr/next_time.png';

// es version
import NextTime_es from 'assets/wheel/es/next_time.png';

import {
  PrizeObjectTypes,
  PrizesByOrderTypes,
  WheelProps,
} from './Wheel.types';
import Modal from 'components/Modal';
import WinningPopup from 'components/WinningPopup';
import ContentWrapper from 'components/ContentWrapper';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { WinningLottie } from 'components/LottieFiles';
import useSound from 'use-sound';
import collectCoins from 'assets/sounds/collectCoins.wav';
import WheelSound from 'assets/sounds/wheel.wav';
import useRewards from 'store/useRewards';
import useWheel from 'store/useWheel';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18n';
import usePersonalAssistant from 'store/usePersonalAssistant';

//array of the circle angles

const prizesByOrder: PrizesByOrderTypes = {
  '1': 'next_time',
  '2': '30',
  '3': 'lucky_badge',
  '4': '100',
  '5': 'spin',
  '6': '10',
  '7': 'expert_badge',
  '8': '50',
};
const Wheel = ({ ...rest }: WheelProps) => {
  const [playCollect] = useSound(collectCoins);
  const [playWheel] = useSound(WheelSound);
  const { t } = useTranslation();
  const { pa } = usePersonalAssistant();
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [prize, setPrize] = useState('-');
  const [deg, setDeg] = useState(0);
  const { coins, badges, setCoins, setBadges } = useRewards();
  const { icons, setIcons, access, setAccess, count, setCount } = useWheel();
  const segementSize = 360 / 8; //deg
  const navigate = useNavigate();

  const wheelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!access) navigate('/map');
    // eslint-disable-next-line
  }, [access]);

  const getPrizeObject = (prize: string): PrizeObjectTypes => {
    const prizeObject = icons.find((item) => item.prize === prize);
    if (prizeObject) {
      return prizeObject;
    } else {
      return icons[0];
    }
  };

  const getPrize = useCallback(
    (prizeDeg: number) => {
      const prizeKey = Math.ceil(prizeDeg / segementSize)?.toString();
      const prize = prizesByOrder[prizeKey];
      return prize;
    },
    [segementSize],
  );

  const replaceWonPrize = (prizeDeg: number, prize: string) => {
    const prizeKey = Math.ceil(prizeDeg / segementSize)?.toString();
    if (prize !== 'next_time') {
      prizesByOrder[prizeKey] = 'next_time';
      // change iconbylng attr on  icons
      const iconsByLng = icons.map((icon) => {
        if (icon.prize === prize) {
          icon.icon = Smile;
          icon.type = 'noChance';
          icon.iconbylng = {
            en: NextTime_en,
            fr: NextTime_fr,
            es: NextTime_es,
          };
        }
        return icon;
      });
      setIcons(iconsByLng);

      // setIcons(icons);
    }
  };

  const collectRewards = () => {
    playCollect();
    setIsOpen(false);
    // get item by prize
    const prizeObject: any = getPrizeObject(prize);
    if (prizeObject.type === 'coins') {
      setCoins(coins + +prizeObject.prize);
      setAccess(false);
    }
    if (prizeObject.type === 'badge') {
      const badgeExists = badges.find(
        (badge: any) => badge?._id === prizeObject?._id,
      );
      if (!badgeExists) {
        const newBadges: any = [...badges, prizeObject];
        setBadges(newBadges);
      }
      setAccess(false);
    }
    if (prizeObject.type === 'noChance') {
      setAccess(false);
    }
    if (prizeObject.type !== 'noChance') {
      setCount(count + 1);
    }
    replaceWonPrize(deg, prize);
  };
  const spin = () => {
    if (wheelRef && wheelRef.current) {
      playWheel();
      const wheel = wheelRef.current;
      setDisabled(true);
      //calculate rotation  degree between the min and max, and animate to it
      let newDeg = Math.floor(600 + Math.random() * 1500);
      setDeg(newDeg);
      wheel.style.transition = 'all 5s ease-out';
      wheel.style.transform = `rotate(${newDeg}deg)`;
    }
  };

  useEffect(() => {
    let wheel: HTMLDivElement | null = null;
    if (wheelRef && wheelRef.current) {
      wheel = wheelRef.current;
    }
    const handleSpinFinished = () => {
      if (wheel) {
        const currentdeg = deg % 360;
        wheel.style.transition = 'none';
        wheel.style.transform = `rotate(${currentdeg}deg)`;
        const prize = getPrize(currentdeg);
        setDeg(currentdeg);
        setPrize(prize);
        setTimeout(() => {
          setIsOpen(true);
        }, 1000);
        setDisabled(false);
      }
    };
    wheel?.addEventListener('transitionend', handleSpinFinished);

    return () => {
      wheel?.removeEventListener('transitionend', handleSpinFinished);
    };
  }, [deg, getPrize]);

  return (
    <ContentWrapper hasSpeechBox={false} avatar={pa?.avatar} {...rest}>
      <Content>
        <WheelWrapper>
          <WheelImages ref={wheelRef}>
            <WheelImg src={spinImage} alt="spin" />
            {icons.map((prize, i) => (
              <PrizeIcon
                key={i}
                src={prize.icon}
                alt={prize.alt}
                angle={prize.angle}
              />
            ))}
          </WheelImages>
          <WheelLight src={light} alt="wheel-light" />
          <WheelShadow src={shadow} alt="wheel-shadow" />
          <Needle src={needle} alt="needle" />
        </WheelWrapper>
        <Button
          width="90%"
          rotation="-1.89deg"
          shadowColor="black"
          disabled={disabled}
          onClick={spin}
        >
          {t('spin')}
        </Button>
      </Content>
      <Modal isOpen={isOpen} closeModal={() => {}}>
        <>
          <WinningPopup
            wheel={true}
            isWon={prize === 'next_time' ? false : true}
            object={true}
            objectImage={getPrizeObject(prize).iconbylng[i18n.language]}
            objectName={''}
            collectRewards={collectRewards}
          />
          {prize !== 'next_time' && (
            <WinningLottie className="absolute -top-[15rem] w-auto" loop />
          )}
        </>
      </Modal>
    </ContentWrapper>
  );
};

export default Wheel;
