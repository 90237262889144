import WrapperBox from 'components/WrapperBox';
import {
  Board,
  PuzzleCardContainer,
  PuzzleCardGame,
  Tile,
} from './PuzzleCard.styles';
import { useEffect, useState } from 'react';
import { PuzzleProps, TileContainerProps } from './PuzzleCard.types';
import GameState from './helper';

const TileContainer = ({ index, pos, onClick, image }: TileContainerProps) => {
  const top = pos[0] * 90 + 5;
  const left = pos[1] * 90 + 5;
  const bgLeft = (index % 3) * 90;
  const bgTop = Math.floor(index / 3) * 90;

  return (
    <Tile
      data-testid="tile"
      image={image}
      onClick={onClick}
      style={{ top, left, backgroundPosition: `-${bgLeft}px -${bgTop}px` }}
    />
  );
};

const PuzzleCard = ({ image, onSolve }: PuzzleProps): JSX.Element => {
  const gameState: any | null = GameState.getInstance();
  const [state, setState] = useState(gameState.getState());

  const move = (index: number) => {
    return () => {
      gameState?.moveTile(index);
      setState(gameState?.getState());
    };
  };

  useEffect(() => {
    if (state.solved) {
      onSolve && onSolve();
      gameState?.startNewGame();
    }
    // eslint-disable-next-line
  }, [state, onSolve]);

  return (
    <WrapperBox
      width="329px"
      height="22.2rem"
      shadowColor="#007AD1"
      borderRadius="31px"
    >
      <PuzzleCardContainer data-testid="puzzle-card">
        <PuzzleCardGame>
          <Board data-testid="puzzle-board">
            {state.board.slice(0, -1).map((pos: [], index: number) => (
              <TileContainer
                key={index + 1}
                index={index}
                pos={pos}
                onClick={move(index)}
                image={image}
              />
            ))}
          </Board>
        </PuzzleCardGame>
      </PuzzleCardContainer>
    </WrapperBox>
  );
};

export default PuzzleCard;
