import useData from 'store/useData';

export const getPabyName = (name: string) => {
  const avatars = useData.getState().avatars;
  if (name === 'PA') {
    return 'PA';
  }
  const avatar: any = avatars?.find(
    (avatar: any) => avatar?.name?.trim() === name?.trim(),
  );

  let location = avatar?.media[0]?.location;
  let searchString = 'https://';
  // check if searchString on location
  if (!location?.includes(searchString)) {
    // replace searchString with empty string
    location = searchString + location;
  }

  return (avatar && location) || '';
};
