import styled from 'styled-components';
import tw from 'twin.macro';
import { QuizBarItemStylesProps } from './QuizBarItem.type';

export const QuizBarItemContainer = styled.div(
  ({ isActive, isCompleted, isLocked }: QuizBarItemStylesProps) => [
    //base styles
    tw`flex flex-col items-center justify-center w-[2.563rem] h-[2.563rem] p-4 px-3 border-2
      border-solid rounded-full text-[1rem] font-medium`,
    //styles for active item
    isActive &&
      tw`w-[11rem] border-darkGray bg-white text-darkGray flex-row justify-between`,
    //styles for completed item
    isCompleted && tw`border-darkGray bg-success text-darkGray`,
    //styles for locked item
    isLocked && tw`border-grey bg-lightGrey text-grey`,
  ],
);

export const QuizBarItemCoin = tw.div`w-[3.313rem] h-[1.688rem] bg-blue rounded-full
flex items-center justify-around p-1.5 text-white gap-1 text-xs font-bold`;

export const QuizBarItemImageCoin = tw.img`w-[0.8rem] h-4`;
