import EditIcon from 'Icons/EditIcon';
import { useRef } from 'react';
import { InputContainer, StyledInput, InputIcon } from './Input.styles';
import { InputProps } from './Input.types';

const Input = ({ edit, ...rest }: InputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <InputContainer>
      <StyledInput ref={inputRef} {...rest} aria-label={rest.placeholder} />
      {edit && (
        <InputIcon data-testid="edit-icon">
          <EditIcon />
        </InputIcon>
      )}
    </InputContainer>
  );
};

export default Input;
