import tw from 'twin.macro';
import styled, { keyframes } from 'styled-components';
import { StyledWrapperBoxProps } from './WrapperBox.types';

const expandDown = keyframes`
   0% { transform: scaleY(0) ; }
  100% { transform: scaleY(1) ; }
`;
export const Wrapper = styled.div<StyledWrapperBoxProps>`
  ${tw`z-10 flex justify-center items-center w-full origin-top`};
  animation: ${expandDown} 0.3s linear 0.5s backwards;
  /* transition: max-height 0.5s ease-out; */
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  rotate: ${({ rotation }) => rotation};
`;

export const WrapperBoxContainer = styled.div<StyledWrapperBoxProps>`
  ${tw`relative min-w-[8rem] max-w-[23.375rem] min-h-[4rem] flex justify-center items-center bg-white  
  border-2 border-black rounded-xl`};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ bgColor }) => bgColor};
  border-color: ${({ borderColor }) => borderColor};
  border-width: ${({ borderWidth }) => borderWidth};
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: ${({ clickable }) => clickable && 'pointer'};

  &:after {
    ${tw`[content: ""] absolute -z-10 inset-0 bg-yellow border-2 border-black rounded-xl 
    translate-x-2.5 translate-y-2.5`};
    background-color: ${({ bgColor, shadowColor }) =>
      shadowColor ? shadowColor : bgColor};
    border-color: ${({ borderColor }) => borderColor};
    border-width: ${({ borderWidth }) => borderWidth};
    border-radius: ${({ borderRadius }) => borderRadius};
    transform: ${({ shadowSpace }) =>
      `translate(${shadowSpace}, ${shadowSpace})`};
  }
`;
