import Modal from 'components/Modal';
import Puzzle from 'components/Quizzes/Puzzle';
import SpeechSwiper from 'components/SpeechSwiper';
import usePersonalAssistant from 'store/usePersonalAssistant';
import { GuideWrapper, PagesWrapper } from './Map.styles';
import { useEffect, useState } from 'react';
import WinningPopup from 'components/WinningPopup';
import useMission from 'store/useMission';
import useUser from 'store/useUser';
import { getSingleDialogue } from 'helpers/MissionsHelper';
import { WinningLottie } from 'components/LottieFiles';
import { useNavigate } from 'react-router-dom';
import {
  finishQuest,
  handleNextMission,
  updateQuest,
  updateSelectedQuest,
} from 'helpers/QuestsHelpers';
import useSound from 'use-sound';
import collectCoins from 'assets/sounds/collectCoins.wav';
import applause from 'assets/sounds/applause.wav';
import ClickSound from 'assets/sounds/Tap.wav';
import { useTranslation } from 'react-i18next';
import useData from 'store/useData';

const PuzzlePage = () => {
  const [playCollect] = useSound(collectCoins);
  const [playapplause] = useSound(applause);
  const [playClick] = useSound(ClickSound);
  const { i18n } = useTranslation();
  // data
  const pa = usePersonalAssistant((state) => state.pa);
  const { setQuest } = useData();
  const {
    mission,
    selectedQuest,
    setMission,
    toggleMission,
    setRefresh,
    setMissionType,
  } = useMission();
  const { setSelectedQuest } = useMission();
  const { user } = useUser();
  //states
  const [win, setWin] = useState(false);
  const [rewardModal, setRewardModal] = useState(false);
  const [dialogueModal, setDialogueModal] = useState<boolean>(false);
  const [dialogueData, setDialogueData] = useState<any>(null);
  //navigation
  const navigate = useNavigate();
  // the PA or another character explains what's on the puzzle and then we have the : congratulations screen

  const onSolve = () => {
    // this is the callback that is called when the puzzle is solved
    // we need to show the congratulations screen
    playapplause();
    setRewardModal(true);
    setWin(true);
  };
  const updateQuests = async () => {
    await updateSelectedQuest(selectedQuest, mission).then(async (res) => {
      setSelectedQuest(res);
      await updateQuest(selectedQuest).then((res) => {
        setQuest(res);
      });
    });
  };
  const collectRewards = async () => {
    playCollect();
    setRewardModal(false);
    setWin(false);

    setMission(selectedQuest?.missions[0]);
    setMissionType(null);

    const newMission = await { ...selectedQuest?.missions[0] };
    newMission.order = newMission.order.slice(1);
    setMission(newMission);
    await updateQuests();

    if (
      (newMission.order && newMission.order.length > 0) ||
      newMission.endDialogue
    ) {
      setRefresh(true);
      navigate('/map');
    } else {
      newMission.finished = true;
      setMission(newMission);
      const nextMission = await handleNextMission(
        selectedQuest?.missions,
        mission?._id,
      );
      if (nextMission) {
        setMission(nextMission);
        selectedQuest?.missions.shift();
        // update quests
        updateQuest(selectedQuest);
      } else {
        finishQuest(selectedQuest?._id);
        updateQuest(selectedQuest);
        toggleMission(false);
      }
      navigate('/map');
    }
  };

  useEffect(() => {
    const { language } = i18n;
    const { description, dictionary } = mission || {};

    if (description && dictionary?.description) {
      const desc = getSingleDialogue(description, dictionary);
      setDialogueData(desc[language]);
      setDialogueModal(true);
    }
  }, [i18n, mission]);

  const onDialogueClose = () => {
    setDialogueModal(false);
  };

  const onClose = () => {
    playClick();
    setDialogueModal(false);
  };
  return (
    <PagesWrapper>
      {!dialogueModal ? (
        <Puzzle
          avatar={pa?.avatar}
          image={mission?.media[0]?.location}
          className="absolute top-[29%] left-0 right-0"
          onSolve={onSolve}
        />
      ) : (
        <GuideWrapper>
          <SpeechSwiper
            isConversation={false}
            name={user?.username || 'Anonymous'}
            type="mission"
            data={dialogueData}
            paImage={user?.avatarImage || ''}
            paPosition="left"
            onStart={onDialogueClose}
            onClose={onClose}
          />
        </GuideWrapper>
      )}
      <Modal isOpen={rewardModal} closeModal={() => {}}>
        <>
          <WinningPopup
            coins={mission?.coins}
            collectRewards={() => collectRewards()}
            object={false}
          />
          {win && (
            <WinningLottie
              className="absolute z-20 -top-[20rem] left-0"
              loop={true}
            />
          )}
        </>
      </Modal>
    </PagesWrapper>
  );
};

export default PuzzlePage;
