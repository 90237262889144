import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`
  body {
   ${tw`w-full font-primary`}
   
 }
 /* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

//Speech Swiper 
 .swiper-pagination {
    bottom: 0.6rem !important;
 }
 .swiper-pagination-bullet {
     ${tw`w-2 h-2 rounded-full border-2 bg-inherit border-black opacity-100`}
 }

 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    ${tw`w-[25px] bg-lightBlue transition-all duration-700`}
 }
 .swiper-slide {
    width: fit-content !important;
    height: fit-content !important;
  }


  .mySwiperI{
    ${tw`w-full h-full  flex justify-start items-start relative`}
  }
  .mySwiperI .swiper-slide {
    ${tw`!w-full !h-full `}
  }

  // Quests Swiper
  .mySwiperII{
    ${tw`w-full h-full pt-5 pb-[8.5rem] flex justify-start items-start relative`}
  }
  .mySwiperII .swiper-slide {
    ${tw`!w-full !h-full `}
  }

  .mySwiperII .swiper-pagination {
    ${tw`!bottom-6 z-10`}
  }

  .mySwiperII .swiper-pagination-bullet {
    ${tw`!w-2 !h-2 !rounded-full !border border-solid !bg-white !border-black !opacity-100`}
  }

  .mySwiperII .swiper-pagination-bullet-active {
    ${tw`scale-150 !bg-yellow !transition-all !duration-700`}
  }
  

  /* .questButton{animation: fadeInRight 1s ease-in;} */

  @keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}

  @keyframes pulseAnimation {
    0% { scale: 0.9; opacity: 0.7 }
    100% {scale: 1.5; opacity: 0.5}
  }  

  //JoyRide
  .react-joyride__spotlight { 
  position: absolute !important; 
  filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.6) !important;
  padding: 2rem !important;
  }
  .__floater {
    ${tw`md:left-[30%]`}
    padding: 0 !important;
    transform: none !important;
    position: fixed !important;
    top: 70% !important;
    left: 20px !important;
    &[x-placement="top"] {
      top: 30px !important;
    }
    @media screen and (min-width: 762px) {
      left: 26% !important;
      top: 72% !important;
    }
  }

  .__floater__arrow {
    display: none;
    width: 0;
    height: 0;
  }

 //map-popup
 .mapboxgl-popup-content {
  ${tw`relative bg-transparent p-0 shadow-none  border-0`}
  
 }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
