import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledAvatarProps } from './Avatar.types';

export const AvatarCardContainer = styled.div<StyledAvatarProps>`
  ${tw`relative w-[5.229rem] h-[5.229rem] border-solid p-1.5 cursor-pointer
    border-[3px] border-black rounded-full z-10 shrink-0`};
  background-color: ${({ bgColor }) => bgColor};
  border-color: ${({ borderColor }) => borderColor};
`;

export const AvatarImage = tw.img`absolute bottom-0 left-0 w-[5rem] rounded-b-[2.5rem] `;
