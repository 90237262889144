import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = tw.div`
   relative h-[6.8rem] z-20
`;
export const Item = styled.span`
  ${tw`w-full flex justify-center items-center bg-white h-[6.8rem] 
       rounded-xl border-[0.188rem] border-black border-b-0`}
  &:after {
    ${tw`[content: ""] absolute -z-10 inset-0 bottom-0 rounded-xl bg-yellow
           translate-x-[0.325rem] border-[0.188rem] border-black border-b-0`};
  }
  img {
    ${tw`w-[3.875rem] h-[4.875rem] object-contain`}
  }
`;

export const Trainagle = tw.span`absolute left-0 -bottom-[24.5%]  w-[calc(100% + 0.281em)] `;

export const BadgeImage = tw.img`w-[3.285rem] h-[4.781rem]`;
