const NUM_ROWS = 3;
const NUM_COLS = 3;
const NUM_TILES = NUM_ROWS * NUM_COLS;
const EMPTY_INDEX = NUM_TILES - 1;
const MOVE_DIRECTIONS = ['up', 'down', 'left', 'right'];

const rand = (min: number, max: number) => {
  return min + Math.floor(Math.random() * (max - min + 1));
};

export default class GameState {
  board: number[][] = [];
  stack: Array<number[][]> = [];
  shuffling: boolean = false;

  static getNewBoard() {
    return Array(9)
      .fill(0)
      .map((x, index) => [Math.floor(index / NUM_COLS), index % NUM_ROWS]);
  }

  static solvedBoard = GameState.getNewBoard();
  static instance: null | GameState = null;
  static stack = [];

  // get Instance typescript
  static getInstance() {
    if (GameState.instance === null) {
      GameState.instance = new GameState();
    }
    return GameState.instance;
  }

  constructor() {
    this.startNewGame();
  }

  isSolved = () => {
    for (let i = 0; i < NUM_TILES; i++) {
      if (
        this.board[i][0] !== GameState.solvedBoard[i][0] ||
        this.board[i][1] !== GameState.solvedBoard[i][1]
      )
        return false;
    }
    return true;
  };

  startNewGame() {
    this.board = GameState.getNewBoard();
    this.stack = [];
    this.shuffle();
  }

  shuffle() {
    this.shuffling = true;
    let shuffleMoves = rand(...([60, 80] as const));
    while (shuffleMoves-- > 0) {
      this.moveInDirection(MOVE_DIRECTIONS[rand(0, 3)]);
    }
    this.shuffling = false;
  }

  canMoveTile(index: number) {
    if (index < 0 || index >= NUM_TILES) return false;

    const tilePos = this.board[index];
    const emptyPos = this.board[EMPTY_INDEX];
    if (tilePos[0] === emptyPos[0])
      return Math.abs(tilePos[1] - emptyPos[1]) === 1;
    else if (tilePos[1] === emptyPos[1])
      return Math.abs(tilePos[0] - emptyPos[0]) === 1;
    else return false;
  }

  moveTile(index: number) {
    if (!this.shuffling && this.isSolved()) return false;
    if (!this.canMoveTile(index)) return false;

    const emptyPosition = [...this.board[EMPTY_INDEX]];
    const tilePosition = [...this.board[index]];

    let boardAfterMove = [...this.board];
    boardAfterMove[EMPTY_INDEX] = tilePosition;
    boardAfterMove[index] = emptyPosition;

    if (!this.shuffling) this.stack.push(this.board);
    this.board = boardAfterMove;

    return true;
  }

  moveInDirection = (dir: string) => {
    const epos = this.board[EMPTY_INDEX];
    const posToMove =
      dir === 'up'
        ? [epos[0] + 1, epos[1]]
        : dir === 'down'
        ? [epos[0] - 1, epos[1]]
        : dir === 'left'
        ? [epos[0], epos[1] + 1]
        : dir === 'right'
        ? [epos[0], epos[1] - 1]
        : epos;
    let tileToMove = EMPTY_INDEX;
    for (let i = 0; i < NUM_TILES; i++) {
      if (
        this.board[i][0] === posToMove[0] &&
        this.board[i][1] === posToMove[1]
      ) {
        tileToMove = i;
        break;
      }
    }
    this.moveTile(tileToMove);
  };

  getState() {
    const self = this;
    return {
      board: self.board,
      solved: self.isSolved(),
    };
  }

  // resetState
  resetState() {
    const self = this;
    return {
      board: self.board,
      solved: false,
    };
  }
}
