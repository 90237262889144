import FindPlaceImage from 'components/FindPlace/FindPlaceImage';
import {
  getQuestsSortedByDistance,
  // getRandomQuest,
} from 'helpers/QuestsHelpers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMission from 'store/useMission';
import usePersonalAssistant from 'store/usePersonalAssistant';
import { GuidesWrapper, PagesWrapper } from './Map.styles';
import SpeechSwiper from 'components/SpeechSwiper';
import useUser from 'store/useUser';
import { getPabyName } from 'helpers/AvatarsHelper';
import FindPlaceQuote from 'components/FindPlace/FindPlaceQuote';
import useSound from 'use-sound';
import ClickSound from 'assets/sounds/Tap.wav';
import { usePosition } from 'hooks/usePosition';
import { Loading } from 'components/LottieFiles';
import i18n from 'i18n';

const FindImage = () => {
  const [playClick] = useSound(ClickSound);
  // states data
  const pa = usePersonalAssistant((state) => state.pa);
  const { user } = useUser();
  const {
    onGoing,
    setMission,
    toggleMission,
    missionType,
    mission,
    setMissionType,
    setSelectedQuest,
    setDisableMarkerState,
  } = useMission();
  const navigate = useNavigate();
  const [dialogueModalFind, setDialogueModalFind] = useState<boolean>(false);
  const [dialogueData, setDialogueData] = useState<any>(null);
  const { latitude, longitude } = usePosition(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const userPos = { latitude, longitude };
    if (mission && missionType === 'enigmas') {
      setLoading(false);
      if (mission.dialogue) {
        if (mission?.dialogue) {
          setDialogueData(mission?.dialogue);
          setDialogueModalFind(true);
        }
      }
    }

    if (latitude && longitude)
      getQuestsSortedByDistance(userPos).then((res: any) => {
        if (res && !onGoing && missionType !== 'enigmas') {
          setSelectedQuest(res);
          setMissionType('enigmas');
          setMission(res?.enigmas[0]);
          setLoading(false);
          if (res?.enigmas[0]?.dialogue) {
            setDialogueData(res?.enigmas[0]?.dialogue);
            setDialogueModalFind(true);
          }
        } else if (!mission && missionType !== 'enigmas') {
          navigate('/map');
        } else if (!res) navigate('/map');
      });
    // eslint-disable-next-line
  }, [latitude, longitude, onGoing]);

  const startTheMission = () => {
    playClick();
    toggleMission(true);
    setDisableMarkerState(true);
    navigate('/map');
  };

  const onDialogueClose = () => {
    playClick();
    setDialogueModalFind(false);
  };

  const onClose = () => {
    playClick();
    setDialogueModalFind(false);
  };
  return (
    <PagesWrapper>
      {loading && <Loading className="absolute top-20 left-20" />}
      {mission &&
        !loading &&
        !dialogueModalFind &&
        (mission?.media[0]?.location ? (
          <FindPlaceImage
            avatar={pa?.avatar}
            image={mission?.media[0]?.location}
            clue={
              mission?.dictionary?.description
                ? mission?.dictionary?.description[i18n.language]
                : mission?.description
            }
            onClick={startTheMission}
          />
        ) : (
          <FindPlaceQuote
            avatar={pa?.avatar}
            quote={
              mission?.dictionary?.description
                ? mission?.dictionary?.description[i18n.language]
                : mission?.description
            }
            onClick={startTheMission}
          />
        ))}
      {dialogueModalFind && (
        <GuidesWrapper>
          <SpeechSwiper
            isConversation={true}
            name={user?.username || 'Anonymous'}
            type="mission"
            data={dialogueData?.conversation[i18n.language]}
            paName={
              (dialogueData?.persons[0] &&
                (dialogueData?.persons[0] === 'PA'
                  ? user?.avatarName
                  : dialogueData?.persons[0])) ||
              ''
            }
            paImage={
              getPabyName(dialogueData?.persons[0]) === 'PA'
                ? user?.avatarImage
                : getPabyName(dialogueData?.persons[0] || '')
            }
            paPosition="left"
            paColor="#007AD1"
            objectImage={
              getPabyName(dialogueData?.persons[1]) === 'PA'
                ? user?.avatarImage
                : getPabyName(dialogueData?.persons[1] || '')
            }
            objectName={dialogueData?.persons[1] || ''}
            objectColor="#FE4A4F"
            onStart={onDialogueClose}
            onClose={onClose}
          />
        </GuidesWrapper>
      )}
    </PagesWrapper>
  );
};

export default FindImage;
