import tw from 'twin.macro';

export const Item = tw.div`
  w-full flex justify-between items-center pb-[18px]
  // border-b-2 border-darkGray
`;

export const ItemName = tw.p`
   text-base font-bold leading-[1.21rem] text-darkGray capitalize
`;

export const Btn = tw.button`
  w-9 h-9 flex justify-center items-center [box-shadow: 2px 2px 0px #000000] 
  border-2 border-darkGray rounded-full bg-softGray
`;
