import { Empty } from 'components/LottieFiles';
import RewardItem from 'components/RewardItem';
import { Items, Top, Wrapper } from './ObjectsCard.styles';
import { ObjectsCardProps } from './ObjectsCard.types';
import NoImage from 'assets/images/noImage.png';
import { getImagefromMedia, getObject3D } from 'helpers/GlobalHelper';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const ObjectsCard = ({
  items,
  handleSeeAll,
  bgColor = '#F2E8E8',
  ...rest
}: ObjectsCardProps): JSX.Element => {
  const { t } = useTranslation();

  const getName = (item: any) => {
    if (item?.dictionary) {
      return item?.dictionary?.name[i18n.language];
    }
    return item?.name;
  };

  const getDescription = (item: any) => {
    if (item?.dictionary && item?.dictionary?.description) {
      return item?.dictionary?.description[i18n.language];
    }
    return item?.description;
  };
  return (
    <Wrapper bgColor={bgColor} {...rest}>
      <Top>
        <p>{t('objectswin')}</p>
        <button onClick={handleSeeAll}>{t('seeAll')}</button>
      </Top>
      <Items>
        {items.length > 0 ? (
          items.map((item: any, index) => {
            const img = getImagefromMedia(item?.media);
            const object3d = getObject3D(item?.media)?.location;

            return (
              <RewardItem
                key={item?.name + index}
                type="object"
                name={getName(item)}
                image={img || NoImage}
                object3d={object3d || ''}
                description={getDescription(item)}
                is3D={true}
              />
            );
          })
        ) : (
          <Empty height="165px" />
        )}
      </Items>
    </Wrapper>
  );
};

export default ObjectsCard;
