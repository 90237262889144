import { API_BADGES, API_OBJECT } from 'config/api';
import { fetchit } from 'lib';
import useData from 'store/useData';

export const getBadges = async (placeId: string) => {
  // 63c806f4b5411539045fdc00
  if (!placeId) return;
  const data = await fetchit(API_BADGES + '/?place=' + placeId, null, 'GET');
  useData.setState({ badges: data.results });
  return data;
};

export const getObjects = async (placeId: string) => {
  if (!placeId) return;
  const data = await fetchit(API_OBJECT + '/?place=' + placeId, null, 'GET');
  useData.setState({ objects: data.results });
  return data;
};
