import styled from 'styled-components';
import tw, { TwStyle } from 'twin.macro';
import { StyledTitleProps } from './Title.types';

const levels: TwStyle = {
  1: tw`text-2xl sm:text-[2rem] sm:leading-[2.625rem] tracking-tighter`,
  2: tw`text-xl sm:text-2xl tracking-tighter`,
  3: tw`text-lg leading-[1.438rem]`,
};

export const StyledTitle = styled.h1<StyledTitleProps>(({ color }) => [
  //base styles
  tw`capitalize font-bold`,
  ({ level }) => levels[level],
  color && `color: ${color};`,
]);
